.sol-page-feedback {
  margin: 20px 0;

  & .sol-modal-close {
    display: none !important;
  }

  @media @xs {
    padding: 10px !important;
  }
}

.sol-feedback {
  button[type="submit"] {
    font-size: 17px;
    padding: 9px 18px 6px;
  }
  &__form {
    margin-top: 3px;
    padding: 13px 28px 26px 34px;
    background-color: rgba(193, 228, 199, 0.3);
    textarea {
      font-family: @main-font;
      font-size: 15px;
      display: block;
      width: 100%;
      height: 140px;
      margin-bottom: 19px;
      border: 1px solid @fringy-flower;
      padding: 10px 15px;
    }
    label {
      letter-spacing: 0.4px;
      text-align: left;
      color: #333333;
      margin-bottom: 13px;
      display: block;
      font-size: 17px;
      line-height: 1.4;
      font-family: PFDINTextStd-Light, sans-serif;
    }
    span {
      font-size: 17px;
      line-height: 1.35;
      letter-spacing: 0.5px;
      text-align: left;
      color: #333333;
      font-weight: 600;
      margin-bottom: -15px;
      display: block;
    }
  }
  .sol-feedback__content {
    position: relative;
    font-size: 18px;
    line-height: 1.28;
    text-align: left;
    color: #333333;
    min-height: 50px;
    border-radius: 3px;
    background-color: rgba(193, 228, 199, 0.3);
    padding: 17px 29px 13px 25px;
    margin-top: 19px;
    .sol-js-feedback-no, .sol-js-feedback-yes {
      .sol-btn;
      font-size: 17px;
      min-height: 35px;
      max-height: 35px;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      &.sol-active,
      &.sol-active[disabled="disabled"]:hover {
        background-color: #6dab78;
        color: @white;
      }
      &[disabled="disabled"]:hover {
        background-color: #c1e4c7;
        cursor: default;
      }
      @media @sm {
        top: auto;
        transform: none;
        position: static;
        width: 74px;
        right: auto;
        left: auto;
      }
    }
    .sol-js-feedback-no {
      right: 29px;
    }
    .sol-js-feedback-yes {
      right: 116px;
      @media @sm {
        margin-right: 13px;
      }
    }
    @media @xs {
      padding: 14px 23px 13px 23px;
    }
  }
  .sol-feedback__answers {
    @media @sm {
      margin-top: 13px;
    }
  }
  .sol-feedback__thanks {
    padding: 17px 39px 21px;
    background-color: rgba(193, 228, 199, 0.3);
    margin-top: 3px;
    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.53;
      letter-spacing: 0.4px;
      text-align: left;
      color: #333333;
      span {
        font-size: 19px;
        line-height: 1.35;
        letter-spacing: 0.5px;
        text-align: left;
        color: #333333;
        font-weight: 600;
      }
    }
  }
}
#feedbackSender {
  height:40px;
  margin-top: -10px;
}
