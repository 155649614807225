.sol-a-z-icon {
  display: none;
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}
.sol-letters {
  @bg: #ddd;
  @text: @black;
  @bg-hover: @color-1;
  @text-hover: @white;
  .sol-clearfix;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  a {
    padding: 6px 0;
    text-align: center;
    height: 24px;
    width: 24px;
    color: @text;
    background-color: @bg;
    box-shadow: none;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 1px;
    margin-left: 1px;
    margin-bottom: 10px;
    display: inline-block;
    &.sol-active,
    &:hover {
      background-color: @bg-hover;
      color: @text-hover;
    }
  }
}
.sv-abc-portlet {
  & .sv-abc-hit {
    & a {
      text-decoration: underline;
      text-decoration-color: @link-underline;
      
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        text-decoration: none;
        border-bottom: 2px solid @link-underline;
        &:hover {
          text-decoration: none !important;
          border-bottom: 2px solid @link-underline-hover;
        }
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: @link-underline-hover;
      }
    }
  }
}
