.sol-landing-page__highlighted-news {
  @media (min-width: 991px) {
    margin-bottom: 59px;
  }
  @media @md-strict {
    padding-left: 2.9%;
    padding-right: 2.9%;
    margin-bottom: 49px;
  }
  @media @sm {
    padding-left: 0;
    padding-right: 0;
  }
  &__header {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #204560;
    color: #fff;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: 36px;
    padding-right: 51px;
    font-family: @main-font;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    margin: 0;
    margin-left: 2px;
    @media @sm {
      width: 100%;
      justify-content: flex-start;
      padding-left: 26px;
      border-radius: 0;
      margin-left: 0;
    }
  }
  &__list {
    position: relative;
    display: flex;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    padding: 26px 44px 69px 38px;
    margin: 0;
    list-style-type: none;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 28.5px 61px 49px;
    }
    @media @sm {
      padding: 21px 3.1% 75px;
    }
    > li {
      flex-basis: 29.6%;
      @media (max-width: 768px) {
        padding-bottom: 16.5px;
        margin-bottom: 15px;
        border-bottom: solid 1px rgba(110, 205, 233, 0.4);
      }
      &:nth-child(3n+2) {
        margin-left: 5.5%;
        margin-right: 5.5%;
        @media (max-width: 768px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      &:last-child {
        flex-basis: 0;
        border-bottom: 0;
        padding: 0;
        margin: 0;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    &:hover {
      .sol-landing-page__highlighted-news__item__preamble{
        /*text-decoration: underline !important;*/
      }
      .sol-landing-page__highlighted-news__item__title{
        text-decoration: underline !important;
      }

    }
    i.sol-icon.sol-icon__clock-small {
      @media @sm {
        .icon-size(13px);
      }
    }
    &__date {
      margin-bottom: 3px;
      &__day, &__month, &__year{
        font-family: @light-font;
        font-size: 17px;
        font-weight: 300;
        text-align: left;
        color: #333333;
        @media @sm {
          font-size: 14px;
        }
      }
    }
    &__title {
      font-size: 21px;
      color: #204560;
      margin-bottom: 5px;
      @media @sm {
        font-size: 19px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    &__preamble {
      font-family: @light-font;
      font-size: 19px;
      font-weight: 300;
      color: #333333;
      @media @sm {
        font-size: 16px;
      }
    }
  }
  &__more-news-btn {
    position: absolute;
    right: 44px;
    bottom: 22px;
    font-family: @light-font;
    font-size: 19px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
    color: #204560;
    @media @md-strict {
      right: 64px;
      bottom: 19px;
    }
    @media @sm {
      right: 15px;
      bottom: 55px;
    }
    &::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid rgba(110,205,233,0.6);
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: -3px;
    }
    &:hover {
      &::after {
        border-bottom-color: #6ecde9;
      }
    }
  }
}
