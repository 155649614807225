/* elements/input.less */
@form-green-1: 									#66bd2d;
@form-green-2: 									#c7e6b3;
@form-green-3: 									#eef7e8;

@form-red-1: 										#d53636;
@form-red-2: 										#de404e;;
@form-red-3: 										#8acf95;

@downriver: #204560;

@form-placeholder:							#b4b4b4;
@form-input-text:								@black;
@form-input-border:							rgba(110, 205, 233, 0.4);
@form-input-bg:									@white;
@form-input-focus-bg:						@white;
@form-input-hover-border:				rgba(110, 205, 233, 0.4);;
@form-input-focus-border:				@focus-border;
@form-input-chk-empty-border:	  #6ecde9;
@form-input-chk-empty-border--hover: rgba(110, 205, 233, 0.4);
@form-input-chk-bg:							@color-1;
@form-input-chk-border:					@downriver;
@form-input-chk-text:						@white;
@form-divider:                  @gray-4;

.input-label {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.input-required-asterisk {
  &:after {
    content: '*';
    color: @form-red-1;
    font-size: 14px;
    margin-left: 2px;
  }
}
.input-text {
  .placeholder({
    color: @form-placeholder;
  });
  .no-outline-focus;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  font-family: @main-font;
  color: @form-input-text;
  vertical-align: middle;
  height: 40px;
  border: 1px solid @form-input-border;
  border-radius: 2px;
  background-color: @form-input-bg;
  .transition(~'border, background-color, box-shadow');
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  &:hover {
    border-color: @form-input-hover-border;
    background-color: @form-input-focus-bg;
  }
  &:focus {
    background-color: @form-input-focus-bg;
    border-color: @form-input-focus-border;
  }
  &::-ms-clear,
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
.input-text-small {
  .input-text;
  height: 35px;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-select {
  .input-text;
  cursor: pointer;
  background-image: url('@{sol-icons-path}/forms/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.input-select-small {
  .input-select;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-text-invalid {
  border-color: @form-red-2;
  background-image: url('@{sol-icons-path}/forms/times-invalid.svg');
}
.input-text-valid {
  border-color: #8acf96;
  background-image: url('@{sol-icons-path}/forms/check-valid.svg');
}
.input-textarea {
  .input-text;
  min-height: 120px;
  height: auto;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed @form-divider;
}
.input-checkbox,
.input-radio {
  display: block;
  height: 0;
  opacity: 0;
  & + label::before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid @form-input-chk-empty-border;
    border-radius: 2px;
    position: relative;
    top: -1px;
    margin-right: 10px;
    float: left;
    text-align: center;
    .transition(~'color, background-color, border-color, border-width');
  }
  & + label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      &::before {
        border-color: @form-input-chk-empty-border--hover;
      }
    }
  }
  &:focus + label::before,
  &:focus:checked + label::before {
    border: 2px solid @form-input-focus-border;
  }
}
.input-checkbox {
  &:checked + label:before {
    .icon(@fa-var-check, 12px);
    padding-top: 2px;
    background-color: @form-input-chk-bg;
    border-color: @form-input-chk-border;
    color: @form-input-chk-text;
  }
}
.input-radio {
  & + label:before {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 1px;
  }
  &:checked + label:before {
    border-width: 3px;
    border-color: @form-input-chk-border;
    background-color: @form-input-chk-bg;
  }
}
.input-file() {
  display: block;
  height: 0;
  opacity: 0;
  & + label {
    .clearfix;
    margin-bottom: 20px;
  }
  &__label {
    .input-label;
    margin-bottom: 8px;
  }
  &__btn {
    .button;
    padding: 5px 20px;
    margin-left: 10px;
    margin-top: 2px;
    font-size: 13px;
  }
  &__text {
    float: left;
    margin-left: 15px;
    font-size: 15px;
    font-weight: 300;
    line-height: 34px;
  }
  &__remove {
    .no-appearance;
    min-height: 32px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    top: 1px;
    cursor: pointer;
    & i {
      font-size: 18px;
      color: @color-1;
    }
  }
}
.input-btn {
  .sol-btn;
}
.input-btn-small {
  .input-btn;
  font-size: 14px;
  padding: 5px 15px 6px;
}
