/* decorations/simple-box.less */
.sol-simple-box-decoration {
  @simple-box-decoration-bg:  rgba(110, 205, 233, 0.13);
  @simple-box-decoration-border: fadeout(@color-3, 77%);
  @simple-box-alt-bg: rgba(110, 205, 233, 0.13);
  margin-top: 25px;
  padding: 8px 42px 34px;
  border: 1px solid @simple-box-decoration-border;
  border-radius: 3px;
  background-color: @simple-box-decoration-bg;
  &.sol-simple-box-decoration-alt {
    background-color: @simple-box-alt-bg;
    border: none;
    .font-heading-3 {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.28;
      letter-spacing: 0.5px;
      text-align: left;
      color: #204560;
      text-transform: uppercase;
    }
  }
  & > div {
    display: block;
    margin-bottom: 9px;
  }
}
.sv-editing-mode {
  .sol-error-box,
  .sol-warning-box {
    display: block;
  }
}
