header.sol-c3l {
  .sol-top {
    background-color: #0080a9;
    padding: 40px 40px 15px;

    @media (max-width: 1024px) {
      background-color: white;
      padding: 0 0 10px 0;
    }

    &-logo {
      & > img {
        margin-left: 0;
      }
    }

    & .sol-top-search__form {
      left: 450px;
      @media (max-width: 1279px) {
        left: 265px;
      }
    }
  }
  .sol-bottom {
    background-color: white;
  }
}

.sol-mobile-search.sol-c3l {
  .sv-searchform-portlet {
    form {
      @media (max-width: 1024px) {
        margin-top: 0;
      }
    }
  }
}

footer.sol-c3l {
  .sv-grid-main.sv-fluid-grid {
    position: relative;
    @media @sm {
      padding-bottom: 80px;
    }
    &::after {
      background-image: url(/webdav/files/System/resources/graphics/logo-vit.svg);
      content: '';
      width: 300px;
      height: 60px;
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      bottom: 0;
      background-size: 300px;
      @media @sm {
        left: 0;
      }
    }
  }
}

.sol-c3l {

  .sol-top-nav {
    display: block;
    
    @media (max-width: 1279px) {
      margin-left: 0;
      margin-right: 0;
    }

    &__item {
      color: #204560;
      &--active {
        border-radius: 3px;
        background-color: rgb(168, 225, 242);
        left: -2px;
        &::after {
          background-color: transparent !important;
        }
      }
      &::after {
        background-color: rgba(110, 205, 233, 0.4);
        width: 1px;
      }
      &:last-child {
        &::after {
          background-color: transparent;
        }
      }
    }
  }

  & .sol-tool-nav {
    margin-top: 4px;
    @media (max-width: 1024px) {
      width: 100%
    }
    &__item {
      @media (max-width: 1024px) {
        margin-top: 3px;
      }

      &:nth-child(2) {
        @media (max-width: 1024px) {
          margin-left: 0;
        }
      }

      & button {
        color: #fff;
        @media (max-width: 1024px) {
          color: #204560;
        }
      }
      & a {
        color: #fff;
        @media (max-width: 1024px) {
          color: #204560;
        }
      }
      &__icon {
        & i.sol-icon__globe::after {
          background-image: url('/webdav/files/System/resources/graphics/icons/globe-white.svg');
          @media (max-width: 1024px) {
            background-image: url('/webdav/files/System/resources/graphics/icons/globe.svg');
          }
        }
        & i.sol-icon__listen::after {
          background-image: url('/webdav/files/System/resources/graphics/icons/listen-white.svg');
          @media (max-width: 1024px) {
            background-image: url('/webdav/files/System/resources/graphics/icons/listen.svg');
          }
        }
        & i.sol-icon__phone::after {
          background-image: url('/webdav/files/System/resources/graphics/icons/phone-white.svg');
          @media (max-width: 1024px) {
            background-image: url('/webdav/files/System/resources/graphics/icons/phone.svg');
          }
        }
        & i.sol-icon__lock::after {
          background-image: url('/webdav/files/System/resources/graphics/icons/lock-white.svg');
          @media (max-width: 1024px) {
            background-image: url('/webdav/files/System/resources/graphics/icons/lock.svg');
          }
        }
      }
    }
    & .sol-back-to-main-site-btn {
      color: black;
      text-decoration: none;

      &__wrapper {
        background-color: #6ECDE9;
        padding: 5px 15px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        position: absolute;
        top: -45px;
        right: 0;
        @media (max-width: 1024px) {
          display: block;
          top: 5px;
          right: 10px;
          border-radius: 3px;
          border: solid 1px rgba(110, 205, 233, 0.6);
          background-color: white;
          color: black;
        }
        a {
          @media @sm {
            font-size: 11px;
          }
        }
      }
    }
  }

  .sol-search-page__wrapper {
    padding-top: 40px;

    @media @sm {
      width: 95%;
    }

    .sol-pagination {
      position: relative;
      @media @sm {
        padding-bottom: 30px;
      }
    }
    .sol-search {
      &-page__form {
        max-width: 700px;
      }
      &-result {
        max-width: 700px;
      }
    }
    .sv-facetedsearch-portlet {
      margin: auto;
    }
    .pagination {
      margin: auto;
      @media @sm {
        width: initial;
      }

      .prev, .next {
        @media @sm {
          position: absolute;
          margin-top: 35px;
        }
      }
      .prev {
        @media @sm {
          left: 0;
        }
      }
      .next {
        @media @sm {
          right: 0;
        }
      }
    }
  }

  .sol-landing-page__top {
    padding-bottom: 0;
  }

  .sol-page-listing {
    &-item {
      &:nth-child(odd) {
        background-color: rgba(110, 205, 233, 0.15);
      }
      &__img {
        height: 245px;
        width: 408px;
        border-radius: 2px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        background-repeat: no-repeat;
        background-size: cover;
        @media @sm {
          display: none;
        }
      }
      &__name {
        font-size: 26px;
        @media @sm {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
      p {
        font-size: 19px;
        @media @sm {
          font-size: 15px;
        }
      }
      .sol-left {
        min-width: 408px;
      }
    }
    ul {
      margin-top: 29px;
    }
    &-child {
      &__link {
        margin: 0 15px 15px 0;
        background-color: #0080a9;
        color: white;
      }
    }
  }

  .sol-listing-page {
    &--news {
      .sol-article-filter {
        &--category {
          display: none;
        }
        &--year, &--month {
          flex-basis: 49%;
        }
        &--month {
          margin-right: 0;;
        }
      }
      .sol-article-filter__reset-btn {
        background-color: #0080a9;
        color: white;
        &:hover {
          background-color: #006683;
        }
      }
      .sol-news-item {
        &__date{
          .sol-icon {
            @media screen and (max-width: @md-max) {
              top: 1px;
            }
            &-edge {
              height: 36px !important;
              width: 34px !important; 
              margin-right: -10px;
              &::after {
                height: 36px !important;
                width: 34px !important; 
                max-height: 36px !important;
                max-width: 34px !important;
                margin-top: 0 !important;
              }
            }
          }
        } 
      }
      h1 {
        @media @sm {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .sol-pagination__list {
        position: relative;
        &-item {
          margin: auto;
          &--previous, &--next {
            @media screen and (max-width: @md-max) {
              position: absolute;
              margin-top: 35px;
            }
          }
          &--next {
            @media screen and (max-width: @md-max) {
              right: 0;
            }
          }
        }
      }
    }
  }

  .font-btn {
    a {
      background-color: #0080a9;
      color: white;
      font-size: 17px;
      font-weight: bold;
      padding-top: 10px;
      &:hover {
        background-color: #006683;
      }
    }
  }

  .sol-right {
    .pagecontent {
      background-color: rgba(110, 205, 233, 0.1);
      padding: 20px 26px 20px 26px;
      margin-top: 30px;
      @media @sm {
        margin-top: 0;
      }
      h1, h2, h3, h5, p, a {
        color: #204560;
      }
      h2 {
        font-size: 26px;
      }
      h3 {
        margin-top: 12px;
        @media @sm {
          font-size: 18px;
        }
      }
      p {
        font-size: 17px;
        @media @sm {
          font-size: 16px;
        }
      }
      .sol-divider-decoration {
        border-color: rgba(110, 205, 233, 0.3);
      }
      .sol-element-action {
        width: 100%;
      }
    }
  }

  .sv-form-portlet {

    input[type="submit"] {
      background-color: #0080a9;
      color: white;
      &:hover {
        background-color: #006683;
      }
    }
  }

  .sol-publish-info {
    a {
      @media screen and (max-width: @md-max) {
        font-size: 16px;
      }
    }
  }

  .sol-related__header {
    @media screen and (max-width: @md-max) {
      font-size: 17px;
      font-weight: normal;
    }
  }
  
  .sol-section {
    .sv-image-portlet {
      @media @sm {
        margin-left: -30px;
        margin-right: -30px;
      }
      img {
        @media @sm {
          max-width: 100% !important;
        }
      }
    }
    .sol-page-tools {
      @media @sm {
        display: none;
      }
    }
  }

  .sv-column {
    &-8 {
      .sol-section {
        @media @sm {
          padding-bottom: 10px;
        }
      }
    }
  }

  .sol-tabs-container {
    .sol-tab-button {
      margin-left: 0;
      font-size: 19px;
      color: #204560;
      flex: auto;
      border-color: rgba(110, 205, 233, 0.1);
      border-width: 1px;
      @media @sm {
        width: 100%;
      }
      &-container {
        display: flex;
        @media @sm {
          display: block;
        }
      }
      &-active {
        background-color: #204560;
        border-color: #204560;
        color: white;
        &::before, &::after {
          border: none;
        }
      }
    }
    .sv-decoration-content {
      padding-top: 0;
      border: none;
      .sol-table-decoration {
        border-top-left-radius: 0;
        border-right-width: 1px;
        border-top-right-radius: 0;
        border-left-width: 1px;
        box-shadow: none;
        @media @sm {
          width: 100%;
        }
        caption {
          display: none;
        }

        thead {
          background-color: rgba(110, 205, 233, 0.1);
          th {
            border-right: none;
            p {
              font-size: 15px;
              font-weight: bold;
              color: #204560;
            }
          }
        }

        tbody {
          tr {
            td {
              border-right: none;
              &:first-child {
                p {
                  font-size: 19px;
                  @media @sm {
                    font-size: 12px;
                  }
                }
              }
              p {
                font-size: 15px;
              }
            }
          }
        }

        &.sv-responsiveTable--stacked {
          tbody {
            tr {
              &:nth-child(even) {
                @media @sm {
                  background-color: white;
                }
              }
            }
          }       
          .sv-responsiveTable--stacked {
            &__headRow {
              background-color: rgba(110, 205, 233, 0.1);
              p {
                font-size: 16px;
                a {
                  text-decoration-color: rgba(110, 205, 233, 0.6);
                }
              }
  
              &--main {
                background-color: rgba(110, 205, 233, 0.4) !important; 
                p {
                  color: #204560;
                }
              }
              
            }
          } 
        }
      }
    }
  }

  .sol-mobile-menu-container {
    .sol-menu {
      &-item {
        &[data-expanded='true'][data-has-children='false'],
        &[data-is-current='true'] {
          & > .sol-menu-item-link {
            color: #35383b;
            background-color: rgba(110, 205, 233, 0.1);
          }
        }

        &[data-is-current='true'] {
          & > .sol-menu-item-link {
            font-weight: bold;
          }
        }

        &[data-expanded='true'][data-menu-level='1'] {
          & > .sol-menu-item-link {
            background-color: rgba(110, 205, 233, 0.1);
          }
        }
        &[data-expanded='true'] {
          & > .sol-menu-button {
            background-color: #0080a9;
            color: white;
            & > .sol-icon__plus--blue {
              &::after {
                background-image: url(/webdav/files/System/resources/graphics/icons/minus-white.svg);
              }
            }
          }
        }

        .sol-menu-list {
          .sol-menu-item-display-name {
            font-size: 17px;
          }
        }

      }
      &-button {
        .sol-icon {
          right: 22px !important;
          transform: none !important;
          top: 42% !important;
        }
      }
    }
  }

  & &-contacts {
    padding-right: 20px;
    padding-left: 20px;
    .sol-top-search__form {
      position: relative !important;
      left: 0;
      padding-right: 0;
      margin-bottom: 35px;
      max-width: 49% !important;
     
      @media @sm {
        max-width: 100% !important;
        margin-bottom: 25px;
      }
    }
    .sv-search {
      &-result {
        max-width: 100%;
        margin-left: 0;
      }
      &-hit {
        height: 130px;
        padding: 10px 20px 10px 24px !important;
        border: none;
        display: flex !important;

        @media @sm {
          padding: 10px 20px 10px 10px !important;
        }

        .font-heading-3 {
          font-size: 19px;
          color: #204560;
          font-weight: normal;
          @media @sm {
            font-size: 18px;
          }
          &:hover {
            text-decoration: none;
          }
          .font-normal {
            display: inline-block;
            color: #333333;
          }
        }

        .sol-image {
          margin: 10px 5px 0 5px;
          &::after {
            height: 70px;
            width: 70px;
            @media @sm {
              height: 60px;
              width: 60px;
            }

            @media only screen and (max-width: 400px) {
              height: 50px;
              width: 50px;
            }
          }
        }

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-top: 10px;
          @media @sm {
            width: 60px;
            height: 60px;
          }
          @media only screen and (max-width: 400px) {
            height: 50px;
            width: 50px;
          }
        }

        ul {
          list-style: none;
          display: inline-block;
          cursor: default;
          padding-left: 20px;

          @media @sm {
            padding-left: 10px;
          }

          .font-normal {
            margin-bottom: 0px;
            font-size: 16px;

            @media only screen and (max-width: 400px) {
              font-size: 14px;
            }

            .sol-icon {
              margin-right: 12px;
              vertical-align: sub;

              @media @sm {
                margin-right: 7px;
              }

              &::after {
                height: 18px;
                width: 16px;
              }
            }
            a {
              position: relative;
              text-decoration: none;
              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: rgba(110, 205, 233, 0.6);
              }
            }
          }
        }
        &:nth-child(odd) {
          margin-left: 0;
        }
      }
    }
    .sol-pagination {
      display: flex;
      justify-content: space-between;
    }
  }

  .sol-contact {
    p {
      margin-bottom: 20px;
      a {
        .link-style;
        padding: 2px;
      }
    }
  }

  .sol-startpage {
    &__top {
      margin-bottom: 70px;
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }

      &__image {
        @media @sm {
          display: block;
          height: 220px;
        }
        .sol-grid {
          & > div {
            width: 650px;
            position: absolute;
            top: 320px;
            left: 0;

            @media (max-width: 1024px) {
              top: 230px;
            }

            @media @sm {
              width: 80%;
              top: 60px;
            }
          }
        }
        .sol-top-image__text {
          position: absolute;
          width: 100%;
          height: auto;

          background-color: rgba(255, 255, 255, 0.7);
          color: #204560;
          right: 0;
          font-size: 35px;
          transform: none;
          text-align: center;
          border-radius: 0;
          padding: 5px 5px 5px 5px;

          @media @sm {
            font-size: 25px;
            bottom: initial;
            text-align: left;
            justify-content: left;
            padding-left: 15px;
          }

          &::after {
            border: none;
          }

        }

        &::after, &::before {
          display: none;
        } 
      }
      &__content {
        min-height: 75px;

        @media (max-width: 1024px) {
          position: relative;
          bottom: 0;
        }

        .sol-popular-pages {
          background-color: rgba(110, 205, 233, 0.1);
          height: 75px;
          @media @md {
            height: auto;
          }

          &__link {
            background-color: #0080a9;
            color: white;
            @media (max-width: 1024px) {
              padding: 12px 15px;
            }
          }
        }
      }
    }

    .sol-crisis {
      &-container {
        background-color: #c1e4c7;
        min-height: 90px;
        border-radius: 500px;
        margin-bottom: 40px;

        @media @sm {
          border-radius: 0;
        }

        &__content {
          max-width: 100%;
          padding: 15px 20px;
          .sol-icon {
            margin-right: 22px;
            &::after {
              height: 58px;
              width: 58px;
            }
          }
        }
      }
      &-message {
        &__title, &__desc, &__more {
          font-size: 20px;
          color: black;
          line-height: normal;
        }
        &__title {
          @media @sm {
            margin-top: 0;
          }
        } 
        &__more {
          &::after {
            border-color: black;
          }
        }
      }
    } 

    &__content {
      &__top {
        padding: 0 0 35px 0;

        @media @md {
          padding-left: 20px;
          padding-right: 20px;
        }

        .sv-row {
          margin-bottom: 40px;

          @media (max-width: 991px) {
            margin-bottom: 0;
          }
        }

        .sv-column-3 {
          margin-left: 1.3%;
        }

        .sol-widget-decoration {
          overflow: visible;

          .sv-decoration-content {
            position: relative;

            .sv-image-portlet, .sv-mfpimage-portlet {
              height: 180px;
              overflow: hidden;

              @media (max-width: 1300px) {
                height: 160px;
              }  

              @media @md {
                height: 145px;
                margin-bottom: 20px !important;
              }
              img {
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                @media @md {
                  max-width: 100% !important;
                  max-height: auto !important;
                  max-height: initial !important;
                }
              }
            }

            .sv-mfpimage-portlet {


              & + .sv-text-portlet {
                bottom: -25px;
                @media @md {
                  bottom: -30px;
                }
              }
            }

            .sv-text-portlet {
              position: absolute;
              width: 92%;
              bottom: -30px;
              background-color: white;
              padding-top: 11px;
              box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
              left: 0;
              right: 0;
              margin: auto;

              @media @sm {
                top: 120px;
              }
              
              h4 {
                margin-top: 0;
                font-size: 19px;             
                @media @md {
                  font-size: 16px;
                }   
              }
              p {
                font-size: 14px;
              }
              a {
                border-bottom: none;
              }
            }
          }
        }
      }

      &__bottom {
        background-color: rgb(251, 250, 246);
        padding-top: 25px;
        padding-bottom: 47px;

        & > .sv-fluid-grid.sv-grid-main {
          box-shadow: none;
        }

        .sv-row {
          & > .sol-widget-decoration-wrapper {
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        .sv-column-6 {
          & > .sv-layout.sv-vertical {
            background-color: white;
            height: 100%;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

            .sol-widget-decoration {
              box-shadow: none;
              height: 100%;
            }

            .sv-archive-portlet {
              & + .sv-text-portlet {
                padding: 15px 20px 6px 20px;
              }
            }            
          }
        }

        .sol-widget-decoration {

          .sv-decoration-content {
            .sv-image-portlet {
              margin-bottom: 0;
              img {
                @media @md {
                  max-width: 100% !important;
                  max-height: auto !important;
                  max-height: initial !important;
                }
              }
            }    

            .sv-text-portlet {
              h4 {
                margin-top: 0;
                /*
                font-size: 21px;
                */
                font-weight: bold;
                line-height: 1.3;
                margin-bottom: 6px;
                a {
                  color: #0080a9;
                }                    
              }
              p {
                /*font-size: 21px;*/
                font-size: 19px;
                font-family: PFDINTextStd-Regular, sans-serif; 
              }
              a {
                border-bottom: none;
              }
            }
            
            .sv-column-6{
              float: left;
              padding-left: 0;
            }
          }
        }

        .sv-archive-portlet {
          background-color: white;
          .sol-news-item {
            text-decoration: none;
            @media @sm {
              margin-right: 0;
            }

            &__date {
              .sol-icon {
                @media @sm {
                  top: 1px;
                }
              }
            }

            &__content {
              padding-top: 16px;
              @media @sm {
                padding: 0 10px 21px 25px;
              }
            }
          }
        }
      }
    }
  }

  .sol-mobile-header {
    background-color: #0080a9;
    .sol-mobile-search {
      &-button {
        background-color: #0080a9;
        .sol-icon {
          &::after {
            @media (max-width: 1024px) {
              background-image: url('/webdav/files/System/resources/graphics/icons/search-white.svg');
            }
          }
        }
        &__text {
          @media (max-width: 1024px) {
            color: white;
          }
        }
      }
    }
    .sol-hamburger-wrapper {
      background-color: #0080a9;
      .sol-hamburger {
        & > span {
          background-color: white;
        }
        &-text {
          color: white;
        }
      }
    }
  }

  .sol-arrow-circle__cnt {
    z-index: 1000;
  }
}
