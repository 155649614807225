body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  //font-smooth: auto;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  & > div.sv-layout,
  & > div.sv-layout > div {
    overflow-x: hidden;
  }

}

input {
  -webkit-appearance: none;
}
