.sol-login {
  & .sol-box-decoration {
    & h2 {
      color: #C8496B;
    }
    & .sv-decoration-content {
      padding: 15px;
    }
    & .sv-loginstatus-portlet {
      margin-bottom: 8px;
      & a {
        display: none;
      }
    }
  }
}
