/* components/legible-text.less */
.sol-legible-text {
  display: none;
  background-color: @white;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
  border-radius: 4px;
  padding: 10px;
  position: relative;
  top: -20px;
  margin-bottom: 30px;
  & p:last-child {
    margin-bottom: 0;
  }
}
