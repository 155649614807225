@xxl: ~'(min-width: 1900px)';
.sol-puff {
  &__link {
    @media @md-strict {
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }
  &__img {
    @media @md-strict {
      min-height: 200px;
      max-height: 200px;
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  &__container {
    margin-top: 0;
    justify-content: space-between;
    @media @md-strict {
      flex-direction: row;
    }
    > div {
      flex: 1 1 48%;
      max-width: 48%;
      margin-right: 0;
      @media @md-strict {
        flex: 1 1 49.5%;
        max-width: 49.5%;
      }
      @media @sm {
        flex: 1 1 100%;
        max-width: 100%; 
      }
    }
  }
}
.sol-puff {
  &__container {
    justify-content: space-between;
    > div {
      flex: 1 1 48%;
      max-width: 48%;
    }
  }
  &__content {
    padding-bottom: 63px;
    &__title {
      color: #c8496b;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.28;
      text-align: left;
      color: #323232;
    }
  }
}
.sol-template-area--intranet {
  width: ~'calc(100% - 110px)';
  margin-left: auto;
  .sol-startpage__bottom {
    margin-top: 50px;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      > div {
        flex: 0 0 49%;
      }
    }
  }
  .sol-startpage__top {
    position: relative;
  }
  .sol-startpage__top__image {
    height: 326px;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;

    @media @sm {
      &::before,
      &::after {
        display: none;
      }
    }
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 40px;
      background-color: #FCFBF9;
      z-index: 1;
      bottom: -25px;
      width: 340px;
      @media @xxl {
        width: 700px;
      }
    }
    &::before {
      content: '';
      left: -1px;
      transform: rotate(5deg) skewx(5deg);
      @media @xxl {
        transform: rotate(3deg) skewx(3deg);
      }
    }
    &::after {
      right: -1px;
      transform: rotate(-5deg) skewx(-5deg);
      @media @xxl {
        transform: rotate(-3deg) skewx(-3deg);
      }
    }
    @media @md {
      height: 368px;
    }
    @media @sm {
      display: none;
    }
  }

  .sol-startpage__wrapper {
    .sol-editable-content-area--1 {
      //margin-top: 150px;
    }
  }

  .sol-top-shortcut{
    background: #fff;
    width: 90%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    bottom: -30px;
    z-index: 2;
  }
  .sol-startpage__top__content {
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media @md-strict {
      bottom: -200px;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    .sol-top-shortcut__item {
      min-height: auto;
      justify-content: flex-start;
    }
    .sol-icon__briefcase {
      .icon-size(63px, 69px);
    }
    .sol-icon__e-services {
      .icon-size(66px, 70px);
    }
    .sol-icon__compare {
      .icon-size(64px, 68px);
    }
  }
  .sol-homepage__bottom {
    display: flex;
    justify-content: space-between;
    > div:first-child {
      & > div {
        flex: 0 0 49%;
      }
    }
    .sol-right {
      width: 48%;
    }
  
    
  }
  @media @md {
    width: 100%;
  }
}  
