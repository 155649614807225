.sol-top-search {
  position: relative;
  &__form {
    position: absolute;
    left: 325px;
    top: 2px;
    width: 406px;
    input[type='search'] {
      .search-field;
    }
    button[type='submit'] {
      .search-button;
    }
  }
}
