/* news-item.less */
.sol-news-item {
  @date-text: @black;
  @header-text: @color-1;
  @desc-text: @black;
  @border: fadeout(@color-2, 70%);
  @large-content-bg: @color-1;
  @large-text: @white;
  cursor: pointer;
  @media @sm {
    margin-right: 15px;
    margin-left: 15px;
  }
  & &__content {
    position: relative;
    padding: 18px 10px 25px 21px;
    border-bottom: 1px solid @border;
    @media @sm {
      padding-right: 0;
      padding-left: 0;
    }
  }
  & &__img {
    min-height: 262px;
    background-size: cover;
    background-position: 50%;
  }
  & &__date {
    display: block;
    font-family: @light-font;
    font-size: 17px;
    line-height: 21px;
    font-weight: 300; 
    color: @date-text;
    margin-bottom: 4px;
    .sol-icon {
      .icon-size(18px, 17px);
      margin-right: 7px;
      margin-top: 1px;
      float: left;
      position: relative;
      top: -2px;
    }
    @media @md {
      font-size: 14px;
      margin-bottom: 0;
      .sol-icon {
        .icon-size(14px);
      }
    }
  }
  & &__header {
    display: block;
    font-size: 21px;
    line-height: 27px;
    color: @header-text;
    margin-top: 0;
    margin-bottom: 4px;
    a {
      text-decoration: none;
      color: @header-text;
    }
    @media @md {
      font-size: 19px;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  &:hover &__header {
    text-decoration: underline;
  }
  & &__desc {
    display: block;
    font-size: 19px;
    line-height: 27px;
    color: @desc-text;
    font-weight: 300;
    margin-top: 0;
    @media @md {
      font-size: 16px;
      line-height: normal;
      font-weight: 300;
    }
  }
  &--large {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 26px 0 rgba(51, 51, 51, 0.4);
    @media @md {
      margin-bottom: 30px;
    }
  }
  &--large &__content {
    background-color: @large-content-bg;
    padding: 18px 45px 29px 34px;
    & > .sol-icon__arrow--white {
      .icon-size(19px);
      position: absolute;
      bottom: 14px;
      right: 14px;
    }
    @media @md {
      padding: 5px 18px 20px;
      .sol-news-item__header {
        margin-top: 10px;
        outline: 1px solid grey;
      }
    }
  }
  &--large &__date,
  &--large &__header,
  &--large &__desc {
    color: @large-text;
    a {
      color: @large-text;
    }
  }
  &--large &__date {
    font-family: @light-font;
  }
}
.sol-startpage-news a.sol-news-archive-link {
  .link-underline;
  font-size: 19px;
  font-weight: 300;
  line-height: 24px;
  color: @color-1;
  margin-top: 15px;
  float: right;
  margin-right: 8px;
  @media @md-strict {
    float: none;
    left: 80px;
    top: 10px;
    font-size: 16px;
  }
  @media @sm {
    margin-right: 15px;
    font-size: 16px;
  }
}
.sol-startpage-news__left {
  ul {
    width: 100%;
    display: flex;
    height: auto;
    @media @md-strict {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media @sm {
      display: block;
      padding-left: 15px;
      padding-right: 15px;
    }
    .sol-news-item--large {
      width: 48%;
      float: left;
      @media @sm {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 20px;
      }
      &:first-child {
        margin-right: 34px;
        @media @md {
          margin-right: 12px;
        }
        @media @sm {
          margin-right: 15px;
        }
      }
      .sol-news-item__img {
        min-height: 200px;
        @media @md {
          min-height: 191px;
        }
        @media @sm {
          min-height: 180px;
        }
      }
      .sol-news-item__content {
        height: 100%;
        .sol-news-item__date {
          @media @sm {
            margin-top: 5px;
          }
        }
        .sol-news-item__header {
          @media @sm {
            margin-top: 3px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
  &.sol-col-xl-6 {
    ul.sol-ul {
      padding-right: 0;
      .sol-news-item--large {
        width: 100%;
        margin-right: 0;
      }
      @media @sm {
        padding-right: 15px;
      }
    }
  }
}
.sol-startpage-news__right {
  padding-right: 0 !important;
  ul {
    padding-left: 0;
    @media @md-strict {
      padding-right: 80px;
      padding-left: 80px;
      .sol-news-item__content {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
