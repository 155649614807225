/* components/page-listing.less */
.sol-page-listing {
  @page-listing-border: fadeout(@color-3, 77%);
  @page-listing-bg: @white;
  @page-listing-item-icon-color: @white;
  @page-listing-item-icon-bg: @color-1 ;
  @page-listing-item-bg: rgba(193, 228, 199, 0.3);
  @page-listing-child-color: @black;
  @page-listing-child-icon-color: @color-1;
  ul {
    .no-list-style;
  }
  &-item {
    display: block;
    padding: 38px 20px 27px;
    background-color: @page-listing-item-bg;
    @media @md {
      padding: 15px 0 17px;
    }
    &__wrapper {
      display: flex;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      @media @md {
        max-width: 100%;
        padding-left: 2.9%;
        padding-right: 2.9%;
      }
      @media @sm {
        flex-wrap: wrap;
      }
    }
    &:nth-child(even) {
      background-color: @white;
    }
    &__link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__name {
      color: inherit;
      margin-bottom: 6px;
      margin-top: 0;
      font-weight: 500;
      @media @md-strict {
        font-size: 21px;
        margin-bottom: 5px;
      }
      @media @sm {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
    &__icon {
      font-size: 17px;
      float: left;
      margin-right: 7px;
      height: 20px;
      width: 20px;
      color: @page-listing-item-icon-color;
      background-color: @page-listing-item-icon-bg;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: 2px;
      padding-left: 1px;
      padding-top: 0;
      margin-left: -12px;
    }
    .sol-right {
      flex-basis: 57%;
      @media @sm {
        flex-basis: 100%;
      }
    }
    .sol-left {
      flex-basis: 38%;
      margin-right: 5%;
      p {
        font-size: 19px;
        font-weight: 300;
        line-height: 1.2em;
        text-align: left;
        color: #333333;
        @media @md-strict {
          margin-bottom: 18px;
        }
        @media @sm {
          margin-bottom: 16px;
          font-size: 17px;
        }
      }
      @media @sm {
        flex-basis: 100%;
        margin-right: 0;
      }
    }
    .sol-right {
      ul {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  &-child {
    &__link {
      .sol-btn;
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
      color: @page-listing-child-color;
      text-decoration: none;
      background-color: #c1e4c7;
      padding: 12px 20px;
      color: #313131;
      display: block;
      border-radius: 3px;
      margin-left: 15px;
      line-height: 21px;
      text-decoration: none;
      margin-bottom: 15px;
      @media @md {
        margin-right: 20px;
        margin-left: 0;
        margin-bottom: 16px;
      }
      &:hover {
        text-decoration: underline;
      }
      & i.fa-external-link {
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
      @media @sm {
        font-size: 15px;
        font-weight: normal;
      }
    }
    &__icon {
      font-size: 18px;
      float: left;
      margin-right: 8px;
      color: @page-listing-child-icon-color;
      position: relative;
      top: 2px;
      padding-left: 2px;
    }
  }
}
