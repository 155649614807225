/* decorations/widgets.less */
.sol-widget-decoration-wrapper a {
  text-decoration: none;
}

.sol-widget-decoration {
  @widget-bg: @white;
  @widget-hover-color: @color-1;
  @widget-heading-text: @black;
  @widget-heading-text-hover: @color-1;
  text-align: left;
  overflow: hidden;
  border-radius: 3px;
  background-color: @widget-bg;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;

  & &-img {
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0 !important;
    @media @md {
      height: 124px;
    }
    @media @sm {
      height: 250px;
    }
  }

  & .sv-text-portlet {
    position: relative;
    padding: 15px 20px;
    font-size: 13px;
    line-height: 20px;
  }
  &:hover .font-widget-heading,
  &:hover .font-widget-heading a {
    text-decoration: underline;
    color: @widget-heading-text;
  }
  & .font-widget-heading,
  & .font-widget-heading a {
    display: block;
    //margin-bottom: 3px;
    margin-bottom: 6px;
    color: @widget-heading-text;
    font-family: @main-font;
    font-size: 21px;
    font-weight: bold;
    text-decoration: none;
    .transition(color);
    line-height: 1.3;
  }

  & .font-normal {
    margin-bottom: 0;
    //font-size: 21px;
    font-size: 19px;
    font-weight: 300;
    color: @black;
    a,
    a:hover {
      background-image: none;
      transition: none;
      color: @black;
      &:after {
        border-bottom: none;
      }
    }
  }
  &:hover {
    .font-normal,
    .font-normal a {
      text-decoration: none;
    }
  }
  .font-widget-heading {
    color: @color-4;
  }
}

.sol-video-widget {
  cursor: pointer;
  .sol-widget-decoration-img:before {
    content: '';
    background-image: url('@{sol-icons-path}/video-ikon-bla.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.sol-widget-decoration .sv-image-portlet {
  margin-bottom: 0!important;
}
