/* decorations/forms.less */
.sol-form,
.sol-form-decoration {
  @form-bg: @white;
  @form-divider: @gray-4;
  padding: 20px 20px 40px;
  border-radius: 3px;
  background-color: @form-bg;
  margin-bottom: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  @media @md {
    padding: 20px;
  }
  @media @sm {
    padding-right: 10px;
    padding-left: 10px;
  }
  & h2 {
    margin-top: 0;
  }
  & form {
    .font-normal {
      margin-bottom: 0;
    }
    & input[type='email'],
    & input[type='number'],
    & input[type='password'],
    & input[type='tel'],
    & input[type='text'],
    & input[type='url'],
    select,
    textarea {
      .input-text;
      padding-top: 10px;
      &:invalid {
        .input-text-invalid;
      }
      &:required:valid {
        .input-text-valid;
      }
      & ~ span.font-normal {
        margin-top: 10px;
      }
    }
    & textarea {
      height: auto;
      min-height: 150px;
    }
    select {
      .input-select;
    }
    & .sol-form-field {
      margin-bottom: 10px;
    }
    .sv-form-subalternativefield {
      > div {
        float: none!important;
      }
    }
    .sv-form-textfield {
      & + span {
        font-size: 14px;
      }
    }
    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & + .font-normal {
        font-size: 14px;
      }
      & > label {
        .input-label; 
        margin-bottom: 7px;
      }
      & > span {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }
    & .sv-form-datefield {
      & > div {
        width: 50%;
        margin: 0 !important;
        &:nth-of-type(1) {
          padding-right: 5px;
        }
        &:nth-of-type(2) {
          padding-left: 5px;
        }
        & select {
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    & br {
      display: none;
    }
    .font-normal {
      display: block;
    }
    & .sv-formField {
      margin-bottom: 26px;
      @media @sm {
        width: 100%;
        padding: 0;
      }
    }
    & input[type='checkbox'] {
      .input-checkbox;
    }
    & input[type='radio'] {
      .input-radio;
    }
    & button[type='submit'],
    & input[type='submit'] {
      .sol-btn;
      margin-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
      & > i.fa {
        margin-right: 11px;
        margin-left: -15px;
      }
    }
    & .sol-form-divider {
      .input-divider;
    }
  }
}

.sol-event-form__categories {
  .sol-form-error-msg {
    color: #de404e;
    display: block;
  }
}