.sol-portal-btn--rose() {
  @portal-btn-bg: #c8496b;
  @portal-btn-bg--hover: #992141;
  @portal-btn-bg--active: #c8496b !important;
  @portal-btn-border--active: #992141 !important;
  background-color: @portal-btn-bg !important;
  color: @white !important;

  &:hover {
    background-color: @portal-btn-bg--hover !important;
  }
  &:active {
    background-color: @portal-btn-bg--active !important;
    border: 2px solid @portal-btn-border--active;
  }
}

.sol-portal--rose {
  @theme-color: #c8496b;
  @light-color: tint(@theme-color, 80%);

  @menu-btn__bg: rgba(200, 73, 107, 0.2);;
  @menu-btn__bg--hover: rgba(200, 73, 107, 0.1);
  @menu-btn__bg--active: rgba(200, 73, 107, 0.7);

  .sol-mobile-header {
    > div {
      > a {
        > span {
          @media @md {
            color: #c8496b;
          }
        }
      }
    }
  }

  .sol-hamburger {
    &-wrapper {
      .sol-hamburger {
        > span {
          @media @md {
            background-color: @theme-color;
          }
        }
      }
    }
  }

  .sol-icon__reserve {
    .icon-size(87px, 100px);
    margin-right: 30px;
    &::after {
      background-image: url('@{sol-icons-path}/boka-lokal-rosa.svg');
    }
  }

  .sol-icon__subscribe {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/foreningsnytt-rosa.svg');
    }
  }

  .sol-icon__cloud {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/tankar-rosa.svg');
    }
  }

  .sol-top-logo {
    text-decoration: none;
    position: absolute;
    top: 22px;
    > span {
      display: block;
      font-size: 36px;
      text-align: left;
      color: @theme-color;
      font-weight: 600;
    }
  }

  .sol-top-search__form {
    input[type="search"] {
      border-color: @theme-color;
    }
    button[type="submit"] i.sol-icon:after {
      background-image: url('@{sol-icons-path}/search-pink.svg');
    }
  }
  .sol-mobile-header .sol-mobile-search-button i.sol-icon:after {
    background-image: url('@{sol-icons-path}/search-pink.svg');
  }

  .sol-back-to-main-site-btn {
    .button;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -1px;
    min-height: 30px !important;
    padding-top: 0;
    padding-bottom: 0;
    background-color: @menu-btn__bg;
    &:hover {
      background-color: @menu-btn__bg--hover;
    }
    &:active {
      background-color: @menu-btn__bg--active;
    }
    @media @md {
      border: solid 1px rgba(200, 73, 107, 0.5);
    }
    &__wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 26px;
      @media @md {
        display: none;
      }
    }
  }
  .sol-top-shortcut__item {
    border-right-color: fadeout(@theme-color, 80%);
    &:hover {
      background-color: fadeout(@theme-color, 95%);
    }
  }
  // Undersidas komponenter
  /*.font-heading-1, .font-heading-2, .font-heading-3, .font-heading-4, .font-heading-5, .font-heading-6,
  .font-heading-1-smaller {
    color: @theme-color !important;
  }*/

  .sol-page-tools__item button.sol-contact__button {
    background-color: rgba(200, 73, 107, 0.2) !important;
    color: @black !important;
    &:hover {
      background-color: darken(@theme-color, 10%) !important;
      span, .fa {
        color: @white;
      }
    }
    &:active {
      border: none !important;
    }
    &[aria-expanded="true"] {
      background-color: #c8496b !important;
      span, .fa {
        color: @white;
      }
    }
  }
  .sol-feedback {
    &__content {
      background-color: @light-color;
    }

    &__answers {
      button {
        @button-bg: #c8496b;
        @dark-color: darken(@button-bg, 10%);
        background-color: @button-bg !important;
        color: @white !important;
        &:hover {
          background-color: @dark-color !important;
        }
      }
    }

  }
  .sol-collapsible {
    &__header {
      background-color: @light-color;
      &__text {
        color: @black !important;
      }
    }
  }
  header {
    .sol-top {
      padding: 0 !important;
      height: 120px;
      @media @md {
        height: 46px;
        background-color: rgba(200, 73, 107, 0.2);
      }
    }
    .sol-bottom {
      background-color: @theme-color;
      .sol-top-nav__item {
        font-size: 19px;
      }
    }
  }
  .sol-landing-page__highlighted-news {
    &__header {
      background-color: @theme-color !important;
    }
  }
  .sol-landing-page-wrapper {
    .sol-page-listing-item:nth-child(odd) {
      background-color: fadeout(@theme-color, 90%);
    }
    .sol-page-listing-child {
      &__link {
        font-size: 19px;
        .sol-portal-btn--rose;
        &:active {
          border: none !important;
        }
      }
    }

    .sol-landing-page {

      &__top {
        .font-heading-1 {
          color: @theme-color;
        }
      }

    }
  }
  /*.sol-news-item {
    &__header {
      > a {
        color: @theme-color !important;
      }
    }
  }*/
  .sol-content-n-popular-pages-wrapper {
    background-color: @light-color;
    padding-top: 23px;
    padding-bottom: 28px;
    .sv-font-startsida1f {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      line-height: 1.21;
      text-align: left;
      @media @md {
        font-size: 19px;
      }
    }
    .sol-popular-pages {
      background-color: @light-color;
      width: 85%;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      &__link {
        .sol-portal-btn--rose;
        font-size: 19px;
      }

    }
  }
  .sol-puff {
    &__content__title {
      color: @theme-color;
    }
  }
  .sol-widget-decoration .font-widget-heading,
  .sol-widget-decoration .font-widget-heading a {
    color: @theme-color;
  }
  .sol-video-widget .sol-widget-decoration-img:before {
    background-image: url('@{sol-icons-path}/video-ikon-rosa.svg');
  }
  .sol-startpage-news__right .sol-news-item__content {
    border-bottom-color: fadeout(@theme-color, 60%);
  }
  .sol-content-wrapper .sol-contact {
    @border: fadeout(#c8496b, 60%);
    @border-thick: fadeout(#c8496b, 80%);
    @background: fadeout(#c8496b, 90%);
    @header: #c8496b;
    border-color: @border;
    &:before {
      border-bottom-color: @border;
    }
    &__header {
      border-bottom-color: @border-thick;
      h2 {
        color: @header;
      }
    }
    &__item__left.sv-column-5.sol-col-md-5 {
      h4.font-heading-2 {
        color: @black !important;
      }
    }
    &__item__right.sv-column-7.sol-col-md-7 {
      background-color: @background;
    }
    &__political-responsibility {
      border-top-color: @border;
    }
  }
}
