.sol-mobile-menu-wrapper {
  position: relative;
}

.sol-mobile-menu-container {
  @mobile-menu-text: @black;
  @mobile-menu-text-active: @white;
  @mobile-menu-bg-active: @color-2;
  @mobile-menu-border: fadeout(@color-2, 40%);
  @mobile-menu-button: fadeout(@color-2, 90%);
  display: none;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;
  height: 0;
  min-height: 0;
  .transition(~'height, min-height', 250ms);
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.43);
  @media @md {
    &.sol-active {
      display: block;
    }
  }

  & > ul {
    border-top: 2px solid @mobile-menu-border;
  }

  & .sol-menu-list {
    width: 100%;
    &-expanded-init {
      display: block;
    }
  }

  & .sol-menu-item {
    position: relative;
    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 15px 49px 15px 19px;
      border-bottom: 2px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @mobile-menu-text;

      & .sol-menu-item-display-name {
        font-size: 19px;
      }
    }
    & .sol-menu-button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      top: 0;
      height: 54.5px;
      right: 0;
      width: 55px;
      background-color: @mobile-menu-button;
      cursor: pointer;
      & i.sol-icon {
        position: absolute;
        right: 18px;
        top: 50%;
        .icon-size(15px);
        margin-top: -7px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
        .rotate(-90deg);
      }
    }

    &[data-menu-level='1'] > .sol-menu-item-link {
      padding-left: 30px;
      & > .sol-menu-item-display-name {
        font-weight: 700;
      }
    }

    &[data-menu-level='2'] .sol-menu-item-link {
      padding-left: 45px;
    }

    &[data-menu-level='3'] .sol-menu-item-link {
      padding-left: 60px;
    }

    &[data-menu-level='4'] .sol-menu-item-link {
      padding-left: 75px;
    }

    &[data-expanded='true'] > .sol-menu-button {
      & i.sol-icon {
        .rotate(0deg);
      }
    }

    &[data-expanded='true'][data-has-children='false'],
    &[data-is-current='true'] {
      & > .sol-menu-item-link {
        color: @mobile-menu-text-active;
        background-color: @mobile-menu-bg-active;
      }
      & > .sol-menu-button {
        & i.fa {
          color: white;
        }
      }
    }

    &[data-node-type='sv:folder'] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}
