.sol-portal--green {
  @theme-color: #2a8659;
  @light-color: tint(@theme-color, 80%);

  @menu-btn__bg: #c1e4c7;
  @menu-btn__bg--hover: rgba(193, 228, 199, 0.4);
  @menu-btn__bg--active: #6dab78;

  .sol-mobile-header {
    > div {
      > a {
        > span {
          @media @md {
            color: #2a8659;
          }
        }
      }
    }
    .sol-hamburger {
      &-wrapper {
        .sol-hamburger {
          > span {
            @media @md {
              background-color: @theme-color;
            }
          }
        }
      }
    }
  }

  .sol-icon__reserve {
    .icon-size(87px, 100px);
    margin-right: 30px;
    &::after {
      background-image: url('@{sol-icons-path}/boka-lokal-gron.svg');
    }
  }

  .sol-icon__subscribe {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/foreningsnytt-gron.svg');
    }
  }

  .sol-icon__cloud {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/tankar-gron.svg');
    }
  }

  .sol-top-logo {
    text-decoration: none;
    position: absolute;
    top: 22px;
    > span {
      display: block;
      font-size: 36px;
      text-align: left;
      color: @theme-color;
      font-weight: 600;
    }
  }
   .sol-top-search__form {
    input[type="search"] {
      border-color: @theme-color;
    }
    button[type="submit"] i.sol-icon:after {
      background-image: url('@{sol-icons-path}/search-green.svg');
    }
  }
  .sol-mobile-header .sol-mobile-search-button i.sol-icon:after {
    background-image: url('@{sol-icons-path}/search-green.svg');
  }

  .sol-back-to-main-site-btn {
    .button;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -1px;
    min-height: 30px !important;
    padding-top: 0;
    padding-bottom: 0;
    background-color: @menu-btn__bg;
    &:hover {
      background-color: @menu-btn__bg--hover;
    }
    &:active {
      background-color: @menu-btn__bg--active;
    }
    @media @md {
      border: solid 1px #c1e4c7;
    }
    &__wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 26px;
      @media @md {
        display: none;
      }
    }
  }
  .sol-top-shortcut__item {
    border-right-color: @menu-btn__bg;
    &:hover {
      background-color: fadeout(@theme-color, 95%);
    }
  }

  .sol-portal-btn--green() {
    @portal-btn-bg: #2a8659;
    @portal-btn-bg--hover: #19774c;
    @portal-btn-bg--active: #2a8659 !important;
    @portal-btn-border--active: #19774c !important;
    background-color: @portal-btn-bg !important;
    color: @white !important;
    &:hover {
      background-color: @portal-btn-bg--hover !important;
    }
    &:active {
      background-color: @portal-btn-bg--active !important;
      border: 2px solid @portal-btn-border--active;
    }
  }

  header {
    .sol-top {
      padding: 0 !important;
      height: 120px;
      @media @md {
        height:  46px;
        background-color: rgba(193, 228, 199, 0.3);
      }
    }

    .sol-bottom {
      background-color: @theme-color;
      .sol-top-nav__item {
        font-size: 19px;
      }
    }
  }
  .sol-landing-page__highlighted-news {
    &__header {
      background-color: @theme-color !important;
    }
  }
  .sol-landing-page-wrapper {
    .sol-page-listing-child {
      &__link {
        font-size: 19px;
        .sol-portal-btn--green !important;
        &:active {
          border: none !important;
        }
      }
    }

    .sol-landing-page {

      &__top {
        .font-heading-1 {
          color: @theme-color;
        }
      }

    }
  }

  .sol-content-n-popular-pages-wrapper {
    background-color: @light-color;
    padding-top: 23px;
    padding-bottom: 28px;
    .sv-font-startsida1f {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      line-height: 1.21;
      text-align: left;
      @media @md {
        font-size: 19px;
      }
    }
    .sol-popular-pages {
      background-color: @light-color;
      width: 85%;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      &__link {
        .sol-portal-btn--green;
        font-size: 19px;
      }

    }
  }
  .sol-puff {
    &__content__title {
      color: @theme-color;
    }
  }
  .sol-widget-decoration .font-widget-heading,
  .sol-widget-decoration .font-widget-heading a {
    color: @theme-color;
  }
  .sol-video-widget .sol-widget-decoration-img:before {
    background-image: url('@{sol-icons-path}/video-ikon-gron.svg');
  }
  .sol-startpage-news__right .sol-news-item__content {
    border-bottom-color: fadeout(@theme-color, 60%);
  }
  .sol-page-tools__item button.sol-contact__button {
    background-color: #c1e4c7 !important;
    color: @black !important;
    &:hover {
      background-color: darken(#2a8659, 10%) !important;
      span, .fa {
        color: @white;
      }
    }
    &[aria-expanded="true"] {
      background-color: #2a8659 !important;
      span, .fa {
        color: @white;
      }
    }
  }

  .sol-content-wrapper .sol-contact {
    @border: #c1e4c7;
    @border-thick: rgba(193, 228, 199, 0.4);
    @background: rgba(193, 228, 199, 0.2);
    @header: #2a8659;
    border-color: @border;
    &:before {
      border-bottom-color: @border;
    }
    &__header {
      border-bottom-color: @border-thick;
      h2 {
        color: @header;
      }
    }
    &__item__left.sv-column-5.sol-col-md-5 {
      h4.font-heading-2 {
        color: @black !important;
      }
    }
    &__item__right.sv-column-7.sol-col-md-7 {
      background-color: @background;
    }
    &__political-responsibility {
      border-top-color: @border;
    }
  }
}
