.sol-news-page {
  &__wrapper {
    .sol-news-date {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      &__text {
        font-size: 17px;
        line-height: 21px;
        font-weight: 300;
        color: #313131;
      }
      &__icon {
        display: inline-flex;
        align-items: center;
        margin-right: 7px;
        position: relative;
        top: -0.6px;
      }
    }
  }
}