/* decorations/collapsible.less */
.sol-collapsible {
  @collapsible-bg: @white;
  @collapsible-header-bg: mix(@color-2, @white, 45%);
  @collapsible-header-text: @black;
  @collapsible-icon: @black;
  @collapsible-icon-bg: @white;
  max-width: 570px;
  margin-bottom: 20px;
  &__header {
    .no-appearance;
    .focus-outline;
    position: relative;
    display: block;
    width: 100%;
    padding: 7px 52px 7px 35px;
    background-color: @collapsible-header-bg;
    border-radius: 25px;
    color: @collapsible-header-text;
    text-align: left;
    text-decoration: none;
    line-height: 36px;
    font-size: 20px;
    font-family: @main-font;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .3s ease;
    margin-bottom: 20px;
    /*margin-top: -5px;*/
    &:hover:not(.sol-collapsible__header--active) {
      background-color: rgba(110, 205, 233, 0.3);
      .sol-collapsible__header__text {
        text-decoration: underline;
      }
    }
    &__text {
      color: #333333;
    }
    &--active {
      .sol-collapsible__header__text {
        color: #fff;
      }
    }
    &__icon {
      background-color: @collapsible-icon-bg;
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -20px;
      border-radius: 50%;
      &::after,
      &::before {
        content: '';
        display: block;
        background-color: @collapsible-icon;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 19px;
        height: 2px;
        margin-left: -9px;
        margin-top: -1px;
        border-radius: 2px;
        .transition(all);
      }
      &::after {
        .rotate(90deg);
      }
    }
    &--active {
      background-color: #204560;
      color: @white;
      & .sol-collapsible__header__icon {
        &::after {
          .rotate(0deg);
        }
      }
    }
  }
  & .sol-collapsible__content,
  & .sv-decoration-content {
    display: none;
    background-color: @collapsible-bg;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 25px 20px 5px;
    margin: -25px 20px 0;
    margin-bottom: 20px;
    p.font-normal {
      font-family: PFDINTextStd-Light, sans-serif;
      font-weight: 300;
      line-height: 1.57;
      text-align: left;
      color: #333333;
      @media @sm {
        font-size: 18px;
      }
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    & p:last-of-type {
      margin: 0;
    }
    .sv-editing-mode & {
      display: block;
    }
  }

}
