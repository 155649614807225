/* decorations/buttons.less */
.sol-btn {
  .button;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    .button;
    display: inline;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    &::after {
      display: none;
    }
  }
}
.sol-btn-small {
  .button-small();
}
.sv-text-portlet.sol-font-btn-small {
  .font-btn {
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 0;
    & > a {
      .button-small;
    }
  }
}

.sol-reset-filter-btn {
  .button-small();
  background-color: @color-4;
  color: @white;
  &:hover {
    background-color: fadeout(@color-4, 40%);
  }
}


.sol-decoration-btn {
  a {
    .sol-btn;  
    padding: 8px 25px;
    background-image: none !important;   
    &:hover {
      background-image: none !important;
    }
    &:after {
      display: none;
    }
  }
}