.sol-focus-news {
  box-shadow: 0 0 26px 0 rgba(51, 51, 51, 0.4);
  border-radius: 3px;
  .sol-focus-news-item {
    background-color: @color-1;
    padding-bottom: 20px;
    width: 100%;

    &__link {
      font-family: @main-font;
      text-decoration: none;

      &:hover {
        & .sol-focus-news-item__header {
          text-decoration: underline;
        }
      }
    }
    &__image-container {
      height: 200px;
      background: #c1e4c7;

      & img {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 370px;
        height: 100%;
        object-fit: cover;
      }
    }
    &__no-image {
      padding-top: 10px;
    }
    &__date {
      margin: 16px 36px 5px;
      color: #fff;
      font-size: 17px;
      font-weight: 300;

      & .sol-icon {
        margin-right: 7px;
        position: relative;
        top: 2px;

        &:after {
          width: 17px;
          height: 17px;
        }
      }
    }
    &__text {
      padding: 12px 36px 20px;
      min-height: 185px;
      display: block;
      overflow: hidden;
    }
    &__header {
      color: #fff;
      margin-top: 0;
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 6px;
    }
    &__preamble {
      display: block;
      color: #fff;
      font-size: 19px;
      margin-bottom: 0;
    }
  }
}

.sol-startpage__content__top {
  & .sol-startpage-focusnews {
    & .pagecontent {
      width: 100%;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-evenly;

      @media @sm {
        justify-content: center;
        flex-wrap: wrap;
      }

      & .sv-custom-module {
        margin: 10px 3% 10px 0;
        max-width: 47%;
        
        & > div:nth-of-type(2), & > div > div {
          height: 100%;

          & .sol-focus-news {
            height: 100%;
            
            &-item {
              height: 100%;
            }
          }
        }

        @media @sm {
          flex: 1 0 100% !important;
          max-width: 370px !important;
          margin: 20px 0;
        }
      }
    }
  }
}

.sol-startpage__content__top {
  @media @md {
    padding-left: 30px;
    padding-right: 30px;
  }
}
