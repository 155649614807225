.sol-top-shortcut {
  display: flex;
  max-width: 1263px;
  flex-direction: row;
  padding: 15px 0;
  &__item {
    flex: 1 33.3333%;
    text-align: center;
    padding: 0 10px;
    min-height: 112px;
    text-decoration: none;
    border-right: 2px solid #E5F6FB;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    &:hover {
      background-color: #e5f6fb;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
      padding-right: 0;
    }
    &__name {
      display: inline-block;
      font-size: 21px;
      line-height: 27px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      margin-top: 4px;
    }
    & .sol-icon {
      position: relative;
      .icon-size(87px, 100px);
      &__briefcase {
        .icon-size(87px, 100px);
        margin-right: 30px;
      }
      &__e-services {
        .icon-size(90px, 80px);
        margin-right: 35px;
      }
      &__compare {
        .icon-size(88px, 99px);
        margin-right: 23px;
      }
    }
    [class*="sol-icon"] {
      margin-right: 20px;
    }
    [class*="framtidsyrken-1"] {
      margin-right: 20px;
    }
    [class*="framtidsyrken-2"] {
      margin-right: 20px;
    }
    [class*="sol-icon__hitta-hit"] {
      margin-right: 15px;
    }
    [class*="sol-icon__e-tjanster-1"] {
      margin-right: 25px;
    }
    [class*="sol-icon__e-tjanster-2"] {
      margin-right: 15px;
    }
    [class*="sol-icon__akutella-arbeten"] {
      margin-right: 15px;
    }
    [class*="felanmalan"] {
      margin-right: 25px;
    }
    [class*="sol-icon__bibliotek"] {
      margin-right: 25px;
    }
    [class*="sol-icon__foreningsregister"] {
      margin-right: 15px;
    }
    [class*="sol-icon__evenemang"] {
      margin-right: 25px;
    }
    [class*="sol-icon__felanmalan--blå"] {
      margin-right: 23px;
    }
    [class*="insyn-i-politiken"] {
      margin-right: 30px;
    }
    [class*="jamforelseguiden"] {
      margin-right: 25px;
    }
    [class*="lediga-jobb"] {
      margin-right: 20px;
    }
    [class*="servicecenter"] {
      margin-right: 25px;
    }
    [class*="stadsbyggnadsportalen-2"] {
      margin-right: 25px;
    }
    [class*="utbildningar"] {
      margin-right: 25px;
    }
    [class*="vi-erbjuder"] {
      margin-right: 25px;
    }
    [class*="elev"] {
      margin-right: 20px;
    }
    .sol-icon {
      @media @md {
        .icon-size(57px, 64px);
        margin-right: 0;
      }
      @media @sm {
        .icon-size(40px, 50px);
        [class*="sol-icon__akutella-arbeten"] {
          .icon-size(40px, 50px);
        }

        [class*="sol-icon__bibliotek"] {
          .icon-size(40px, 50px);
        }

        [class*="sol-icon__elev"] {
          .icon-size(45px, 50px);
        }
      }
    }

    @media @md {
      display: block;
      padding-top: 5px;
      min-height: 100px;
      position: relative;
      flex: 1 0;
      &:first-child,
      &:last-child {
        padding-left: 0;
      }

      &__name {
        display: block;
        font-size: 18px;
        position: absolute;
        bottom: 2px;
        align-self: center;
        left: 0;
        right: 0;
      }

      & .sol-icon {
        &__briefcase {
          .icon-size(58px, 67px);
          margin-right: 0;
        }
        &__e-services {
          .icon-size(61px, 70px);
          margin-right: 0;
        }
        &__compare {
          .icon-size(57px, 64px);
          margin-right: 0;
        }
      }
    }
    @media @sm {
      padding-top: 10px;
      min-height: 85px;
      &:first-child {
        margin-left: 5px;
      }

      &:nth-child(2) {
        padding-right: 23px;
        padding-left: 23px;
      }

      &:last-child {
        margin-right: 5px;
      }

      &__name {
        font-size: 13px;
      }

      & .sol-icon {
        &__briefcase {
          .icon-size(40px, 46px);
        }
        &__e-services {
          .icon-size(41px, 46px);
        }
        &__compare {
          .icon-size(44px, 50px);
        }
      }
    }
  }
}

