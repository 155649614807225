.sol-listing-page--news {
  &__header--special {
    display: none;
  }
  .sol-news-item {
    @date-text: @black;
    @header-text: @color-1;
    @desc-text: @black;
    @border: fadeout(@color-2, 70%);
    @large-content-bg: @color-1;
    @large-text: @white;
    &__content {
      position: relative;
      border-bottom: 1px solid @border;
      @media @sm {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &__date {
      @media @md {
        font-size: 14px;
        font-weight: 300;
      }
    }
    &__header {
      @media @md {
        font-size: 19px;
      }
    }
    &__desc {
      @media @md {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  .sol-news-item__img {
    left: 25px;
    height: 115px;
    min-height: 0;
    width: 180px;
    position: absolute;
    background-size: cover;
    background-position: 50%;
    top: 0;
    margin-right: auto;
    @media @sm {
      position: static;
      left: auto;
      top: auto;
      width: 100%;
      margin-bottom: 16px;
      min-height: 140px;
    }
  }
  /* news-item.less */
  .sol-news-item {
    @date-text: @black;
    @header-text: @color-1;
    @desc-text: @black;
    @border: fadeout(@color-2, 70%);
    @large-content-bg: @color-1;
    @large-text: @white;
    & &__date {
      display: block;
      font-size: 17px;
      line-height: 21px;
      font-weight: 300;
      color: @date-text;
      margin-bottom: 4px;
      .sol-icon {
        .icon-size(18px, 17px);
        margin-right: 7px;
        margin-top: 1px;
        float: left;
      }
    }
    & &__header {
      display: block;
      font-size: 21px;
      line-height: 27px;
      color: @header-text;
      margin-top: 0;
      margin-bottom: 4px;
      a {
        text-decoration: none;
        color: @header-text;
      }
    }
    &:hover &__header {
      text-decoration: underline;
    }
    & &__desc {
      display: block;
      font-size: 21px;
      line-height: 27px;
      color: @desc-text;
      font-weight: 300;
      margin-top: 0;
    }
    &--large {
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.08);
    }
    &--large &__content {
      background-color: @large-content-bg;
      padding: 0 45px 29px 21px;
      & > .sol-icon__arrow--white {
        .icon-size(19px);
        position: absolute;
        bottom: 14px;
        right: 14px;
      }
    }
    &--large &__date,
    &--large &__header,
    &--large &__desc {
      color: @large-text;
      a {
        color: @large-text;
      }
    }
  }
  .sol-news-archive-link {
    .link-underline;
    font-size: 19px;
    font-weight: 300;
    line-height: 24px;
    color: @color-1;
    margin-top: 15px;
    float: right;
  }
  .sol-col-md-7.sol-startpage-news__left {
    ul {
      width: 100%;
      display: flex;
      height: auto;
      .sol-news-item--large {
        width: 48%;
        float: left;
        &:first-child {
          margin-right: 34px;
        }
        .sol-news-item__img {
          min-height: 200px;
        }
        .sol-news-item__content {
          height: 100%;
        }
      }
    }
  }
  .sol-col-md-5.sol-startpage-news__right ul {
    padding-left: 0;
  }

  .sol-news-item--no-img {
    .sol-news-item {
      &__content {
        min-height: 0;
        padding-left: 25px;
      }
    }
  }
}
