/* decorations/lists.less */
ol,
ul {
  @list-icon: @color-2;
  @list-number: @color-2;
  &.sol-bullet-list,
  &.sol-numbered-list {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none !important;
    & li {
      position: relative;
      margin-bottom: 7px;
      padding-left: 20px;
      &::before {
        position: absolute;
        left: 0;
        top: 8px;
        display: inline-block;
        font-weight: 700;
      }
      a {
        .link-style;
      }
    }
  }
  &.sol-bullet-list li::before {
    .icon(@fa-var-angle-right, 22px);
    color: @list-icon;
    content: "\25CF";
    line-height: 0.5em;
  }
  &.sol-numbered-list {
    & li {
      counter-increment: step-counter;
      padding-left: 20px;
      &::before {
        content: counter(step-counter) '.';
        color: @list-number;
        font-size: 18px;
        text-align: center;
        top: 2px;
      }
    }
  }
}
