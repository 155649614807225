.sol-chat-btn {
  display: none;
  .no-appearance;
  .no-outline-focus;
  cursor: pointer;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background-color: @color-4;
  position: fixed;
  right: 16px;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  z-index: 70;
  &__text {
    color: @white;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    font-family: @main-font;
  }
  .sol-icon__chat { 
    margin-bottom: 6px;
    .icon-size(25.4px, 28.8px);
  }
  @media @md {
    width: 102px;
    height: 102px;
    bottom: 75px;
  }
  @media @sm {
    width: 75px;
    height: 75px;
    bottom: 110px;
    flex-direction: row;
    &__text {
      font-size: 15px;
    }
    .sol-icon__chat {
      display: none;
    }
  }
}
