/*.sol-tabs {
  @border-bottom: fadeout(@primary-color, 60%);
  @border-bottom-active: @primary-color;
  &__button-container {
    .no-list-style;
    display: flex;
    border-bottom: 4px solid @border-bottom;
    @media @sm {
      display: block;
      border-bottom: none;
    }
    & > li {
      flex: 1;
    }
  }
  &__button {
    .outline-focus;
    display: block;
    font-family: @text-font;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    min-height: 44px;
    line-height: 44px;
    border-bottom: 4px solid transparent;
    margin-bottom: -4px;
    @media @sm {
      border-bottom-color: @border-bottom;
    }
    &--active {
      border-bottom-color: @border-bottom-active;
    }
  }
}*/

.sol-tabs-container {
  padding-bottom: 50px;
  @button-active: @color-4;
  @border-color: @color-4;
  margin-bottom: 20px;
  margin-top: 40px;
  .sol-tab-button {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 8px 25px 10px;
    min-height: 37px;
    margin-right: 0;
    margin-left: 1.3%;
    border-left-style: solid;
    border-left-width: 2px;
    border-top-style: solid;
    border-top-width: 2px;
    border-right-style: solid;
    border-right-width: 2px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-color: @white;
    background-color: @white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    float: left;
    box-shadow: 2px -2px 4px 0 rgba(0, 0, 0, 0.05);
    &.sol-tab-button-active {
      background-color: @button-active;
      border-color: @border-color;
      color: @white;
      &:after,
      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: transparent;
        border-top-color: @border-color;
        border-width: 6px;
        margin-left: -6px;
      }
      &:before {
        border-color: transparent;
        border-top-color: @border-color;
        border-width: 9px;
        margin-left: -9px;
      }
    }
    @media @sm {
      padding: 8px 15px 10px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }
  .sv-decoration-content {
    border-top-style: solid;
    border-top-width: 3px;
    border-color: @border-color;
    padding-top: 13px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    background-color: @white;
  }
  .sv-load-more {
    display: block !important;
  }
}
