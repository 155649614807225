.sol_signlanguage_link {
  display: none;
  iframe {
    width: 100%;
    height: 380px;
    @media @md {
      width: 100%;
      height: 320px;
    }
    @media @sm {
      width: 100%;
      height: 220px;
    }
  }
}
