.sol-available-positions {
    @dark-slate-blue:#204560;
    letter-spacing: 0.01em;
    margin-bottom: 1.5rem;

    * {
        font-weight:300;
    }
    
    &__heading {
        padding-left: 17px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0;
        background-color: @dark-slate-blue;
        color: white;
        font-size: 18px;
        border-radius: 3px 3px 0px 0px;
        line-height: 1.5;
        padding-right: 31px;
        padding-left: 29px;
        padding-top: 7px;
        padding-bottom: 4px;
        @media (min-width: @md-min) {
            display: inline-block;
        }
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    
    .sol-available-positions__list {
        list-style: none;
        padding:0;
        border:1px solid #F9F9F9;
        margin:0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08)
    }

    .sol-available-positions__list-item {
        padding-left:17px;
        padding-top:11px;
        padding-bottom:14px;

        @media (min-width: @md-min) {
            padding-left:28px;
            padding-top:21px;
            padding-bottom:24px;
        }

        &:nth-child(even) {
            background-color: rgba(110, 205, 233, 0.1);
        }
    }

    .sol-available-positions__title {
        font-size:19px;
        margin:0;
        margin-bottom: 5px;
        a {
            font-weight: 400;
            color:@dark-slate-blue;
        }
        @media (min-width: @lg-min) {
            font-size:21px;
        }
    }

    .sol-available-positions__application-deadline {
        //font-size:19px;
        //line-height: 1.3em;
        //font-family: PFDINTextStd-Light, sans-serif;
        
        font-size: 17px;
        line-height: 1.3;
        font-weight: 300;
        color: #313131;
        //margin-bottom: 4px;
        
        .sol-icon {
            //.icon-size(18px, 17px);
            margin-right: 7px;
            margin-top: 1px;
            float: left;
        }
    }

    .sol-available-positions-message {
        font-size: 19px;
        line-height: 1.3em;
    }

}

.sol-available-positions-btn-group {
    margin-bottom: 1rem;
    
    
    .font-btn {
        margin-bottom: 0.5rem;
        @media (min-width: @sm-min){
            display: inline-block; 
        }
    }
    
    .sol-external-link-icon {
        vertical-align: -8%;
        font-size: 14px!important;
        margin: 0 0 0 5px !important;
    }

}