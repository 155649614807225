/* components/page-tools.less */
.page-tool-box() {
  display: none;
  position: absolute;
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
  text-align: center;
  z-index: 1;
  cursor: default;
}

.sol-page-tools {
  @border: fadeout(@color-2, 60%);
  @background: rgba(110, 205, 233, 0.6);
  text-align: left;
  margin-top: 0;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media @xs {
    margin-bottom: 20px;
  }
  .sol-only-tools{
    margin-left: auto
  }
  &__item {
    position: relative;
    display: inline-block;
    margin-right: 40px;
    @media @xs {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:not(:first-child) {
      margin-left: 0;
    }
    button {
      .no-appearance;
      .focus-outline;
      cursor: pointer;
      font-size: 15px;
      line-height: 18px;
      font-family: @main-font;
      padding-left: 0;
      padding-right: 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: @background;
        visibility: hidden;
      }
      &:hover {
        &::after {
          visibility: visible;
        }
      }
      &.sol-contact__button:hover {
        &::after {
          visibility: hidden;
        }
      }
      &[aria-expanded="true"] {
        background-color: @background;
        border-radius: 2px;
      }
      &[aria-expanded="true"]:hover {
        &::after {
          visibility: hidden;
        }
      }
      @media @xs {
        font-size: 14px;
        /*&:not(.sol-contact__button) {
          height: 40px;
          position: relative;
          margin-right: 15px;
          i {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          span {
            display: block;
            top: 10px;
            position: relative;
          }
        }*/
      }
    }
    .sol-icon {
      margin-right: 8px;
      float: left;
      .icon-size(20px);
      &__listen {
        .icon-size(18px; 20px);
      }
      &__share {
        .icon-size(16px; 13px);
      }
      &__legible {
        .icon-size(17px; 24px);
      }
      &__signlanguage {
        display: flex;
        align-items: center;
        .icon-size(24px);
      }
      @media @xs {
        .icon-size(16px);
        &__listen {
          .icon-size(16px; 20px);
        }
        &__share {
          .icon-size(16px; 13px);
        }
        &__legible {
          .icon-size(17px; 24px);
        }
        &__signlanguage {
          .icon-size(18px);
        }
      }
    }
    &.sol-contact__container {
      margin-right: auto;
      button.sol-contact__button {
        @contact-button-background-color: @color-2;
        @contact-button-color: @black;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        color: @contact-button-color;
        background-color: fadeout(@contact-button-background-color, 60%);
        font-size: 17px;
        border-radius: 3px;
        padding: 7px 15px;
        min-width: 150px;
        cursor: pointer;
        i.fa-comments-o {
          margin-right: 7px;
          font-size: 16px;
          position: relative;
          top: -1px;
        }
        i.fa-angle-down {
          margin-left: 20px;
        }
        &[aria-expanded="true"] {
          background-color: @contact-button-background-color;
          i.fa-comments-o:before {
            content: '';
          }
          i.fa-angle-down {
            margin-left: 50px;
            &:before {
              content: '\f106';
            }
          }
        }
      }
      @media @sm {
        width: 50%;
        top: 10px;
        button.sol-contact__button {
          text-align: left;
          width: 100%;
          i.fa-angle-down {
            float: right;
          }
        }
      }
      @media @xs {
        margin-top: 10px;
        margin-left: 0;
        flex: 0 0 45%;
        top: 0;
        button.sol-contact__button {
          font-size: 14px;
          i.fa-comments-o {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sol-js-toggle-signlanguage {
    line-height: 24px;
  }
  &__box {
    .page-tool-box;
    width: 258px;
    margin-top: 17px;
    margin-left: -106px;
    border: 1px solid @border;
    border-radius: 2px;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
    &:after,
    &:before {
      bottom: 100%;
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
    	border-bottom-color: @white;
    	border-width: 15px;
    	margin-left: -15px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
    	border-bottom-color: @border;
    	border-width: 16px;
    	margin-left: -16px;
    }
    &__item {
      .transition(border);
      float: left;
      width: 64px;
      height: 60px;
      color: @color-1;
      border: none;
      border-right: 1px solid @border;
      border-radius: 0;
      font-size: 22px;
      padding-top: 18px;
      text-align: center;
      margin-right: 0;
      margin-left: 0;
      @media @sm {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }
      & i.fa {
        &.fa-facebook-f,
        &.fa-twitter {
          position: relative;
          top: 2px;
        }
      }
      &:hover {
        border-color: @border;
        opacity: 0.7;
      }
      &:last-child {
        border-right: none;
      }
    }
    &__close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @color-1;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;
      & i.fa {
        font-size: 11px;
      }
    }
    @media @sm {
      width: 162px;
      margin-left: -57px;
    }
  }
}
.sol-readspeaker {
  position: relative;
  & &__box {
    .page-tool-box;
    position: absolute;
    width: 246px !important;
    height: 50px !important;
    top: -30px;
  }
}
