* {
  box-sizing: border-box;
}
a,
button {
  .focus-outline;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.sol-ul,
.sol-ul-container ul {
  .no-list-style;
}
.sol-float-right {
  float: right;
}
.sol-float-left {
  float: left;
}
.sol-hidden {
  display: none;
}
.sol-clearfix {
  .clearfix;
}
.sol-relative {
  position: relative;
}
.sol-no-anim * {
  transition: none !important;
}
.sol-external-link-icon {
  margin-left: 6px;
  font-size: 17px !important;
}
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.sv-portlet p {
  margin-top: 0;
}

.sol-focus-outline-show {
  &,
  [data-whatinput='keyboard'] & {
    outline: 2px solid @focus-border;
    z-index: 10;
  }
  &.sol-clickable-element a,
  &[data-clickable-element] a,
  [data-whatinput='initial'] &,
  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    z-index: 0;
  }
}

.sol-social-container {
  border-radius: 3px;
  background-color: @white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.modal input[type="checkbox"] {
  -webkit-appearance: checkbox; 
}
