.sol-article-wrapper .sv-image-portlet {
    overflow: visible !important;
}
.sol-wide-image {
    &__cnt {
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 0;
    opacity: 0;
        .sv-decoration-content {
            width: 100vw;/* <-- children as wide as the browser window (viewport) */
            margin-left: calc(-((100vw - 100%) / 2));
            img {
                max-width: 834px !important;
            }
            .sv-portlet-image-caption {
                max-width: 834px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}   