
.sol-icon__clock-small {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.sol-event-item {
  &--img, &--no-img {
    display: block;
    text-decoration: none;
    padding: 5px 10px 21px 217px;
    position: relative;
    border-bottom: 1px solid rgba(110, 205, 233, 0.3);
    margin-top: 16px;
    margin-bottom: 20px;
    min-height: 131px;
    &:hover {
      .sol-event-item__title {
        text-decoration: underline;
      }
    }
  }
  &--img {
    @media @md {
      margin-left: 16px;
    }
    @media @sm {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      margin-left: 0;
    }
  }
  &--no-img {

    @media @sm {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      padding-left: 4%;
    }
  }
  &__img {
    left: 12px;
    height: 115px;
    min-height: 0;
    width: 180px;
    position: absolute;
    background-size: cover;
    background-position: 50%;
    top: 0;
    margin-right: auto;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    @media @sm {
      position: static;
      left: auto;
      height: 140px;
      width: 100%;
    }
  }
  &__content {
    @media @sm {
      padding-top: 16px;
    }
  }

  &__date-info {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 21px;
    font-weight: 300;
    color: #313131;
    margin-bottom: 4px;
    font-family: PFDINTextStd-Light,sans-serif;
    .sol-event-item__date__weekday {
      text-transform: capitalize;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  &__title {
    font-size: 21px;
    line-height: 1.1;
    text-align: left;
    color: #204560;
  }
  &__description {
    font-weight: 300;
    text-align: left;
    color: #333;
    font-size: 19px;
    font-family: PFDINTextStd-Light,sans-serif;
    margin-top: 5px;
  }
}
