/* decorations/tables.less */
.sv-text-portlet table.sol-table-decoration, .sv-text-portlet table.sol-table-decoration--wide {
  @table-border: rgba(110, 205, 233, 0.4);
  @thead-color: @white;
  @thead-bg: @color-1;
  @thead-border: rgba(110, 205, 233, 0.4);
  @tbody-bg: @white;
  @tbody-odd: @white;
  @tbody-border: rgba(110, 205, 233, 0.4);
  border-collapse: collapse;
  border-radius: 3px;
  border-style: hidden;
  box-shadow: 0 0 0 1px @table-border;
  overflow: hidden;

  & caption {
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 600;
  }

  & thead {
    background-color: @thead-bg;

    & th {
      padding: 11px 18px;
      border-right: 1px solid @thead-border;

      & p {
        font-family: @main-font;
        font-size: 20px;
        line-height: 22px;
        color: @thead-color;
        font-weight: 700;
        line-height: 1.15;
        text-align: left;

        @media @sm {
          font-size: 18px;
        }

        & a {
          text-decoration: underline;
          text-decoration-color: @link-underline;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            text-decoration: none;
            border-bottom: 2px solid @link-underline;
            &:hover {
              text-decoration: none !important;
              border-bottom: 2px solid @link-underline-hover;
            }
          }
    
          &:hover {
            text-decoration: underline;
            text-decoration-color: @link-underline-hover;
          }
        }
      }
      &:last-child {
        border: none;
        border-top-right-radius: 3px;
      }
      &:first-child {
        border-top-left-radius: 3px;
      }
    }
  }

  & tbody {
    background-color: @tbody-bg;
    border-color: @tbody-border;
    & tr {
      &:nth-child(even) {
        background-color: rgba(110, 205, 233, 0.1);
      }
      td {
        border-right: 1px solid rgba(110, 205, 233, 0.4);
        padding: 11px 18px;
 
        & p {
          font-family: @main-font;
          font-size: 19px;
          font-weight: 300;
          line-height: 1.62;

          @media @sm {
            font-size: 16px;
          }

          & a {
            text-decoration: underline;
            text-decoration-color: @link-underline;
      
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              text-decoration: none;
              border-bottom: 2px solid @link-underline;
              &:hover {
                text-decoration: none !important;
                border-bottom: 2px solid @link-underline-hover;
              }
            }
            &:hover {
              text-decoration: underline;
              text-decoration-color: @link-underline-hover;
            }
          }
        }
        &:last-child {
          border: none;
        }
      }
      &:nth-child(odd) {
        background-color: @tbody-odd;
      }
    }
    & th {
      & p {
        @media @sm {
          font-size: 18px;
        }
      }
    }
  }
}
@media @sm {
  .sol-table-decoration, .sol-table-decoration--wide {
    & tbody {
      & tr {
        & th.sv-responsiveTable--stacked__headRow.sv-responsiveTable--stacked__headRow--main {
          background-color: #204560;
          border-bottom: 1px solid white;
          padding: 10px 12px 8px 12px;

          & p {
            color: white;
            font-weight: inherit;
          }
        }
        & th.sv-responsiveTable--stacked__headRow {
          background-color: rgba(110, 205, 233, 0.4);
          padding: 10px 12px 8px 12px;

          & p {
            color: black;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
.sv-text-portlet table.sol-table-decoration--wide {
  display: block;
  overflow: auto;
  box-shadow: none;

  & tbody {
    border: 1px solid rgba(110, 205, 233, 0.4);
  }
}
