.sol-pinboard-creator__registration-form {
  .input-group--text-field label {
    font-size: 16px !important;
  }
  select {
    -webkit-appearance: menulist;
  }
  .input-group--text-field label {
    top: 25px;
    left: 50px !important;
    font-size: 12px;
  }
  .menu__activator--active {
      label {
        top: 18px;
      }
  }
  .sol-form;
  .sol-event-form;
  label {
    font-size: 16px;
    font-weight: 400;
  }
  .sol-notice__dates {
    .application--wrap {
      background-color: #fff;
      padding: 0;
    }
  }
  .sol-files-list {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .sol-remove-file-btn {
    background-color: #bee9f5;
    padding: 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
}