.sol-page-listing-el {
    @item-bg-color:rgba(110, 205, 233, 0.1);
    @dark-slate-blue:#204560;
    letter-spacing: 0.01em;

    * {
        font-weight:300;
    }

    &__title {
        padding-left:17px;
        padding-right:20px;
        padding-top:5px;
        padding-bottom: 5px;
        margin:0;
        background-color: @dark-slate-blue;
        color:white;
        font-size:18px;
        border-radius:3px 3px 0px 0px;
        line-height: 1.5;
        padding-right: 31px;
        padding-left: 29px;
        padding-top: 7px;
        padding-bottom: 4px;
        @media (min-width: @md-min) {
            display:inline-block;
        }
    }

    a {
        text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }

    ul {
        list-style: none;
        padding:0;
        border:1px solid #F9F9F9;
        margin:0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08)
    }

    &-item {
        padding-left:17px;
        padding-top:11px;
        padding-bottom:14px;
        @media (min-width: @md-min) {
            padding-left:28px;
            padding-top:21px;
            padding-bottom:24px;
        }

        &:nth-child(even) {
            background-color: @item-bg-color;
        }

        &__title {
            font-size:19px;
            margin:0;
            margin-bottom: 5px;
            a {
                font-weight: 400;
                color:@dark-slate-blue;
            }
            @media (min-width: @lg-min) {
                font-size:21px;
            }
        }
        &__desc{
            font-size:19px;
            line-height: 1.3em;
            font-family: PFDINTextStd-Light, sans-serif;
        }
    }

}
