.sol-portal--blue {
  @theme-color: #0080a9;
  @light-color: tint(@theme-color, 80%);

  @menu-btn__bg: rgba(110, 205, 233, 0.25);
  @menu-btn__bg--hover: rgba(110, 205, 233, 0.15);
  @menu-btn__bg--active: #6ecde9;

  .sol-icon__reserve {
    .icon-size(87px, 100px);
    margin-right: 30px;
    &::after {
      background-image: url('@{sol-icons-path}/boka-lokal-bla.svg');
    }
   }

   .sol-icon__subscribe {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/foreningsnytt-bla.svg');
    }
   }

   .sol-icon__cloud {
    .icon-size(87px, 100px);
    margin-right: 23px;
    &::after {
      background-image: url('@{sol-icons-path}/tankar-bla.svg');
    }
   }
   

  .sol-top-logo {
    text-decoration: none;
    position: absolute;
    top: 22px;
    > span {
      display: block;
      font-size: 36px;
      text-align: left;
      color: @theme-color;
      font-weight: 600;
    }
  }

  .sol-top-search__form input[type="search"] {
    border-color: #204560;
  }
  


  .sol-portal-btn--blue() {
    @portal-btn-bg: #0080a9;
    @portal-btn-bg--hover: #006683;
    @portal-btn-bg--active: #0080a9  !important;
    @portal-btn-border--active: #006683 !important;
    background-color: @portal-btn-bg !important;
    color: @white !important;
    &:hover {
      background-color: @portal-btn-bg--hover !important;
    }
    &:active {
      background-color: @portal-btn-bg--active !important;
      border: 2px solid @portal-btn-border--active;
    }
  }

  header {
    .sol-top {
      padding: 0 !important;
      height: 120px;
      @media @md {
        height:  46px;
        background-color: rgba(110, 205, 233, 0.25);
      }

      .sol-back-to-main-site-btn {
        .button;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -1px;
        min-height: 30px !important;
        padding-top: 0;
        padding-bottom: 0;
        background-color: @menu-btn__bg;
        &:hover {
          background-color: @menu-btn__bg--hover;
        }
        &:active {
          background-color: @menu-btn__bg--active;
        }
        @media @md {
          border: solid 1px rgba(110, 205, 233, 0.6);
        }
        &__wrapper {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 26px;
          @media @md {
            display: none;
          }
        }
      }
    }
    .sol-bottom {
      background-color: @theme-color;
      .sol-top-nav__item {
        font-size: 19px;
      }
    }

    .sol-mobile-header {
      > div {
        > a {
          > span {
            @media @md {
              color: #0080a9;
            }
          }
        }
      }
      .sol-hamburger {
        > span {
          @media @md {
            background-color: @theme-color;
          }
        }
      }
    }

  }
  .sol-landing-page__highlighted-news {
    &__header {
      background-color: @theme-color !important;
    }
  }
  .sol-landing-page-wrapper {
    .sol-page-listing-item:nth-child(odd) {
      background-color: rgba(110, 205, 233, 0.15) !important;
    }
    .sol-page-listing-child {
      &__link {
        font-size: 19px;
        .sol-portal-btn--blue !important;
        &:active {
          border: none !important;
        }
      }
    }

    .sol-landing-page {

      &__top {
        .font-heading-1 {
          color: @theme-color;
        }
      }

    }
  }
  .sol-content-n-popular-pages-wrapper {
    background-color: @light-color;
    padding-top: 23px;
    padding-bottom: 28px;
    .sv-font-startsida1f {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      line-height: 1.21;
      text-align: left;
      @media @md {
        font-size: 19px;
      }
    }
    .sol-popular-pages {
      background-color: @light-color;
      width: 85%;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      &__link {
        .sol-portal-btn--blue;
        font-size: 19px;
      }
    }
  }
  .sol-puff {
    &__content__title {
      color: @theme-color;
    }
  }
  .sol-widget-decoration .font-widget-heading,
  .sol-widget-decoration .font-widget-heading a {
    color: @theme-color;
  }
  .sol-video-widget .sol-widget-decoration-img:before {
    background-image: url('@{sol-icons-path}/video-ikon-bla.svg');
  }
  .sol-startpage-news__right .sol-news-item__content {
    border-bottom-color: fadeout(@theme-color, 60%);
  }
  .sol-page-tools__item button.sol-contact__button {
    &:hover {
      background-color: darken(#0080a9, 10%) !important;
      color: @white;
    }
    &[aria-expanded="true"] {
      background-color: #0080a9;
      color: @white;
    }
  }

  .sol-content-wrapper .sol-contact {
    @border: rgba(110, 205, 233, 0.8);
    @border-thick: rgba(110, 205, 233, 0.25);
    @background: rgba(110, 205, 233, 0.25);
    @header: #0080a9;
    border-color: @border;
    &:before {
      border-bottom-color: @border;
    }
    &__header {
      border-bottom-color: @border-thick;
      h2 {
        color: @header;
      }
    }
    &__item__left.sv-column-5.sol-col-md-5 {
      h4.font-heading-2 {
        color: @black !important;
      }
    }
    &__item__right.sv-column-7.sol-col-md-7 {
      background-color: @background;
    }
    &__political-responsibility {
      border-top-color: @border;
    }
  }
}
