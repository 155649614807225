
.sol-icon {
  &__tipsa {
    margin-right: 12px;
    max-height: 27.6px;
    &::after {
      height: 27.6px;
      width: 29.5px;
    }
  }
}
.sol-event-page {
  .font-heading-1 {
    &::after {
      display: none;
    }
  }
  &__create-event-btn {
    margin-top: 25px;
    max-width: 570px;
    border-radius: 100px;
    webkit-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: #c1e4c7;
    padding: 8px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 50px;
    text-align: left;
    position: relative;
    cursor: pointer;
    line-height: normal;
    overflow: visible;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    font-size: 20px;
    line-height: 1.8;
    text-decoration: none;
    &__icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 4px;
      top: 50%;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}