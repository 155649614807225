/* events/event-registration.less */
.sol-event-form-n-more-info-wrapper {
  @form-bg: @white;
  padding: 22px 30px;
  background-color: @form-bg;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  @media @sm {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  .sol-collapsible {
    max-width: none;
  }
}
.sol-event-form {
  @form-bg: @white;
  @error-color: @form-red-1;
  margin-top: 30px;
  .sol-event-form__buttons {
    @media @sm {
      padding-top: 0;
      padding-bottom: 0;
    }
    > button[type="submit"] {
      @media @sm {
        width: 100%;
      }
    }
   
  }
  .sol-remove-time {
    .sol-btn;
    height: 40px;
    margin-left: 1%;
    @media @sm {
      margin-bottom: 15px;
    }
    i.fa {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .sol-datetime-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &:not(:first-child) {
      > div {
        width: 47%;
        @media @sm {
          width: 100%;
        }
        &:first-child {
          margin-right: 2%;
        }
      }
    }
    > div {
      width: 47%;
      @media @sm {
        flex-wrap: wrap;
        width: 100%;
      }

    }
  }
  &__categories {
      > div {
        display: inline-block;
        &:first-child {
          margin-right: 45px;
        }
      }
  }
  &__content {
    position: relative;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      @media @md {
        font-size: 19px;
      }
    }
    & .sol-row > div {
      margin-bottom: 20px;
    } 
    & .sol-form-row > div {
      margin-bottom: 20px;
    }
    &--loading::after{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &__divider {
    padding-bottom: 25px;
    @media @sm {
      padding-bottom: 0;
    }
  }
  &__buttons {
    .clearfix;
    padding-top: 18px;
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-start;
  }
  &__error-msg {
    display: block;
    color: @error-color;
    margin-bottom: 15px;
    font-weight: 500;
    margin-top: 5px;
    &:after {
      content: '';
      height: 19px;
      width: 19px;
      display: inline-block;
      background-image: url('@{sol-icons-path}/forms/times-invalid.svg');
      background-size: cover;
      margin-left: 2px;
      position: relative;
      top: 4px;
      margin-top: -4px;
    }
  }
  & fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  & label,
  & legend {
    .input-label;
  }
 

  & input[type="text"],
  & input[type="tel"],
  & input[type="email"],
  & input[type="url"] {
    .input-text;
    &.ng-invalid.ng-touched,
    .ng-submitted&.ng-invalid {
      .input-text-invalid;
    }
  }
  & textarea {
    .input-textarea;
    &.ng-invalid.ng-touched,
    .ng-submitted&.ng-invalid {
      .input-text-invalid;
    }
  }
  & input[type="checkbox"] {
    .input-checkbox;
  }
  & input[type="radio"] {
    .input-radio;
    &:checked {
      display: none;
      & + label {
        &::before {
          background-color: @downriver;
          border-color: @downriver;
        }
      }
    }
  }
  & .sol-event-form__file {
    .input-file;
    &__btn {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      margin-left: 0!important;
      min-height: 35px;
      font-weight: 300;
      @media @md {
        min-width: 110px;
        min-height: 50px;
      }
    }
    &__text {
      float: none !important;
    }
    & + label{
      margin-bottom: 0;
    }
  }
  & button[type="submit"] {
    .input-btn;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    @media @md-strict {
      min-width: 140px;
      min-height: 50px;
    }
  }
  .sol-add-time {
    .sol-btn;
    margin-top: 17px;
    margin-bottom: 37px;
    min-height: 35px !important;
    @media @md {  
      min-height: 50px !important;
    }
    @media @sm {
      width: 100%;
    }
  }
  .sol-loading{
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -50px;
    z-index: 1;
    & svg{
      width: 100px;
      height: 100px;
      & path{
        fill: @color-1;
      }
    }
  }
}