/* events/event-info.less */
.sol-event-info {
  .sol-simple-box-decoration {
    margin-top: 0;
  }
  &-field {
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;
    .sol-other-dates {
      margin-left: 5px;
    }
    .sol-date {
      &[date-has-passed="true"] {
        color: red;
      }
    }
    > a {
      color: rgba(110, 205, 233, 0.59);
      > span {
        color: #0e3c5d;
      }
      &[href^=tel] {
        color: #313131 !important;
        text-decoration: none !important;
      }
      &:hover {
        color: #6ecde9;
      }

    }
    & > span:first-child {
      margin-right: 2px;
      font-size: 17px;
      font-weight: 700;
    }
  }
}
 
.sol-event-info-small {
  display: flex;
  @icon-color: @color-1;
  @border: #d8d8d8;
  .sol-clearfix;
  margin-top: 10px;
  margin-bottom: 32px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: rgba(110, 205, 233, 0.45);
  }
  &-item { 
    margin-right: 15px;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    color: #333333;
    border-right: 1px solid @border;
    &:last-child {
      border-right: none;
    }
    &.date {
      &:first-letter {
        text-transform: capitalize;
      }
    }
    padding-right: 15px;
    & i {
      margin-right: 6px;
      color: @icon-color;
    }
    @media @sm {
      font-size: 14px;
      margin-right: 10px;
      padding-right: 10px;
    }
    @media @xs {
      margin-right: 5px;
      padding-right: 5px;
    }
  }
}
