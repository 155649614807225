@open-sans-path: '@{sol-font-path}/open-sans';
@roboto-path: '@{sol-font-path}/roboto';

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Light.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Light.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-LightItalic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-LightItalic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Regular.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Regular.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Italic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Italic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Semibold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Semibold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Bold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Bold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-BoldItalic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-BoldItalic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-ExtraBold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-ExtraBold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('@{roboto-path}/Roboto-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('@{roboto-path}/Roboto-BlackItalic.eot'), /* IE9 Compat Modes */
  url('@{roboto-path}/Roboto-BlackItalic.woff') format('woff'), /* Modern Browsers */
  url('@{roboto-path}/Roboto-BlackItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('@{roboto-path}/Roboto-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('@{roboto-path}/Roboto-MediumItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}