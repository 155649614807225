.sol-table-of-content {
  &-wrapper {
    display: none;
    margin-bottom: 30px;
    background-color: rgba(110, 205, 233, 0.1);
    border-radius: 3px;
    width: 462px;
    padding: 18px 25px 30px 42px;

    @media @md-strict {
      margin-bottom: 42px;
    }
    @media @xs {
      width: 100%;
    }
      > .sv-text-portlet {
        > .sv-text-portlet-content {
          > p {
            font-size: 17px!important;
            font-weight: bold;
            line-height: 1.28;
            letter-spacing: 0.04em;
            text-align: left;
            color: #204560;
            margin-bottom: 15px;
            text-transform: uppercase;
            position: relative;
            left: -18px;
            font-weight: 600;
            font-family: PFDINTextStd-Regular, sans-serif;
            margin-bottom: 12px!important;
          }
        }
      }
  }
}
.sv-toc-portlet  ul {
  > li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    > a {
      position: relative;
      font-size: 19px;
      font-weight: 300;
      line-height: 1;
      text-align: left;
      color: #204560;
      font-family: @light-font;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -5px;
        left: -20px;
        height: 29px;
        width: 13px;
        background-image: url('@{sol-icons-path}/arrow--blue.svg');
        background-size: 100% 100%;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: rgba(110, 205, 233, 0.6);
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        transition: all 0.2s ease-in-out 0s;
      }
      &:hover {
        &::after {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}
