.sol-article-filter {
  @collapsible-bg: @white;
  @collapsible-header-bg: mix(@color-2, @white, 45%);
  @collapsible-header-text: @black;
  @collapsible-icon: @black;
  @collapsible-icon-bg: @white;
  .sol-flex-row {
    display: flex;
    justify-content: flex-end;
  }
  &__reset-btn {
    .sol-btn;
    margin-bottom: 20px;
    font-size: 17px;
    @media @sm {
      margin-right: 15px;
    }
  }
  &__cnt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(110, 205, 233, 0.3);
    @media (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 31px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--year, &--month, &--category {
    flex-basis: 31%;
    max-width: 400px;
    position: relative;
    &__header {
      z-index: 12;
      .no-appearance;
      .focus-outline;
      position: relative;
      display: block;
      width: 100%;
      padding: 7px 52px 7px 35px;
      background-color: @collapsible-header-bg;
      border-radius: 25px;
      color: @collapsible-header-text;
      text-align: left;
      text-decoration: none;
      line-height: 36px;
      font-size: 20px;
      font-family: @main-font;
      margin: 0;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &[disabled="disabled"] {
        background-color: lighten(@collapsible-header-bg, 10%);
        cursor: not-allowed;
      }

      &__icon {
        background-color: @collapsible-icon-bg;
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 4px;
        top: 50%;
        margin-top: -20px;
        border-radius: 50%;
        &::after,
        &::before {
          content: '';
          display: block;
          background-color: @collapsible-icon;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 22px;
          height: 4px;
          margin-left: -11px;
          margin-top: -2px;
          border-radius: 2px;
          .transition(all);
        }
        &::after {
          .rotate(90deg);
        }
      }

      &__text {
        @media @sm {
          font-size: 16px;
          line-height: 1.25;
        }
      }
    }
    &__list {
      list-style: none;
      position: absolute;
      top: 44px;
      left: 5%;
      margin: 0;
      padding: 0;
      background-color: #fff;
      max-height: 0;
      overflow: hidden;
      transition: all .3s ease;
      border-radius: 3px;
      width: 90%;
      z-index: 2;
      background-color: @white;
      > li > button {
        .no-appearance;
        display: block;
        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 20px;
        transition: all .3s ease;
        &:hover {
          background-color: #bee9f5;
        }
      }
    }

    &.expanded {
      .sol-article-filter--year__header__icon::after, .sol-article-filter--month__header__icon::after, .sol-article-filter--category__header__icon::after{
        transform: rotate(0deg);
      }
      > ul {
        max-height: 1000px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  &--year {
    @media @sm {
      z-index: 9;
      margin-right: 0;
      margin-bottom: 25px;
    }
    &__list {
      z-index: 9;
    }
  }
  &--month {
    margin-right: 3.8%;
    margin-left: 3.8%;
    @media @sm {
      z-index: 8;
      margin: 0 0 25px;
    }
    &__list {
      z-index: 8;
    }
  }
  &--category {
    @media @sm {
      z-index: 7;
    }
    
  }
}

.sol-portal--blue, .sol-portal--rose, .sol-portal--green {
  .sol-article-filter--year, .sol-article-filter--month {
    flex-basis: 49%;
  }
  .sol-article-filter--category {
    display: none;
  }
}