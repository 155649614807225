.sol-startpage {
  @top-image-border: @white;
  @top-image-content-bg: @white;
  @xxl: ~'(min-width: 1900px)';
  &__top {
    position: relative;
    &__image {
      position: relative;
      height: 530px;
      background-position: 50% 50%;
      background-size: cover;
      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 40px;
        background-color: @top-image-border;
        z-index: 1;
        bottom: -25px;
        width: 340px;
        @media @xxl {
          width: 700px;
        }
      }
      &::before {
        content: '';
        left: -1px;
        transform: rotate(5deg) skewx(5deg);
        @media @xxl {
          transform: rotate(3deg) skewx(3deg);
        }
      }
      &::after {
        right: -1px;
        transform: rotate(-5deg) skewx(-5deg);
        @media @xxl {
          transform: rotate(-3deg) skewx(-3deg);
        }
      }
      @media @md {
        height: 368px;
      }
      @media @sm {
        display: none;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      border-radius: 3px;
      background-color: @top-image-content-bg;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      min-height: 215px;
      left: 0;
      right: 0;
      bottom: -40px;
      > .sv-script-portlet:nth-child(2) {
        flex: 1 1 auto;
        display: flex;
      }
      @media @md {
        margin-right: 0;
        margin-left: 0;
        height: auto;
        bottom: -130px;
        box-shadow: none;
      }

      @media @sm {
        position: relative;
        top: 0;
        bottom: auto;
      }
    }
  }
}
