/* elements/boxes.less */
.search-field() {
  @input-border: fadeout(@color-2, 55%);
  @input-bg: @white;
  @input-color: @color-1;
  @input-border-hover: @color-2;
  .input-text;
  border-color: @input-border;
  background-color: @input-bg;
  color: @input-color;
  padding: 2px 50px 0 28px;
  height: 44px;
  line-height: 44px;
  &:hover {
    border-color: @input-border-hover;
  }
  .placeholder({
    color: @input-color;
  });
}
.search-button() {
  .no-appearance;
  .focus-outline;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  cursor: pointer;
  & .sol-icon__search {
    .icon-size(23px, 22px);
    &.sol-icon-edge {
      padding-top: 9px;
    }
  }
}
