/* components/top-image.less */
.sol-subpage__top-image {
  @text-background: fadeout(@color-4, 10%);
  @text-background-hover: @color-4;
  @text-border: fadeout(@white, 55%);
  .sv-mfpimage-portlet {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    height: 415px;
    overflow: hidden;
    @media @sm {
      height: 200px;
    }
    img {
      max-height: unset !important;
      max-width: unset !important;
      min-height: 415px;
      position: absolute;
      margin: auto;
      top: -100%;
      bottom: -100%;
      @media @sm {
        min-height: 200px;
      }
    }
  }
  .sol-grid {
    position: relative;
  }
  .sol-top-image__text {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: 52px;
    width: 202px;
    height: 202px;
    background-color: @text-background;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 25px;
    line-height: 1.12;
    color: @white;
    padding: 50px 22px;
    z-index: 2;
    text-decoration: none;
    .rotate(5deg);
    transition: background-color 0.5s;
    &::after {
      content: '';
      border: solid 2px @text-border;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      @media @sm {
        top: 6px;
        right: 6px;
        bottom: 6px;
        left: 6px;
      }
    }
    @media @sm {
      width: 120px;
      height: 120px;
      bottom: 35px;
      padding: 23px 13px;
      //font-size: 16px;
    }
  }
  a.sol-top-image__text:hover {
    background-color: @text-background-hover;
  }
}
.sol-startpage__top__image {
  @text-background: fadeout(@color-1, 10%);
  @text-background-hover: @color-1;
  @text-border: fadeout(@white, 55%);
  .sol-grid {
    position: relative;
  }
  .sol-top-image__text {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 52px;
    width: 202px;
    height: 202px;
    background-color: @text-background;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 25px;
    line-height: 1.12;
    color: @white;
    padding: 50px 22px;
    z-index: 2;
    text-decoration: none;
    .rotate(5deg);
    transition: background-color 0.5s;
    &::after {
      content: '';
      border: solid 2px @text-border;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      @media @sm {
        top: 6px;
        right: 6px;
        bottom: 6px;
        left: 6px;
      }
    }
    @media @sm {
      width: 120px;
      height: 120px;
      bottom: 35px;
      padding: 23px 13px;
      //font-size: 16px;
    }
  }
}
