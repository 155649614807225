body[class*="sol-portal"] {
  .sol-widget-decoration-wrapper {
    .sv-decoration-content {
      @media @md-strict {
        display: flex;
        .sol-widget-decoration-img {
          width: 27%;
          height: auto;
          min-height: 140px;
        }
        > div:not(.sol-widget-decoration-img) {
          width: 73%;
        }
      }
    }
  }

  .sol-tool-nav .sol-tool-nav__item:nth-child(2) {
    display: none;
  }
  @startpage__bg: rgba(240, 236, 225, 0.3);

  .sol-startpage__top__image {
    &::after {
      background-color: #faf9f6;
    }
    &::before {
      background-color: #faf9f6;
    }
    @media @md-strict {
      bottom: -190px;
    }
    @media @md {
      width: 100%;
      position: static;
    }
    @media @sm {
      display: block;
      height: 135px;
      &::before,
      &::after {
        display: none;
      }
    }
  }
  //.sol-startsida__puff__wrapper {
  .sol-puff__container > div {
    flex: 1 0 32%;
    max-width: 32%;
    @media @md {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }
  @media @md-strict {
    margin-top: 230px;
  }
  @media @sm {
    margin-top: 40px;
  }
  //}

  header > .sol-top > .sv-grid-main {
    position: relative;
    padding-top: 56px;
    .sol-back-to-main-site-btn__wrapper {
      position: absolute;
      top: 0;
      right: 0;
      @media @xs {
        margin-left: 15px !important;
      }
      @media @xs {
        a.sol-back-to-main-site-btn {
          padding: 0 15px;
        }
      }
    }
    @media @md {
      padding-top: 0;
      .sol-back-to-main-site-btn__wrapper.sol-show-md--strict {
        position: unset;
        top: auto;
        right: auto;
        display: inline-block !important;
        float: left;
        margin-left: 25px;
      }
    }
  }
  .sol-mobile-header {
    @media @md {
      min-height: 92px;
      display: flex;
      align-items: center;
      padding: 0 19px;
    }
    > div {
      @media @md {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .sol-hamburger-text {
        @media @md {
          top: 0;
        }
      }
      > a {
        @media @md {
          display: flex;
          flex-direction: column;
          text-decoration: none;
          .sol-mobile-logo {
            @media @md {
              margin: 0 0 6px 0;
              max-width: 137px;
              float: none;
            }
          }
          > span {
            @media @md {
              text-decoration: none;
              font-size: 30px;
              text-align: left;
            }
            @media @sm {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .sol-tool-nav {
    @media @md {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 19px;
      .sol-back-to-main-site-btn {
        &__wrapper {
          margin: 0 0 0 30px;
        }
        font-size: 12px;
        text-align: center;
        color: #333333;
        border-radius: 3px;
        background-color: #ffffff;
      }
    }
  }

  .sol-tool-nav__item {
    > a,
    button {
      @media @md {
        color: #333333;
      }
      @media @xs {
        margin: 0 5px;
        padding: 0;
      }
    }
    .sol-icon {
      &__listen {
        .sol-icon__listen--black;
      }
      &__globe {
        @media @md {
          .sol-icon__globe--black;
        }
      }
      &__legible {
        @media @md {
          .sol-icon__legible--black;
        }
      }
      &__phone {
        @media @md {
          .sol-icon__phone--black;
        }
      }
      &__lock {
        @media @md {
          .sol-icon__lock--black;
        }
      }
    }
    @media @xs {
      margin-left: 3%;
    }
  }

  .sol-startpage-news__right {
    width: 100%;
  }
  .sol-top-logo {
    margin-left: -3px;
    & > img {
      height: 34px;
      margin-top: -5px;
      margin-bottom: 15px;
      margin-left: -7px;
    }
  }
  .sol-top-search {
    form {
      left: 525px;
      input {
        border-radius: 3px;
      }
    }
  }
  .sol-top-shortcut__item {
    @media @sm {
      min-height: 106px;
    }
    &__name {
      @media @sm {
        line-height: normal;
        position: relative;
      }
    }
    i.sol-icon {
      @media @md {
        .icon-size(38px);
        margin-right: 0;
      }
    }
  }
  .sol-popular-pages {
    @media @sm {
      width: 92% !important;
      padding-left: 0;
      padding-right: 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .sol-popular-pages__link {
    @media @sm {
      display: inline-block;
      width: auto;
    }
  }
  .sol-popular-pages__header {
    font-size: 21px;
  }
  .sol-startpage-news {
    @media @sm {
      margin-bottom: 30px;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
      padding-top: 60px;
    }
  }
  .sol-news-n-puffar-wrapper {
    display: flex;
    justify-content: space-between;
    @media @md {
      flex-wrap: wrap;
    }
    > div {
      flex: 0 1 47%;
      max-width: 47%;
      @media @md {
        flex: 0 1 100%;
        max-width: 100%;
      }
    }
    .sol-startpage-news__right {
      @media @md-strict {
        max-width: 94%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 8%;
        margin-bottom: 45px;
        .sol-news-archive-link {
          left: 0;
        }
      }
      @media @sm {
        padding-right: 25px;
        padding-left: 25px;
        .sol-news-item {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .sol-puff {
      &__link {
        @media @md-strict {
          height: auto;
          display: flex;
          flex-direction: column;
        }
      }
      &__img {
        @media @md-strict {
          min-height: 200px;
          max-height: 200px;
          flex: 1 1 100%;
          max-width: 100%;
        }
      }
      &__container {
        margin-top: 0;
        justify-content: space-between;
        @media @md-strict {
          flex-direction: row;
        }
        > div {
          flex: 1 1 48%;
          max-width: 48%;
          margin-right: 0;
          @media @md-strict {
            flex: 1 1 49.5%;
            max-width: 49.5%;
          }
          @media @sm {
            flex: 1 1 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .sol-startpage-news {
    .sol-ul {
      padding-left: 0;
    }
    &__left {
      margin-top: -20px;
      .sol-news-item {
        &__date {
          display: none;
        }
        &__content {
          background-color: @white;
        }
      }
    }
    &__right {
      margin-top: 0;
    }
  }
  .sol-startpage {
    background-color: @startpage__bg;
    .sol-news-item {
      &__header {
        > a {
          color: @downriver;
        }
      }
      &__desc {
        color: @black;
      }
    }
  }
  .sol-startpage__content__top {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sol-startpage__top__content {
    bottom: -140px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media @md-strict {
      bottom: -200px;
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.sol-back-to-main-site-btn {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    @media @md {
      display: none;
    }
  }
  &--green,
  &--blue,
  &--rosa {
    .button;
  }
}

.sol-content-n-popular-pages-wrapper {
  .sv-font-startsida1f {
    font-family: @main-font;
    font-weight: bold;
    line-height: 1.38;
    @media @sm {
      width: 92% !important;
    }
  }
}

.sol-content-wrapper .sol-contact .sol-contact__item__right {
  margin-top: -5px;
  padding-top: 25px;
}
