#ArtviseWebHelpTab {
  width: 120px !important;
  height: 120px !important;
  right: 42px !important;
  bottom: 65px !important;
  top: auto !important;

  @media @sm {
    width: 75px !important;
    height: 75px !important;
    right: 25px !important;
    bottom: 100px !important;
  }

  & #ArtviseStartWebHelp {
    height: 120px;
    width: 120px;
    background-image: none;
    display: block;
    line-height: normal;

    @media @sm {
      width: 75px;
      height: 75px;
      background-image: none;
    }
    &:after {
      content: "Chatt";
      display: block;
      font-family: PFDINTextStd-Regular, sans-serif;
      font-weight: 100;
      font-size: 19px;
      position: relative;
      left: 0px !important;
      top: 18px !important;

      @media @sm {
        font-size: 21px;
        top: -36px;
        left: -4px;
      }
    }
    &:before {
      content: "";
      background: url("/images/18.53b03efd15ecf6f80a3ec671/1553259475023/chat.svg");
      background-size: 36px 36px;
      display: block;
      margin: 0 auto;
      position: relative;
      width: 36px;
      height: 36px;
      left: 0px !important;
      top: 32px !important;

      @media @sm {
        font-size: 21px;
        top: 20px !important;
        left: -4px;
      }
    }
    & #ArtviseChatImage {
      display: none;
      position: relative;
      top: 32px;
      left: 3px;
      width: 35px;
      margin: 0 auto;
    }
  }
}

#ArtviseWebHelp a,
#ArtviseWebHelp a:link,
#ArtviseWebHelp a:visited {
  text-decoration: underline !important;
}
