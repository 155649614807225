.sol-startpage-events {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-bottom: 45px;
  &__header {
    background-color: @fringy-flower;
    padding: 23px 38px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    &__text {
      font-size: 19px;
      text-align: left;
      color: #333333;
      margin-top: 5px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .sol-icon__tickets::after {
      background-image: url('@{sol-icons-path}/tickets--black.svg');
      width: 55px;
      height: 44.4px;
    }
  }
  &-list-wrapper {
    width: 100%;
    padding-left: 33px;
    padding-right: 18px;
    position: relative;
    .sol-events-archive-link {
      font-family: @light-font;
      display: inline-block;
      width: auto;
      text-align: right;
      text-decoration: none;
      position: absolute;
      bottom: 4px;
      right: 19px;
      span {
        z-index: 1;
        position: relative;
        font-size: 19px;
        font-weight: 300;
        line-height: 1;
        text-align: left;
        color: #204560;
        .link-underline;
        &:hover {
          &::after {
            border-bottom-color: #6ecde9;
          }
        }
      }
    }
  }
  .sol-ul {
    display: flex;
    align-items: flex-start;
    .sol-event-item {
      display: flex;
      flex-basis: 33%;
      margin-top: 17px;
      &:first-child {
        margin-left: 0;
      }
      &__date {
        background-color: @fringy-flower;
        font-family: @main-font;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 18px;
        &__day {
          font-size: 21.6px;
          line-height: 1;
          margin-bottom: 3px;
          font-weight: bold;
        }
        &__month {
          font-size: 12px;
          line-height: 0.93;
          text-transform: uppercase;
        }
      }
      &__header {
        flex-basis: 78%;
        margin: 0;
        font-size: 18px;
        line-height: 1.17;
        text-align: left;
        .sol-event-item__date__weekday {
          font-family: @light-font;
          font-size: 13px;
          font-weight: 300;
          display: block;
          line-height: 1.08;
          text-align: left;
          color: #333333;
          text-transform: capitalize;
        }
        > a {
          position: relative;
          text-decoration: none;
          color: #204560;
          &:active {
            color: #333333;
            text-decoration: underline;
          }
        }
      }
      &:hover .sol-event-item__header a {
        text-decoration: underline;
      }
    }
  }
  @media @md {
    &__header {
      .sol-icon__tickets {
        position: relative;
        top: 50%;
        margin-top: -45px;
      }
      &__text {
        position: relative;
        top: 50%;
        margin-top: 5px;
      }
    }
    &-list-wrapper {
      .sol-ul {
        display: block;
        .sol-event-item {
          margin-left: 0;
          margin-bottom: 30px;
          &__header {
            font-size: 16px;
            line-height: 1.19;
          }
        }
      }
      .sol-events-archive-link span {
        font-size: 16px;
      }
    }
  }
  @media @sm {
    display: block;
    padding-top: 5px;
    padding-left: 5px;
    &__header {
      display: block;
      padding: 5px 13px;
      margin-right: 5px;
      .sol-icon__tickets {
        position: relative;
        top: 4px;
        margin-top: 0;
        &::after {
          .icon-size(33px, 40px);
        }
      }
      &__text {
        display: inline-block;
        font-size: 17px;
        position: relative;
        top: -7px;
        margin-top: 0;
      }
    }
    &-list-wrapper {
      padding-right: 15px;
      padding-left: 15px;
      .sol-ul {
        .sol-event-item {
          margin-bottom: 20px;
          &__date {
            width: 45px;
            height: 45px;
            &__day {
              font-size: 18px;
              line-height: 0.85;
            }
            &__month {
              font-size: 11px;
              line-height: 1.02;
            }
          }
        }
      }
      .sol-events-archive-link {
        bottom: -22px;
      }
    }
  }
}
