.sol-mobile-header {
  @header-menu-bg: @color-1;
  @header-top-bg: @white;
  display: none;
  text-align: center;
  background-color: @header-top-bg;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  & > div {
    position: relative;
    max-width: 991px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
  }
  @media @md {
    display: block;
  }
  /*@media @md-strict {
    background-color: @header-menu-bg;
    .sol-mobile-logo {
      display: none;
    }
  }*/
  .sol-mobile-logo {
    float: left;
    margin-left: 10px;
    margin-top: 11px;
    height: 35px;
    @media @sm {
      height: 30px;
    }
  }
  .sol-mobile-search-button {
    width: 55px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 60px;
    background-color: @white;
    border: 0;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      flex-wrap: wrap;
    }
    cursor: pointer;
    i.sol-icon {
      .icon-size(25px);
    }
    &__text {
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      color: @color-1;
      font-family: @main-font;
    }
    .sol-mobile-search-close {
      & > span {
        display: none;
        width: 35px;
        height: 3px;
        border-radius: 2px;
        background-color: @color-1;
        transition: transform 500ms;
      }
    }
    &[aria-expanded="true"] {
      i.sol-icon:after {
        background-image: none;
      }
      .sol-mobile-search-close__top {
        display: block;
        transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
        width: 30px;
        position: relative;
        top: -24px;
        left: 5px;
      }
      .sol-mobile-search-close__bottom {
        display: block;
        transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
        width: 30px;
        position: relative;
        top: -9px;
        left: 5px;
      }
      .sol-mobile-search-button__text {
        margin-top: -3px;
      }
    }
  }
  .sol-hamburger-wrapper {
    .focus-outline;
    width: 55px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: @white;
    border: 0;
    text-align: center;
    padding-top: 32px;
    cursor: pointer;
    & .sol-hamburger {
      width: 25px;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -12px;
      & > span {
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 2px;
        background-color: @color-1;
        transition: transform 500ms;
      }
      & .sol-hamburger-middle {
        margin-top: 6px;
        margin-bottom: 6px;
        transition: width 500ms;
      }
      &.sol-active {
        left: 57%;
        .sol-hamburger-top {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(4.5px) translateY(7.5px) translateZ(0px);
          width: 26px;
        }
        .sol-hamburger-middle {
          width: 0;
        }
        .sol-hamburger-bottom {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(3.5px) translateY(-7.5px) translateZ(0px);
          width: 26px;
        }
      }
      @media @sm {
        right: 10px;
      }
    }
    & .sol-hamburger-text {
      color: @color-1;
      position: relative;
      top: -3px;
      font-size: 11px;
      font-family: @main-font;
    }
  }
}
