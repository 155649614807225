@quote-text:										@color-1;
@general-text: 									@black;
@list-icon: 										@color-2;
@list-number: 									@color-2;

body {
  font-family: @main-font;
  color: @general-text;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  background-color: @white;
  overflow-x: hidden;
  letter-spacing: 0.01em;
}
.font-description,
.font-normal,
p,
p.font-description,
p.font-normal,
p.sv-font-lank-till-profilsida {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: @light-font;
  color: @general-text;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 300;
  @media @md-strict {
    margin-bottom: 32px;
  }
  @media @sm {
    font-size: 15px;
  }
}

p.font-normal, p.sv-font-lank-till-profilsida {
  a {
    .link-style;
    padding: 2px;
  }
  @media @md-strict {
    margin-bottom: 42px;
  }
  @media @sm {
    font-size: 18px;
    margin-bottom: 18px;
  }
}

ul.sv-font-lank-till-profilsida {
  & li {
    a {
      margin-top: 0;
      margin-bottom: 20px;
      font-family: PFDINTextStd-Light, sans-serif;
      color: #313131;
      font-size: 20px;
      line-height: 1.4;
      font-weight: 300;
      border-bottom: 1px solid rgba(110, 205, 233, 0.6);
      text-decoration: none;
      background-size: 200% 200%;
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, rgba(110, 205, 233, 0.6)), color-stop(50%, transparent));
      background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 50%, transparent 50%);
      -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
      transition: background-position 300ms, color 300ms ease, border-color 300ms ease;

      &:hover {
        background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 51%, transparent 50%);
        background-position: 0 100%;
        transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
      }
      i {
        display: none;
      }
    }
  }
}

p.sv-font-lank-till-profilsida {
  a {
    i {
      display: none;
    }
  }
}
ul.font-normal {
  li{
    a{
      .link-style;
    //padding: 2px;
    }
  }
  @media @sm {
    font-size: 18px;
    margin-bottom: 18px;
  }
}

p.font-description {
  font-size: 23px;
  line-height: 1.4;
  @media @sm {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 27px;
  }
  a,
  a & {
    color: @link-underline;
    &:hover {
      color: @link-underline;
    }
  }
}
h1.font-heading-1 {
  font-family: @main-font;
  color: @color-1;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 13px;
  font-size: 36px;
  line-height: 1;
  font-weight: normal;
  text-align: left;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: fadeout(@color-2, 55%);
  }
  @media @md {
    font-size: 33px;
  }
  @media @sm {
    font-size: 25px;
    margin-bottom: 14px;
  }
}
.font-heading-1-smaller,
h1.font-heading-1-smaller {
  font-family: @main-font;
  color: @color-1;
  display: block;
  margin-bottom: 15px;
  font-size: 33px;
  line-height: 1.15;
  font-weight: normal;
  position: relative;
}
.font-heading-2,
h2,
h2.font-heading-2 {
  font-family: @main-font;
  color: @color-1;
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 1.38;
  @media @md-strict {
    font-size: 24px;
  }
  @media @sm {
    margin-top: 22px;
    font-size: 20px;
  }
}
.font-heading-3,
h3,
h3.font-heading-3 {
  font-family: @main-font;
  color: @color-1;
  margin-top: 21px;
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 1.5;
  @media @xs {
    font-size: 18px;
  }
}
.font-heading-4,
h4,
h4.font-heading-4 {
  font-family: @main-font;
  color: @color-1;
  margin-top: 19px;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 1.65;
  @media @xs {
    font-size: 16px;
  }
}
.font-quote,
p.font-quote {
  position: relative;
  font-family: @main-font;
  color: @quote-text;
  font-size: 22px;
  line-height: 29px;
  font-weight: 100;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  @media @md {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }
  @media @sm {
    font-size: 18px;
    line-height: 25px;
  }
  @media @xs {
    padding-left: 30px;
  }
  &::before {
    .icon(@fa-var-quote-right, 20px);
    position: absolute;
    left: 20px;
    top: 0;
    opacity: 0.3;
    line-height: 35px;
    @media @md {
      left: 5px;
      line-height: 33px;
    }
    @media @xs {
      left: 5px;
      line-height: 31px;
    }
  }
}
