/* elements/buttons.less */
.button() {
  @button-text: @black;
  @button-bg: @btn-bg;
  @button-bg-hover: fadeout(@btn-bg, 70%);
  @button-text-hover: @white;
  @button-bg-active: @btn-bg-active;
  .focus-outline;
  position: relative;
  display: inline-block;
  line-height: 22px;
  min-height: 40px;
  padding: 8px 25px;
  color: @button-text;
  border-radius: 3px;
  background-color: @button-bg;
  border-radius: 3px;
  .transition(~'background-color, color');
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-family: @main-font;
  border: 0;
  &:hover {
    background-color: @button-bg-hover;
  }
  &:active {
    background-color: @button-bg-active;
  }
  & i.fa {
    margin-right: 10px;
    margin-left: -5px;
    font-weight: 400;
  }
}
.button-small() {
  @button-small-bg: @btn-bg;
  @button-small-text: @black;
  @button-small-bg-hover: fadeout(@btn-bg, 70%);
  @button-small-text-hover: @white;
  cursor: pointer;
  min-height: 35px;
  padding: 7px 25px 8px;
  color: @button-small-text;
  background-color: @button-small-bg;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
  border: 0;
  border-radius: 3px;
  &:hover {
    background-color: @button-small-bg-hover;
    color: @button-small-text-hover;
  }
  &:active {
    top: 0;
  }
}

.btn-focus() {
  &,
  [data-whatinput='keyboard'] {
    &:focus {
      //background-color: @gray-lighter;
      color: @black;
    }
  }
  [data-whatinput='initial'] &,
  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    &:focus {
      //color: @button-hover-color;
      //background-color: @button-hover-bg;
    }
  }
}

.btn() {
  .no-appearance;
  //.outline-focus;
  .btn-focus;
  display: inline-block;
  border-radius: 5px;
  //color: @button-color;
  //border: solid 2px @button-border;
  //background-color: @button-bg;
  font-family: @text-font;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  &:hover {
    //color: @button-hover-color;
    //background-color: @button-hover-bg;
  }
  & i.fa {
    font-size: 16px;
    //color: @button-color;
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
}
.btn-smaller() {
  .btn;
  min-width: 0;
  padding: 6px 16px;
  min-width: 0;
  font-size: 13px;
  font-weight: 500;
  min-height: 33px;
  line-height: 33px;
  padding: 0 15px;
}
.btn-mini() {
  .no-appearance;
  .btn-focus;
  display: inline-block;
  //background-color: @button-bg;
  //color: @button-color;
  font-family: @text-font;
  font-size: 12px !important;
  font-weight: 600;
  padding: 0 10px 0;
  text-shadow: none;
  transition: none;
  min-height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: none;
  & i.fa {
    margin-right: 5px;
  }
  &:hover {
    //color: @button-hover-color;
    //background-color: @button-hover-bg;
  }
  &.disabled,
  &[disabled] {
    //background-color: @button-bg;
    opacity: 0.6;
  }
}

