/** file: gismo.less **/
.gismoMap {
    position:relative;
}

.sol-gismo-map {
  .gismoMap {
    height:500px;
  }
}

.sol-map-builder {
  font-size:15px;
  &__step {
    font-weight: bold;
  }
  .sol-row {
    padding:20px;
  }

  input {
    border-radius: 4px;
    display:inline;
    font-size:15px;
    width:100%;
  }

  select {
    width:100%;
  }
}
​
.sol-filtered-select {
  &__filter {
    padding-bottom: 5px;
  }
}
