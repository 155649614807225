#triggerChat {
  font-size: 16px;
  font-family: PFDINTextStd-Regular, sans-serif;
  opacity: 0;
  position: fixed;
  bottom: 190px;
  right: 30px;
  padding: 20px 10px;
  background: #c8496b;
  color: white;
  border: none;

  &:focus {
    opacity: 1;
    display: block;
    outline: 2px solid #c1e4c7;
    z-index: 10;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 80%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #c8496b;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
}
