.sol-form {
  select {
    border-radius: 2px;
    border: solid 1px rgba(110, 205, 233, 0.4);
    padding: 10px 15px ;
    width: 100%;
  }
  & [class$="__error-message"] {
    color: red;
    font-weight: 400;
  }
}