header {
  @header-menu-bg: @color-1;
  @header-top-bg: @white;
  @header-top-mobile-bg: @color-1;
  @header-top-text: @color-2;
  @header-top-mobile-text: @color-2;
  position: relative;
  z-index: 50;
  background-color: @header-top-bg;
  .sol-top {
    padding: 18px 28px 17px 28px;
    background-color: @header-top-bg;
    @media @md {
      text-align: center;
      background-color: @header-top-mobile-bg;
      //padding-top: 5px;
      //padding-bottom: 5px;
      padding: 5px 7px 5px 7px;
    }
    /*@media @md {
      padding-left: 7px;
      padding-right: 7px;
    }*/
    /*
    @media (min-width: 1025px) {
      margin: 0 1.5rem;
    }
    */
    .sol-top-logo {
      & > img {
        height: 50px;
        margin-left: -16px;
      }
      @media @lg {
        margin-left: 0;
      }
      @media @md {
        display: none;
      }
    }
  }
  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: center;
    @media @md {
      display: none;
    }
  }
}
