/* components/breadcrumbs.less */
.sol-breadcrumbs-wrapper {
  @breadcrumbs-text: @color-1;
  @breadcrumbs-divider: @black;
  @breadcrumbs-border: @color-2;
  padding-top: 13px;
  padding-bottom: 13px;
  
  padding-right: 25px;
  padding-left: 25px;
  /*@media (max-width: 1180px) {
    padding-right: 25px;
    padding-left: 25px;
  }*/
  .sol-breadcrumbs {
    .sol-clearfix;
    @media @sm {
      padding-left: 15px;
      padding-right: 15px;
    }
    & li {
      float: left;
      font-size: 15px;
      letter-spacing: 0.5px;
      line-height: 1.2;
      & > a {
        text-decoration: none;
        color: @breadcrumbs-text;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: fadeout(@breadcrumbs-border, 40%);
        }
        &:hover::after {
          background-color: @breadcrumbs-border;
        }
      }
      & > span {
        &.sol-divider {
          color: @breadcrumbs-divider;
          margin-right: 8px;
          margin-left: 8px;
          float: left;
        }
      }
    }
  }
  @media @sm {
    display: none;
  }
}
