/* special/error-page.less */
.sol-error-page {
  @error-bg: fadeout(@white, 10%);
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: @error-bg;
  text-align: center;

  &-text-big {
    font-size: 255px;
    line-height: 220px;
    font-weight: 700;
  }

  &-text-small {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 34px;
    line-height: 40px;
    font-weight: 300;
  }

  & .sol-btn {
    margin-top: 20px;

    &:first-child {
      margin-right: 10px;
    }
  }
}
