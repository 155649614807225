.underlined-text {
  text-decoration: none;
  position: relative;
  &::after {
    content: '';
    border-bottom: 2px solid rgba(110, 205, 233, 0.6);
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 3px;
    z-index: -1;
  }
  &:hover {
    .underlined-text::after {
      border-bottom-color: #6ecde9;
    }
  }
}
.sol-landing-page__top {
  padding-bottom: 33.5px;
  @media  @md-strict {
    padding: 0 2.9% 28px;
  }

  @media @sm {
    padding: 0 1.8% 24px 2.9%;
  }

  h1 {
    font-size: 36px;
    line-height: 1.1em;
    text-align: left;
    color: #204560;
    padding: 0;
    margin-bottom: 27px;
    &::after {
      display: none;
    }
    @media @md-strict {
      font-size: 29px;
      margin-bottom: 28px;
      padding: 0;
    }
    @media @sm {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  a {

    text-decoration: none;
    display: flex;
    align-items: center;
    .sol-text {
      font-family: @light-font;
      font-size: 19px;
      font-weight: 300;
      line-height: 1;
      text-align: left;
      color: #204560;
      @media @sm {
        font-size: 17px;
        font-weight: 300;
      }
      &:extend(.underlined-text);
      &::after {
        &:extend(.underlined-text::after);
        bottom: 0;
      }
      &:hover {
        &::after {
          border-bottom-color: #6ecde9;
        }
      }
    }
    .sol-icon {
      display: flex;
      margin-right: 6.3px;
      .icon-size(11px, 10.7px);
    }
  }
}
