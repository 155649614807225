.sol-article-wrapper {
  @date-bg: fadeout(@color-1, 20%);
  @date-color: @white;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  @media @md-strict {
    width: 79%;
  }
  @media @sm {
    width: 92%;
  }
  .sv-image-portlet {
    position: relative;
    overflow: hidden;
    .sol-article-date {
      position: absolute;
      left: -45px;
      top: -45px;
      width: 100px;
      height: 100px;
      padding-top: 47px;
      padding-left: 31px;
      border-radius: 100%;
      background-color: @date-bg;
      color: @date-color;
      text-align: center;
      @media @sm {
        left: -40px;
        top: -40px;
        width: 90px;
        height: 90px;
        padding-top: 40px;
      }
      &-day {
        display: block;
        font-size: 16px;
        line-height: 11px; 
        font-weight: 700;
        @media @sm {
          font-size: 14px;
          line-height: 7px;
        }
      }
      &-month {
        font-size: 12px;
        line-height: 22px;
        display: block;
      }
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}

.sv-archive-portlet {
  & h2.font-heading-2 {
    margin-top: 6px;

    & > a {
      & span {
        color: #204560;
      }
    }
    & > span {
      color: #204560;
    }
  }
}