﻿﻿/*
Skattekollen Stockholm - template css for resultspage.
*/
#skattekollenwidget {
	font-family: @main-font;
	line-height: 18 px; 
	color: #333333;
	display: block;
	height: 100%;
	max-width: 450px;
}

#skattekollenwidget .skattekollenholder {
	display: block;
	max-width: 450px;
}

#skattekollenwidget .skattekollenchecker{
	display: block;
	max-width: 450px;
}

#skattekollenwidget .disclaimer {
	margin-top: 30px;
	font-size: 0.9em;
	display: block;
}


#skattekollenwidget .outerContainer {
	background: transparent;
	font-size: 1em;
	background: transparent;
	width: 100%;
	height: 100%;
	max-width: 450px;
}

#skattekollenwidget .innerContainer {
	background: transparent;
	width: 100%;
	max-width: 450px;
	margin: 0;
	display: block;
}

/* Detailed fördelning title */
#skattekollenwidget .fordelning {
	margin-top: 30px; 
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: bold;
}

#skattekollenwidget .detailsList {
			width: 100%;
			background: #fff;
			display: block;
}

#skattekollenwidget .zummationcontainer {
	width: 450px;
	margin-top: 30px;
}


#skattekollenwidget .zummation {
	display: inline-block;
	width: 222px;
	min-height: 50px;
	margin-bottom: 15px;
}

#skattekollenwidget .zummation img {
	display: inline-block;
	width: 60px; 
	height: 50px;
}

#skattekollenwidget .zummationText {
	font-size: 15px;
	display: inline-block;
	float: right;
	width: 154px;
	margin-top: auto;
	margin-bottom: auto;
	font-weight: normal;
			padding:5px 0 0 2px;        
}

/* Explanation and  nr of swedish to infoboxes - main categories */
#skattekollenwidget .zummationText span {
	font-weight: normal;
	font-size: 15px;
}

#skattekollenwidget #diagramholder {
	width: 450px;
	height: 332px;
	background: transparent;
	position: relative;
}

/* Commun tax title (inside circle) */
#skattekollenwidget .center {
	display: inline-block;
			font-size:18px;
}

/* swedish kr and procent (sindei circle) */
#skattekollenwidget .amount,#skattekollenwidget  .percentage {
			font-size:24px;
}

#skattekollenwidget .spacer {
	width: 1px;
	height: 110px;
}

/* Salary in kr */
#skattekollenwidget .salary {
	font-size: 24px;
	font-family: @main-font;
			line-height:36px;
}


/* Resultpage: Your salary-text */  
#skattekollenwidget .changeDiv {
	display: block;
	width: 100%;
	margin-bottom: 30px;
	font-size: 1.1em;                  
}

/*  */
#skattekollenwidget .changeDiv img {
			float:left;
}

#skattekollenwidget .changeDiv a {
	text-decoration: none;
	color: #006ebf;
	font-size: 1em;
			padding-top:2px;
			display:block;
}

#skattekollenwidget .dLHeader {
	width: 100%;
	height: 46px;
	display: inline-block;
	color: #fff;
	background-color: #000;
	margin-top: 30px;
}

/* title on category-blocks */
#skattekollenwidget .dLHeader span {
	margin-left: 15px;
	margin-top: 10px;
	display: inline-block;
	font-size: 18px;
	font-family: @main-font;
}

#skattekollenwidget .headerImage {
	float: right;
	margin-right: 3px;
}

#skattekollenwidget .dLItem {
	width: 100%;
	min-height: 28px;
	display: inline-block;
	cursor: pointer;
}

#skattekollenwidget .dLTitle:hover {
	text-decoration: underline;
}

/* description/title for each subcategory in block */
#skattekollenwidget .dLTitle {
	margin-top: 5px;
	margin-left: 10px;
	display: inline-block;
	font-size: 16px;
	font-weight: normal;
}
/* ... and swedish kr */
#skattekollenwidget .dLAmount {
	margin-top: 5px;
	float: right;
	margin-right: 10px;
	display: inline-block;
	font-size: 15px;
}

#skattekollenwidget .dLDescription {
	display: none;
	margin-left: 35px;
	font-size: 0.95em;
	color: #333;
	width: 340px;
	padding-bottom: 15px;
}

/* detailed escription for each subcategory in block */
#skattekollenwidget .communalinfo {
	width: 150px;
	position: absolute;
	top: 120px;
	left: 145px;                /* Adjust so text fits into circle */
	z-index: 1000;
	text-align:center;
	font-size: 1em;
}


#skattekollenwidget .spacerbottom {
	width: 1px;
	height: 25px;
}

/* New color scheme */
#skattekollenwidget.newcss .dept-1, #skattekollenwidget.newcss #zumm-0 img  {
	background-color:#204560;
	margin-top: 0;
}
#skattekollenwidget.newcss .dept-2, #skattekollenwidget.newcss #zumm-1 img {
	background-color:#4E745B;
}
#skattekollenwidget.newcss .dept-3, #skattekollenwidget.newcss #zumm-2 img {
			background-color:#A04040;
}
#skattekollenwidget.newcss .dept-4, #skattekollenwidget.newcss #zumm-3 img {
	//background-color:red;    
	background-color:#767676;
}
#skattekollenwidget.newcss .dept-5, #skattekollenwidget.newcss #zumm-4 img {
	background-color:#BA9E60;
}
#skattekollenwidget.newcss .dept-6, #skattekollenwidget.newcss #zumm-5 img {
	background-color:#000000;  
}

/* Old color scheme */
#skattekollenwidget.oldcss .dept-1, #skattekollenwidget.oldcss #zumm-0 img  {
	background-color:#204560;
	margin-top: 0;
}
#skattekollenwidget.oldcss .dept-2, #skattekollenwidget.oldcss #zumm-1 img {
	background-color:#4E745B;
}
#skattekollenwidget.oldcss .dept-3, #skattekollenwidget.oldcss #zumm-2 img {
	background-color:#A04040;    
	//background-color:red;
}
#skattekollenwidget.oldcss .dept-4, #skattekollenwidget.oldcss #zumm-3 img {
	background-color:#767676;    
}
#skattekollenwidget.oldcss .dept-5, #skattekollenwidget.oldcss #zumm-4 img {
	background-color:#BA9E60;        
}
#skattekollenwidget.oldcss .dept-6, #skattekollenwidget.oldcss #zumm-5 img {
	background-color:#000000;  
}


#skattekollenwidget .odd {
	background: #eaeaea;
}
#skattekollenwidget .even {
	background: #c8c8c8;
}

/* Title */
#skattekollenwidget  h1 {                          
	font-size: 30px;
	font-weight: normal;
	line-height: 30px;
}
			

/* 
	FIRST PAGE css
*/
#skattekollenchecker {
	font-family: @main-font;
	font-size: 24px;
}
#skattekollenwidget .text {
	outline: none;
			border: 3px solid #2a4c65;      /* where does my money go? - button same colour  */
	width: 120px;
	background: #fff;
	display: inline-block;
	line-height: 36px;
			height:36px;
	font-weight: 500;
	padding-left: 10px;
			font-family: Verdana,Arial;
	font-size: 18px;                /* where does my money go?  */
	border-radius:10px;
}

#skattekollenwidget .inputLabel {   /* kr */
			display: inline-block;
	margin-left: 5px;
	font-size: 1em;
	color: #666666;
}

#skattekollenwidget .button {
			font-family:Verdana,Arial;
	margin-top: 20px;
	display: block;
	font-weight: normal;
	cursor: pointer;
	font-size: 14px;
	color: #fff;
	text-shadow: none;
	background: #2a4c65;    /* same colour as textlabel input */
	border: 0 none;
	margin-left: 0;
	
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 7px;
	padding-bottom: 8px;
	height: 36px;
	text-align: center;
}

/* How much do you earn? */
#skattekollenwidget  h2 {
			font-weight:bold;
			display:block;
}

#skattekollenwidget  h3 {
			font-weight:normal;
			display:block;
}

/* under picture */
#skattekollenwidget p {     
	margin-top: 10px;
	display: inline-block;
}

/* different right margins on icons - SPECIAL FOR THIS WIDGET*/
#skattekollenwidget  .dLHeader.dept-1 .headerImage {
			margin-right: 1px;
}
#skattekollenwidget  .dLHeader.dept-2 .headerImage {
			margin-right: 1px;
}
#skattekollenwidget  .dLHeader.dept-3 .headerImage {
			margin-right: 3px;
}
#skattekollenwidget .dLHeader.dept-4 .headerImage {
			margin-right: -2px;
}
#skattekollenwidget .dLHeader.dept-5 .headerImage {
			margin-right: 5px;
}
#skattekollenwidget .dLHeader.dept-6 .headerImage {
			margin-right: 0px;
}


@media only screen and  (max-width: 500px) {
	#skattekollenwidget .dLDescription {width:335px;}
	#skattekollenwidget {width:400px;min-width:375px;max-width:375px;}
	#skattekollenholder {width:360px;min-width:365px;}
	#skattekollenwidget .zummationcontainer {width:375px;}
	#skattekollenwidget .zummation {display:block;}
	#skattekollenwidget {min-width:375xp;max-width:375px;}
	#skattekollenwidget .outerContainer {max-width:375px;}
	#skattekollenwidget #diagramholder {width:375px;}
	#diagramholder svg {left:-50px !important;}
	#skattekollenwidget .communalinfo {left:100px;}
	#skattekollenwidget .dLTitle {width:305px;}
}
