.sol-startpage__content {
  @startpage-bottom-bg: @white;
  &__top {
    padding: 95px 0 35px;
  }
  &__bottom {
    padding: 0 auto 35px;
    background-color: @startpage-bottom-bg;
    > .sv-fluid-grid.sv-grid-main {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
  }
  @media @md {
    &__top {
      padding-top: 160px;
    }
    &__bottom {
      padding: 0 20px 35px;
    }
  }
  @media @sm {
    &__top {
      padding-top: 30px;
    }
    &__bottom {
      padding: 0 15px 35px;
    }
  }
}
