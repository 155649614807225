/** file:telia_chat.less */

cg-chat cg-chat-tab {
  top: 180px !important;
  background: #c8496b !important;
  transition: none !important;
}
cg-chat.cg-chat-tab-visible cg-chat-tab,
cg-chat.cg-chat-tab-hidden cg-chat-tab {
  border-radius: 50%;
  width: 115px !important;
  height: 115px !important;
  right: 15px !important;
  bottom: 55px !important;
  top: auto !important;
}
cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab,
cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab {
  font-family: PFDINTextStd-Regular, sans-serif !important;
  font-weight: 300 !important;
  transform: none !important;
  width: 115px !important;
  position: absolute;
  bottom: 15px !important;
}
cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab:before,
cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab:before {
  content: '';
  background-image: url('/webdav/files/System/resources/graphics/icons/chat.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  padding-right: 30px;
  position: absolute;
  top: -25px;
  left: 42px;
}
cg-chat cg-chat-offer {
  top: auto !important;
  bottom: 55px !important;
}
cg-chat cg-chat-offer:before {
  width: 0 !important;
}

@media (max-width: 991px) {
  cg-chat.cg-chat-tab-visible cg-chat-tab,
  cg-chat.cg-chat-tab-hidden cg-chat-tab {
    width: 102px !important;
    height: 102px !important;
    bottom: 75px !important;
  }
   
  cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab,
  cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab {
    width: 102px !important;
    bottom: 10px !important;
  }
   
  cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab:before,
  cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab:before {
    left: 36px !important;
  }
  cg-chat cg-chat-offer {
    top: 20px !important;
    bottom: auto !important;
  }
}
   
@media (max-width: 767px) {
  cg-chat.cg-chat-tab-visible cg-chat-tab,
  cg-chat.cg-chat-tab-hidden cg-chat-tab {
    width: 75px !important;
    height: 75px !important;
    bottom: 110px !important;
  }
  
  cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab,
  cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab {
    font-size: 19px !important;
    width: 75px !important;
    bottom: 13px !important;
  }
  cg-chat.cg-chat-tab-visible cg-chat-tab .cg-chat-tab:before,
  cg-chat.cg-chat-tab-hidden cg-chat-tab .cg-chat-tab:before {
    display: none !important;
  }
}