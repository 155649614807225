.sol-pagination__list {
  padding-left: 0;
  @media @md {
    padding-left: 17px;
    padding-right: 25px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media @sm {
    padding-left: 15px;
  }
  &-item {
    &--previous, &--next {
      @media @sm {
        margin-top: 25px;
      }
    }
    &--previous {
      @media @sm {
        order: 9;
        margin-right: auto;
      }
    }
    &--next {
      order: 10;
    }
  }
}