footer {
  @footer-bg: @color-1;
  @footer-text: @white;
  @footer-social-border: @color-2;
  @footer-social-text: @color-2;
  .sol-bottom {
    padding: 38px 0 55px;
    background-color: @footer-bg;
    .sv-grid-main.sv-fluid-grid {
      padding-left: 100px;
      padding-right: 100px;
    }
    .sol-footer-contact,
    .sol-footer-links {
      &__item {
        .transition(opacity);
        display: block;
        position: relative;
        margin-bottom: 16px;
        & a,
        & p,
        & h2 {
          display: block;
          text-decoration: none;
          color: @footer-text;
          font-size: 19px;
          font-weight: 300;
          line-height: 1.32;
          margin: 0;
        }
        & p {
          font-size: 17px;
        }
        & a {
          position: relative;
          display: inline-block;
          .link-underline;
          & > span {
            position: relative;
            z-index: 1;
          }
          & .sol-external-link-icon {
            position: relative;
            top: -2px;
          }

          &[href^="mailto:"] {
            position: relative;
            margin-left: 30px;
            > span {
              font-size: 19px;
              height: 19px;
              .icon-size(16.5px, 23px);
              &::after {
                display: inline-block;
                content: "";
                background-image: url(/webdav/files/System/resources/graphics/icons/mail.svg);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          &[href^="tel:"]  {
            position: relative;
            margin-left: 30px;
            > span {
              font-size: 19px;
              height: 19px;
              &::before {
                display: inline-block;
                content: "";
                width: 21px;
                height: 17px;
                background-image: url(/webdav/files/System/resources/graphics/icons/phone-white.svg);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: absolute;
                height: 21px;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

        }
        & h2 {
          font-weight: normal;
        }
      }
    }
    .sol-footer-links {
      &__item {
        margin-bottom: 8px;
        a {
          span {
            font-family: @light-font;
            line-height: 1.4;
          }
          i.sol-external-link-icon {
            font-size: 14px !important;
            top: 0;
          }
        }
      }
    }
    .sol-social-media {
      .sol-clearfix;
      &__item > a {
        width: 52px;
        height: 52px;
        margin-right: 33px;
        margin-bottom: 25px;
        float: left;
        border: 2px solid @footer-social-border;
        border-radius: 50%;
        color: @footer-social-text;
        font-size: 24px;
        text-align: center;
        padding-top: 11px;
        .transition(opacity);
        &:hover {
          opacity: 0.7;
        }
        @media @xs {
          margin-right: 30px;
        }
      }
      &__item:last-child > a {
        margin-right: 0;
      }
    }
  }
  h1,
  p {
    &.font-footer-header {
      font-family: @main-font;
      color: @footer-text;
      font-size: 25px;
      margin-bottom: 26px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  p.font-footer-text {
    margin-bottom: 11px;
    font-size: 14px;
    font-family: @main-font;
    color: @footer-text;
    line-height: 22px;
    font-weight: 700;
    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }
  @media @md {
    .sol-bottom {
      padding: 30px 0;
      .sv-grid-main.sv-fluid-grid {
        padding-left: 50px;
        padding-right: 50px;
      }
      .sol-footer-contact,
      .sol-footer-links {
        &__item {
          a {
            &[href^="mailto:"] > span {
              .icon-size(18px, 23px);
            }
            &[href^="tel:"]  > span {
              &::before {
                .icon-size(18px);
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
    h1,
    p {
      &.font-footer-header {
        font-size: 23px;
      }
    }
  }
  @media @sm {
    .sol-bottom {
      padding: 20px 0;
      .sv-grid-main.sv-fluid-grid {
        padding-left: 10px;
        padding-right: 10px;
      }
      .sol-footer-contact,
      .sol-footer-links {
        &__item {
          & a,
          & p,
          & h2 {
            font-size: 16px;
          }
          p {
            font-weight: 300;
          }
          a {
            &[href^="mailto:"] > span {
              font-size: 16px;
              height: 16px;
              .icon-size(14px, 18px);
            }
            &[href^="tel:"]  > span {
              font-size: 16px;
              height: 16px;
              &::before {
                .icon-size(16px, 16px);
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    h1,
    p {
      &.font-footer-header {
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 10px;
      }
    }
  }
  & .sol-bottom .sol-footer-contact__item a {
    border-bottom: none;
    background: none;

    &:hover {
      background: none;
    }
  }
  a:focus, button:focus, [data-whatinput="keyboard"] a:focus, [data-whatinput="keyboard"] button:focus {
    outline: 2px solid #FBC900 !important;
  }
}
