// FILDELNINGSMODUL
.sv-file-portlet {
  .sv-file-portlet-fileicon {
    width: 26px;

    img {
      width: 100%;
    }
  }
    width: auto;
    
      ul{
        li{
          div{
            padding: 0;
            &:first-child{
              padding-left: 0;
            }
            a{
              position: relative;
              z-index: 1;
              text-decoration: none;
              background-size: 200% 200%;
              background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 50%, transparent 50%);
              transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
              padding-left: 1px;
              padding-right: 1px;
              border-bottom: 1px solid rgba(110, 205, 233, 0.6);
             //padding: 2px;
              &:hover{
                background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 51%, transparent 50%);
                background-position: 0 100%;
                transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
              }
            }
          }
        }
      }
    }


// LINK LIST PAGINATION
.sv-linklist-portlet > div > div:nth-child(3) {
  & a,
  & span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: 0;
    margin-right: 2px;
    margin-left: 2px;
    padding: 4px 0 0;
    border-radius: 100%;
    background-color: @pagination-bg;
    box-shadow: none;
    color: @pagination-text;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border: none;
    text-decoration: none;
    line-height: 22px;

    &:hover {
      background-color: @pagination-hover-bg;
    }
  }

  & span,
  & span {
    background-color: @pagination-current-bg;
    color: @pagination-current-text;

    & strong {
      font-weight: 300;
    }
  }
}

//SITEMAP
.sv-sitemap-portlet {
  margin-top: 50px;
  .font-normal {
    margin-bottom: 5px;
  }

  & a.font-normal {
    &:hover {
     color: darken(#c1e4c7, 30%);
    }
  }
}

//MFP
.sv-mfpimage-portlet {
  margin-top: 25px;
  margin-bottom: 30px;
}
