.sol-event-page {
  .sol-other-dates {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
  .sol-flex-cnt {
    display: flex;

  }
  .sol-event__show-all-dates {
    color: #204560;
    .link-style;
    font-size: 17px;
    font-weight: normal;
  }
  @media @sm {
    margin-right: 15px;
    margin-left: 15px;
    .sv-image-portlet,
    .sol-event-info {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  h1.font-heading-1 {
    padding-bottom: 0;
    margin-bottom: 22px;  
    &::after {
      display: none;
    }
  }
}
.sol-icon {
  &__tipsa {
    margin-right: 12px;
    max-height: 27.6px;
    &::after {
      height: 27.6px;
      width: 29.5px;
    }
  }
}
.sol-event-page {
  &__create-event-btn {
    margin: 25px 0;
    max-width: 570px;
    border-radius: 100px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: #c1e4c7;
    padding: 8px 28px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 50px;
    text-align: left;
    position: relative;
    cursor: pointer;
    overflow: visible;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    font-size: 20px;
    line-height: 1.8;
    text-decoration: none;
    &__icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 4px;
      top: 50%;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
