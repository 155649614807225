/* events/moment-picker-modified.less */
.moment-picker-input {
  cursor: pointer;
}
.moment-picker {
  position: absolute;
  z-index: 1000;
  &-container {
    color: @black;
    min-width: 20em;
    background: @white;
    padding: 4px;
    border: 1px solid @gray-4;
    position: absolute;
    margin-top: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  &.inline {
    display: block;
    position: relative;
    .moment-picker-container {
      position: relative;
      margin: 0;
    }
  }
  &.top {
    .moment-picker-container {
      bottom: 100%;
      margin-top: auto;
      margin-bottom: 4px;
    }
  }
  .right .moment-picker-container {
    right: 0;
    margin-left: auto;
    margin-right: -0.5em;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 100%;
    table-layout: fixed;
  }
  th {
    font-weight: bold;
  }
  th:first-child,
  th:last-child {
    width: 2em;
  }
  th,
  td {
    padding: 0;
    text-align: center;
    min-width: 2em;
    height: 2em;
    cursor: pointer;
    border-radius: 2px;
    &:hover {
      background-color: @lgt-color-3;
    }
    &.disabled,
    &.disabled:hover {
      color: @gray-2;
      background: none;
      cursor: default;
    }
    &.today {
      background: @gray-3;
      color: @black;
    }
    &.selected {
      color: @white;
      border-color: @color-1;
      background-color: @color-1;
    }
    &.highlighted {
      background-color: @color-1;
      color: @white;
    }
  }
  .decade-view td,
  .year-view td {
    height: 3.4em;
  }
  .month-view {
    .moment-picker-specific-views th {
      background: none;
      cursor: default;
    }
    td {
      width: 1.4285714286em;
    }
  }
  .day-view td,
  .hour-view td {
    height: 2.3333333333em;
  }
  .minute-view td {
    height: 1.8em;
  }
  i.fa-angle-right,
  i.fa-angle-left {
    font-size: 17px;
    position: relative;
    top: 1px;
  }
}
