.sv-channel-item {
  &:first-child {
    padding-top: 18px !important;
  }
}

.tyr-critical-messages--content {
  padding: 0px 10px 25px 0px !important;
}

.critical-messages__container {
  align-items: baseline;
}

.critical-messages__container-date {
  margin-right: 1rem;
}

.sol-icon__clock-small {
  &:after {
    height: 18px;
    width: 17px;
  }
}

.tyr-footer__critical-messages {
  position: fixed;
  right: 24rem;
  bottom: 0;
  display: block;
  padding: 0.6rem 1rem;
  text-align: center;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  background-color: #c8496b;
  z-index: 1039;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 55%);

  a {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
  }

  @media (max-width: 550px) {
    right: auto;
    left: 0;
  }
}

.tyr-footer__societal-disturbances {
  position: fixed;
  right: 13rem;
  bottom: 0;
  display: block;
  padding: 0.6rem 1rem;
  text-align: center;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  background-color: #c8496b;
  z-index: 1039;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 55%);

  a {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
  }

  @media (max-width: 550px) {
    left: 9rem;
    right: auto;
  }
}

#ArtviseStartWebHelp {
  border: 3px solid white;
  background-color: #204560;

  &:hover {
    background-color: #c8496b;
  }

  @media (max-width: 500px) {
    position: relative;
    bottom: 2rem;
  }
}
