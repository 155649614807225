.sol-tool-nav {
  @tool-nav-text: #333333;
  @tool-nav-mobile-text: @white;
  @to-content-bg: @color-1;
  @to-content-text: @white;
  float: right;
  margin-top: 1px;
  position: relative;
  @media @md {
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
    text-align: center;
  }
  @media @sm {
    float: none;
  }
  &__item {
    display: inline-block;
    float: left;
    margin-left: 25px;
    @media @lg-strict {
      margin-left: 15px;
    }
    @media @sm {
      margin-left: 15px;
    }
    @media @xs {
      margin-left: 7px;
    }
    &__icon {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 9px;
      & i.sol-icon {
        &__lock {
          .icon-size(22px);
        }
        &__phone {
          .icon-size(20px);
        }
        &__search {
          .icon-size(20px);
        }
        &__globe {
          .icon-size(21px);
        }
        &__legible {
          .icon-size(19px, 29px);
        }
        &__listen {
          .icon-size(17px, 23px);
        }
      }
      @media @md {
        margin-bottom: 0;
        & i.sol-icon {
          &__lock {
            .sol-icon__lock--white;
            .icon-size(18px);
          }
          &__phone {
            .sol-icon__phone--white;
            .icon-size(16px);
          }
          &__globe {
            .sol-icon__globe--white;
            .icon-size(15px);
          }
          &__legible {
            .sol-icon__legible--white;
            .icon-size(16px, 12px);
          }
          &__listen {
            .sol-icon__listen--white;
            .icon-size(14px, 19px);
          }
        }
      }
    }
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      display: block;
      color: @tool-nav-text;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      font-family: @main-font;
      padding: 0 5px;
      @media @md {
        color: @tool-nav-mobile-text;
        margin: 0 9px;
        font-size: 10px;
        font-weight: 300;
      }
      @media @sm {
        font-family: @light-font;
      }
      @media @xs {
        padding: 0;
        margin: 0 7px;
      }
    }
  }
}
.sol-to-content {
  position: absolute;
  right: -5000px;
  top: 7px;
  width: 135px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: #204560;
  font-size: 14px;
  color: #fff;
  margin-left: 0;
  &:focus {
    left: 60%;
    @media @md {
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
