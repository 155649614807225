/* part/wrapper.less */

@img-text: @black;
@img-text-bg:	@white;

.sol-section {
  padding-top: 30px;
  padding-bottom: 50px;
  @media @md {
    padding-top: 20px;
    padding-bottom: 95px;
  }
  @media @sm {
    padding-top: 25px;
    padding-bottom: 54px;
  }
}

.sol-content-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  @media @md-strict {
    max-width: 79.9%;
  }
  @media @sm {
    width: 92%;
  }
  /*& .sv-image-portlet {
    margin-left: -67px;
    margin-right: -67px;
    @media @sm {
      margin-left: -4.5%;
      margin-right: -4.5%;
    }
  }*/
  & .sv-language-portlet {
    float: left;
    margin-right: 20px;

    & a {
      text-decoration: none;
      font-size: 15px;
    }

    .sol-icon {
      .icon-size(18px, 17px);
      margin-right: 7px;
      margin-top: 1px;
      float: left;
    }

    & .sol-icon__globe {
      margin-right: 10px;
      vertical-align: middle;
    }
    & .sol-icon__english {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}

.sol-wide-content-wrapper {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  
  @media @md-strict {
    max-width: 79.9%;
  }
  @media @sm {
    width: 92%;
  }
  @media (min-width: @md-max) {
    padding-right: 25px;
    padding-left: 25px;
    box-sizing: content-box;
  }
}

.pagecontent {
  & .sv-mfpimage-portlet,
  & .sv-image-portlet {
    margin-bottom: 20px;
  }
  .sv-portlet-image-caption {
      margin-top: 0;
      padding: 8px 0px;
      background-color: @img-text-bg;
      color: @img-text;
      font-size: 17px;
      line-height: 20px;
      text-align: left;
      font-family: @main-font;
      font-weight: 300;
    }
  & .sv-linkicon {
    display: none;
  }
}
