.sol-listing-page--news {
    max-width: 833px;
    margin-left: auto;
    margin-right: auto;
    @media @md-strict {
        max-width: 81%;
    }
  &__header {
    &--special {
      display: none;

      &.sol-visible {
        display: block;
      }

    }
  } 
    .sol-page-title {
        &__cnt {
            @media @sm {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .sol-page-tools {
        @media @sm {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
  .sol-no-hits {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sol-loading {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
}
