/* components/related.less */
.sol-related-info-wrapper {
  @media @sm {
    margin-left: -4%;
    margin-right: -4%;
  }
}
.sol-contact,
.sol-related {
  padding: 26px 44px 12px 24px;
  background-color: fadeout(@lgt-color-3, 60%);
  border-radius: 3px;
  @related-header: #204560;
  @media @md-strict {
    padding: 40px 80px 52px;
  }
  &__header {
    color: @related-header;
    font-size: 30px;
    font-weight: 600;
    line-height: 0.97;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.04em;
    text-align: left;
    text-transform: uppercase;
  }
  & h4 {
    @media @xs {
      font-size: 18px;
    }
  }
}

.sol-contact{
  &__header {
    letter-spacing: 0.01em;
  }
}


.sol-related {
  @related-info-text: @color-1;
  @related-info-icon: @black;
  &__item {
    margin-bottom: 5px;
    color: @related-info-text !important;
    text-decoration: none;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.2;
    display: inline-block;
    margin-bottom: 17px;

    a &__name {
      .link-underline;
    }
    &__size {
      display: inline-block;
      color: @related-info-text;
      font-size: 12px;
      margin-left: 5px;
    }
    & i.fa {
      margin-right: 5px;
      color: @related-info-icon;
      font-size: 20px;
      &.fa-external-link {
        margin-left: 5px;
        margin-right: 0;
        font-size: 13px;
        position: relative;
        top: -1px;
      }
    }
  }
}
.sol-contact {
  @contact-heading: @black;
  @contact-link: @color-1;
  @contact-background: @white;
  @contact-border: @color-2;
  .sol-icon + a {
    .link-style;
    color: #204560;
    font-size: 19px;
    @media @sm {
      font-size: 18px;
    }

  }
  display: none;
  background-color: @contact-background;
  border: 1px solid fadeout(@contact-border, 60%);
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  &:before,
  &:after {
    bottom: 100%;
  	left: 10%;
  	border: 2px solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }
  &:before {
    border-color: rgba(197, 235, 246, 0);
  	border-bottom-color: fadeout(@contact-border, 60%);
  	border-width: 21px;
  	margin-left: -21px;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
  	border-bottom-color: #ffffff;
  	border-width: 20px;
  	margin-left: -20px;
  }
  &__header {
    padding: 11px 27px 10px;
    border-bottom: 5px solid fadeout(@contact-border, 60%);
    font-size: 23px;
    text-transform: none;
    font-weight: normal;
    margin-bottom: 0;
    h2 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .sv-row.sol-md-row {
    margin-right: 0;
    margin-left: 0;

    & > div {
      @media @sm {
        margin: 5px 3px;
        padding: 6px 12px;
      }
    }
  }

  &__item__left.sv-column-5.sol-col-md-5,
  &__item__left.sv-column-6.sol-col-md-6,
  &__item__right.sv-column-7.sol-col-md-7,
  &__item__right.sv-column-6.sol-col-md-6,
  &__political-responsibility {
    @link-border: @color-2;
    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-left: 25px;
    & > * {
      margin-top: 0 !important;
    }
    & > div {
      margin-bottom: 15px;
    }
    h3,
    h4,
    h5 {
      color: #204560;
      font-size: 20px;
      margin-top: 0;
      display: block;
      margin-bottom: 5px;
      font-weight: 300;

      @media @xs {
        font-size: 18px;
      }
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 19px;
      line-height: 1.26;

      @media @sm {
        font-size: 16px;
      }
      a {
        .link-style;
        padding: 2px;
        //color: @contact-link;
        //text-decoration: underline;
        //text-decoration-color: rgba(110, 205, 233, 0.6); 
        position: relative;
        &::after {
/*           content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: fadeout(@link-border, 40%);
          border-bottom: none; */
        }
        &:hover::after {
          //background-color: @link-border;
        }
        &:hover {
          text-decoration-color: rgba(110, 205, 233, 1);
        }
      }
    }
    .sol-external-link-icon {
      margin-left: 6px;
      font-size: 17px !important;
      position: absolute;
      top: 7px;
    }
  }
  &__item__left.sv-column-6.sol-col-md-6 {
    margin-top: 4px;
    @media @sm {
      margin-bottom: 0;
    }
  }
  &__item__right.sv-column-6.sol-col-md-6 {
    background-color: fadeout(@contact-border, 90%);
    margin-right: -10px;
    max-width: 50% !important;
    table {
      caption {
        height: 0;
      }
      thead tr th p {
        font-weight: 700;
        text-align: left;
      }
      tr td p {
        margin-bottom: -3px;
      }
      tr td:last-child p {
        text-align: right;
      }
    }
    & > p {
      line-height: 25px;
      margin-bottom: 10px;
    }
    i.sol-icon {
      margin-right: 5px;
      position: relative;
      top: 3px;
      &.sol-icon-edge {
        top: 8px;
      }
    }
    i.sol-icon.sol-icon__phone {
      .icon-size(18px);
    }
    i.sol-icon.sol-icon__mail {
      .icon-size(18px, 19px);
    }
    i.sol-icon.sol-icon__fax {
      .icon-size(18px);
    }
    @media @md {
      margin-bottom: 0;
    }
    @media @sm {
      max-width: 100% !important;
      padding-top: 20px;
    }
  }
  &__political-responsibility {
    border-top: 1px solid fadeout(@contact-border, 70%);
    width: 100%;
  }
  @media @sm {
    margin-top: 30px;
  }
}
