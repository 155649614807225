.sol-shortcut-container {
  @shortcut-top-bg: @white;
  background-color: @shortcut-top-bg;
  padding: 0 0 35px;
}
.sol-shortcut {
  @shortcut-text: @color-1;
  @shortcut-border: @gray-3;
  @shortcut-line: @color-3;
  .sol-clearfix;
  margin-left: -10px;
  margin-right: -10px;
  @media @sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &__item {
    .focus-outline;
    position: relative;
    display: inline-block;
    float: left;
    text-align: center;
    padding: 0 10px 47px;
    text-decoration: none;
    border-right: 1px solid @shortcut-border;
    .transition(background-color);
    &:focus,
    &:hover {
      opacity: 1;
      background-color: #e5f6fb;
    }
    &:last-child {
      border-right: 0;
    }
    &::after {
     display: none;
    }
    @media @sm {
      width: 33%!important;
      &:nth-child(4),
      &:last-child {
        width: 49% !important;
        margin-top: 17px;
        &:before {
          content: '';
          background-color: @shortcut-border;
          margin-top: -10px;
          width: 101%;
          height: 1px;
          display: block;
        }
      }
      &:nth-child(4):before {
        margin-left: 20px;
      }
      &:nth-child(4):before, &:nth-child(5):before  {
        margin-bottom: 35px;
      }
      &:last-child:before {
        margin-right: 20px;
      }
    }
    & .sol-icon {
      @total-height: 95px;
      display: block;
      margin: 0 auto 25px;
      .icon-size(76px, 99px);
      @media @md {
        @total-height: 70px;
      }
      @media @md {
        .icon-size(57px, 64px);
        margin-right: 0;
      }
      @media @sm {
        .icon-size(40px, 50px);
        [class*="sol-icon__akutella-arbeten"] {
          .icon-size(40px, 50px);
        }

        [class*="sol-icon__bibliotek"] {
          .icon-size(40px, 50px);
        }

        [class*="sol-icon__elev"] {
          .icon-size(45px, 50px);
        }
      }
      &__books {
        @height: 64px;
        .icon-size(@height; 47px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 37px;
          .icon-size(37px, 33px);
        }
      }
      &__info {
        @height: 67px;
        .icon-size(@height; 67px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 37px;
          .icon-size(37px);
        }
      }
      &__commenting {
        @height: 71px;
        .icon-size(@height; 69px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 41px;
          .icon-size(41px, 39px);
        }
      }
      &__work {
        @height: 71px;
        .icon-size(@height; 69px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 41px;
          .icon-size(41px, 39px);
        }
      }
      &__clock {
        @height: 72px;
        .icon-size(@height; 73px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 42px;
          .icon-size(42px, 43px);
        }
      }
      &__compare {
        @height: 68px;
        .icon-size(@height; 75px);
        margin-top: @total-height - @height;
        @media @md {
          @height: 38px;
          .icon-size(38px, 45px);
        }
      }
    }
    & > span {
      display: block;
      color: @shortcut-text;
      font-size: 21px;
      line-height: 27px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      @media @md {
        font-size: 16px;
      }
      @media @xs {
        font-size: 13px;
        letter-spacing: 0.2px;
      }
    }
    &:focus,
    &:hover {}
  }
}
