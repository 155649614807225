/* decorations/search-form.less */
.sol-box-decoration {
  @box-bg: @white;
  @box-header-bg: @color-4;
  @box-header-color: @white;
  .sol-clearfix;
  border-radius: 4px;
  background-color: @box-bg;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  &-header {
    display: block;
    padding: 12px 15px;
    background-color: @box-header-bg;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: @box-header-color;
    text-align: left;
    text-decoration: none;
    line-height: 13px;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    a & {
      .focus-outline;
    }
  }
  .sv-text-portlet.sol-btn-small .font-btn {
    margin-bottom: 5px;
  }
}

.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
