.sol-iframe {
    width:100%;
    border:none;
    &.etjanst {
        height:1000px;
    }
    &.dedu {
        height:1050px;
    }
}
.sv-iframe-portlet {
    height: 1200px;
    @media @sm {
        height: 800px;
        overflow: scroll;
    }
    @media (max-width: 321px) {
        height: auto;
    } 
    @media (min-width: 373px) and (max-width: 376px) {
        height: auto;
    } 
}
