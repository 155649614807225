.sol-file-uploader {
  .sol-form-group;
  &__error-message {
    color: red;
  }
  input {
    height: 0;
    opacity: 0;
  }
  &__file-name {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 300;
    line-height: 34px;
  }
  &__label {
    display: block;
    font-size: 14px;
    //font-weight: 700;
    margin-bottom: 8px;
  }
  &__upload-btn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    min-height: 40px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color,color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-family: PFDINTextStd-Regular,sans-serif;
    border: 0;
    padding: 5px 20px;
    margin-top: 2px;
    font-size: 13px;
  }
}