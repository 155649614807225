/* special/search.less */
@search-hit-text: @black;
@search-hit-text-hover: @color-1;
@search-hit-bg: @white;
@search-hit-icon: @color-1;
@search-hit-path: @color-1;
@search-hit-path-hover: @black;

@pagination-bg: transparent;
@pagination-text: @black;
@pagination-hover-bg: @lgt-color-1;
@pagination-current-text: @white;
@pagination-current-bg: @color-1;

.search-hit {
  &__path {
    margin-bottom: 0 !important;
    a {
      font-size: 16px;
      &::after {
        bottom: -1px !important;
      }
    }
  }
}
.sol-search-page {
  &__form {
    @media @sm {
      max-width: 90% !important;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__wrapper {
    padding-top: 78px;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media @md-strict {
      padding-top: 49px;
    }
    @media @sm {
      padding-top: 28px;
      width: 100%;
    }

    .pagination {
      display: flex;
      align-items: center;
      @media @sm {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
      }
      span {
        width: 30px;
        height: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 19px;
        text-align: left;
        color: #313131;
        text-decoration: none;
        //border: solid 2px rgba(110, 205, 233, 0.6);
        border-radius: 50%;
        background-color: transparent;
        &.current {
          border: solid 2px rgba(110, 205, 233, 0.6);
        }

        &.current.prev, &.current.next {
          border: none;
          width: auto;
          height: auto;
        }
      }

      a{
        width: 30px;
        height: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 19px;
        text-align: left;
        color: #313131;
        text-decoration: none;
        cursor: pointer;
        border: none;
        background-color: transparent;
        &::after {
          display: none;
        }
        &.prev, &.next {
          width:auto;
          height:auto;
          border: none;
          font-size: 19px;
          font-weight: 300;
          text-align: left;
          color: #204560;
          line-height: 1;
          position: relative;
          padding: 0;
          &::after {
            display: block;
            content: '';
            border-bottom: 2px solid rgba(110, 205, 233, 0.6);
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -2px;
            z-index: -1;
          }
        }
      }
      .current {
        color: #204560;
      }


    }
    > div.pagecontent {
      width: 100%;
    }
    .sol-search-page + div > p {
      font-size: 15px;
      font-weight: 300;
      text-align: left;
      color: #333333;
      margin-left: 28px;
      margin-top: 13px;
    }
    .sol-search-page + div > div {
      display: flex;
      justify-content: space-between;
      @media @md {
        flex-wrap: wrap;
      }
    }
    .sv-search-facet {
      margin-bottom: 1em;
    }
    .sv-search-facets {
      float: none;
      order: 2;
      width: 30%;
      padding: 0 !important;
      @media @md {
        order: 0;
        width: 100%;
        max-width: 620px;
        display: flex;
        justify-content: space-between;
      }
      @media @sm {
        flex-direction: column;
      }
      > .sv-search-facet {
        @media @md {
          width: 45%;
          position: relative;
          &:nth-child(1) {
            z-index: 5;
          }

          &:nth-child(2) {
            z-index: 4;
          }

          &:nth-child(3) {
            z-index: 3;
          }
        }
        @media @sm {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        > p {
          @media @md-strict {
            display: none;
          }
        }
        > button {
          display: none;
          @media @md {
            height: 50px;
            border-radius: 100px;
            background-color: #c1e4c7;
            display: flex;
            padding-left: 25px;
            align-items: center;
            width: 100%;
            border: none;
            font-size: 17px;
            position: relative;
            .sv-search-facet__button {
              background-color: @white;
              border-radius: 50%;
              height: 40px;
              width: 40px;
              position: absolute;
              top: 5px;
              right: 5px;
              &::after,
              &::before {
                content: '';
                display: block;
                background-color: @black;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 22px;
                height: 4px;
                margin-left: -11px;
                margin-top: -2px;
                border-radius: 2px;
                .transition(all);
              }
              &::after {
                .rotate(90deg);
              }
            }
            &.sol-active {
              background-color: #204560;
              color: @white;
              & .sv-search-facet__button {
                &::after {
                  .rotate(0deg);
                }
              }
            }
          }
        }
        > ul {
          @media @md {
            display: none;
            margin: 0;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            padding: 14px 19px 24px 19px;
            position: absolute;
            top: 44px;
            left: 5%;
            background-color: @white;
            width: 90%;
            z-index: -1;
            border-radius: 3px;
            > li {
              margin: 0 0 10px !important;
              a {
                position: relative;
                &::after {
                  content: '';
                  border-bottom: 2px solid rgba(110,205,233,0.6);
                  position: absolute;
                  left: -1px;
                  right: -1px;
                  bottom: 3px;
                  z-index: -1;
                }
                &:hover {
                  &::after {
                    border-bottom-color: #6ecde9;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sv-search-facet-name {
      font-size: 17px;
      line-height: 2.12;
      font-weight: 600;
      margin-bottom: 4px;
      text-transform: uppercase;
      @media @md {
        display: none;
      }
    }
    .sv-search-facet-item {
      display: flex;
      justify-content: space-between;
      font-size: 17px;
      color: #333333;
      font-weight: 400;
      margin-bottom: 8px;
      a {
        font-size: 17px;
        color: #333333;
      }
      &--has-matches {
        a {
          font-size: 17px;
          text-align: left;
          color: #204560;
        }
      }

    }
    .sv-search-facet-item-selected {
      a {
        &::after {
          display: block;
          content: '';
          border-bottom: 2px solid rgba(110, 205, 233, 0.6);
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: 3px;
          z-index: -1;
        }
      }
    }
  }
  &__left {
    width: 60%;
  }
  &__form {
    max-width: 620px;
    position: relative;
    input[type='search'] {
      .search-field;
      height: 60px;
      font-size: 21px;
    }
    button[type='submit'] {
      .search-button;
      i.sol-icon {
        margin-right: 20px;
        .icon-size(28px, 33px);
      }
    }
  }
}

.sv-facetedsearch-portlet {
  p.font-normal {
    strong {
      font-weight: bold;
    }
  }
  .sol-pagination {
    max-width: 620px;
    width: 100%;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    & a {
      color: #204560;
      font-size: 19px;
      font-weight: 300;
      border: none;
      text-decoration: none;
      line-height: normal;
    }
  }
}

.sv-search-result {
  max-width: 620px;
  width: 100%;
  .sv-search-hit {
    padding: 18px 20px 19px 24px !important;
    font-size: 17px;
    img {
      width: 20px;
      height: auto;
      &[src*="fa-file-o"] {
        display: none;
      }
    }
    // Rubrik av sökträff
    > p {
      font-size: 21px;
      font-weight: 300;
      text-align: left;
      color: #333333;
    }
    > div > a {
      font-size: 21px;
      text-align: left;
      color: #204560;
    }
  }
  .sv-search-hit {
    &:first-child {
      border-top: solid 1px rgba(110, 205, 233, 0.4);
    }
    &__last-modified {
      font-size: 17px;
      text-align: left;
      font-weight: bold;
      color: #333333;
    }
    border-bottom: solid 1px rgba(110, 205, 233, 0.4);
  }
}

.sv-standardsearch-portlet {
  .sol-did-you-mean,
  .sol-num-hits {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 12px;
  }

  .sv-search-hit {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 20px 50px 20px 40px;
    border-radius: 2px;
    background-color: @search-hit-bg;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    & .font-heading-3 {
      display: block;
      margin-top: 0;
      margin-bottom: 3px;
      color: @search-hit-text;
      font-size: 15px;
      font-weight: 800;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: @search-hit-text-hover;
      }
    }

    & .font-normal {
      font-size: 15px;
      line-height: 1.4em;
    }

    & .sol-hit-info-text {
      font-size: 13px;

      & i.fa {
        color: @search-hit-icon;
        margin-right: 5px;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

      & > strong {
        margin-left: 10px;
      }

      & > strong:first-of-type {
        margin-left: 0;
      }
    }

    & .sol-hit-path-text {
      font-size: 12px;

      & a {
        color: @search-hit-path;
        text-decoration: none;

        &:hover {
          color: @search-hit-path-hover;
          text-decoration: underline;
        }
      }
    }
  }
}
