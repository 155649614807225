.sol-arrow-circle__btn {
  background-color: rgba(110, 205, 233, 0.45);
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  border: 0;
  .sol-icon::after {
    width: 25px;
    height: 25px;
  }
  span.sol-icon__arrow--2 {
    padding-top: 1rem;
  }
}

.sol-arrow-circle__cnt {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 45px;
  margin-right: 45px;
  flex-direction: column;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //margin-left: -125px;
    margin-bottom: 30px;
  }
  span {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #525252;
    margin-top: 15px;
  }
  & > span {
    padding: 2px 6px;
    border-radius: 4px;
    background-color: white;
  }
}
.sv-marketplace-sitevision-toTop {
  float: right;
  padding-right: 9.5rem;
  .sv-to-top--right {
    right: unset !important;
    padding-bottom: 1.5rem;
    margin-left: 1.25rem;
    @media (max-width: 500px) {
      bottom: 3rem !important;
    }
  }
}

.sv-marketplace-sitevision-toTop a.sv-to-top--right:focus {
  outline: none !important;
  svg {
    outline: 2px solid #fbc900 !important;
  }
}
