.sol-authority__list-item {
  margin-bottom: 25px;
  .fa-file-pdf-o {
    margin-right: 5px;
  }
  .sol-collapsible__header--active {
    background-color: #204560;
    color: #fff;
  }
  .font-normal {
    margin-bottom: 5px;
  }
  .sol-collapsible__header {
    margin-bottom: 0;
  }
  .sol-notice__list {
    padding: 15px;
  }
  .sol-notice__publish-date, .sol-notice__unpublish-date {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 17px;
  }
  .sol-notice__list-item {
    margin-bottom: 20px;
  }
  .sol-notice__file-list {
    margin-top: 5px;
  }
  .sol-notice__file-list-item {
    > a {
      .link-style;
      font-size: 16px;
    }
  }
  .sol-collapsible__body {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    border-radius: 3px;
    margin: 0 20px;
    > span {
      padding: 15px;
    }
  }
}