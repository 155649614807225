.sol-popular-pages {
  background-color: #ECF6EE;
  @text-color: @color-1;
  .clearfix;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &__header {
    line-height: 19px;
    letter-spacing: 0.4px;
    color: @black;
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  &__link {
    .sol-btn;
    text-transform: initial;
    background-color: @fringy-flower;
    padding: 12px 15px;
    color: @black;
    display: block;
    border-radius: 3px;
    margin-left: 18px;
    font-size: 17px;
    line-height: 21px;
    .sol-icon {
      .icon-size(13px, 15px);
      margin-right: 8px;
      margin-top: 3px;
      float: left;
      &::after {
        float: left;
      }
    }
  }
  @media @md {
    display: block;
    padding: 13px 22px 13px 120px;
    position: relative;
    &__header {
      font-size: 18px;
      position: absolute;
      top: 13px;
      left: 22px;
    }
    &__link {
      display: inline-block;
      font-size: 17px;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 0;
      padding: 6px 20px;
      line-height: normal;
    }
  }
  @media @sm {
    display: block;
    padding: 13px 16px;
    &__header {
      position: relative;
      font-size: 16px;
      margin-bottom: 10px;
      top: auto;
      left: auto;
    }
    &__link {
      font-size: 15px;
      min-height: 35px;
      padding: 8px 20px 4px;
    }
  }
}
