/* components/publish-info.less */

.sol-publish-info {
  @pub-bg: rgba(110, 205, 233, 0.1);
  background: @pub-bg;
  padding: 15px 32px 14px 24px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  font-family: @light-font;
  strong {
    font-weight: normal;
    font-family: @main-font;
  }
  @media @md-strict {
    margin-top: 15px;
    font-size: 16px;
  }
  @media @xs {
    padding: 13px 23px 14px;
    font-size: 16px;
    a {
      font-size: 16px;
    }
  }
  > div {
    &:first-child {
      margin-bottom: 6px;
    }
  }
  a {
    .link-underline;
    color: #204560;
    font-family: PFDINTextStd-Light, sans-serif;
    font-size: 19px;
    font-weight: 300;
    line-height: 1;
    margin-left: 4px;

    &:hover {
      color: #204560;
    }
  }
}
