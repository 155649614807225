.tyr-main__portal-page--puff-container {
  .sol-widget-decoration-wrapper {
    .sv-decoration-content {
      @media @md-strict {
        flex-wrap: wrap;
        .sol-widget-decoration-img {
          width: 100% !important;
        }
      }
    }
  }
}
