/* elements/buttons.less */
.button() {
  @button-text: @black;
  @button-bg: @btn-bg;
  @button-bg-hover: fadeout(@btn-bg, 70%);
  @button-text-hover: @white;
  @button-bg-active: @btn-bg-active;
  .focus-outline;
  position: relative;
  display: inline-block;
  line-height: 22px;
  min-height: 40px;
  padding: 8px 25px;
  color: @button-text;
  border-radius: 3px;
  background-color: @button-bg;
  border-radius: 3px;
  .transition(~'background-color, color');
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-family: @main-font;
  border: 0;
  &:hover {
    background-color: @button-bg-hover;
  }
  &:active {
    background-color: @button-bg-active;
  }
  & i.fa {
    margin-right: 10px;
    margin-left: -5px;
    font-weight: 400;
  }
}
.button-small() {
  @button-small-bg: @btn-bg;
  @button-small-text: @black;
  @button-small-bg-hover: fadeout(@btn-bg, 70%);
  @button-small-text-hover: @white;
  cursor: pointer;
  min-height: 35px;
  padding: 7px 25px 8px;
  color: @button-small-text;
  background-color: @button-small-bg;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
  border: 0;
  border-radius: 3px;
  &:hover {
    background-color: @button-small-bg-hover;
    color: @button-small-text-hover;
  }
  &:active {
    top: 0;
  }
}
