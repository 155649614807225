/* messages.less */
.sol-crisis-container {
  @crisis-bg: #d83a48;
  @crisis-text: @white;
  background-color: @crisis-bg;
  color: @crisis-text;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  &__content {
    max-width: 924px;
    margin: 0 auto;
    padding: 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
  }

  & i.sol-icon {
    margin-right: 30px;
    &__warning:after {
      height: 124px;
      width: 124px;
      line-height: 1;
    }
  }

  .sol-crisis-message {
    .sol-clearfix;
    &__published,
    &__updated {
      color: @crisis-text;
      font-size: 17px;
      display: inline-block;
      & b {
        font-weight: 600;
      }
    }

    &__updated {
      margin-left: 100px;
    }

    &__title {
      display: block;
      margin-bottom: 5px;
      color: @crisis-text;
      font-size: 30px;
      line-height: 39px;
      font-weight: 600;
    }

    &__desc {
      color: @crisis-text;
      font-size: 21px;
      font-weight: 300;
      line-height: 1.2;
      margin-right: 10px;
      display: inline;
    }

    &__more {
      float: right;
      display: block;
      font-size: 19px;
      font-weight: 300;
      line-height: 1;
      color: @crisis-text;
      white-space: nowrap;
      .link-underline;
      &::after {
        border-bottom-width: 1px;
        border-color: fadeout(@crisis-text, 50%);
      }
      &:hover::after {
        border-bottom-color: @crisis-text;
      }
    }
    &.sol-one-line {
      .sol-crisis-message__title {
        display: inline-block;
      }
      .sol-crisis-message__more {
        position: relative;
        top: -2px;
        left: 20px;
      }
    }
  }
  @media @md {
    & i.sol-icon__warning:after {
      .icon-size(78px, 87px);
    }
    .sol-crisis-message {
      &__title {
        font-size: 26px;
      }
      &__desc,
      &__more {
        font-size: 19px;
      }
    }
  }
  @media @sm {
    position: relative;
    &__content {
      padding-left: 81px;
    }
    & i.sol-icon__warning:after {
      width: 50px;
      left: 15px;
      top: 65px;
      .icon-size(43px, 48px);
    }
    .sol-crisis-message {
      &__published {
        position: absolute;
        top: 10px;
        left: 15px;
        font-size: 15px;
      }
      &__updated {
        position: absolute;
        top: 30px;
        left: 15px;
        font-size: 15px;
        margin-left: 0;
      }
      &__title {
        font-size: 20px;
        margin-top: 32px;
      }
      &__desc,
      &__more {
        font-size: 16px;
      }
    }
  }
}
.sol-info-container {
  @info-bg: #A8E1F2;
  @info-text: @color-1;
  background-color: @info-bg;
  color: @info-text;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  &__content {
    max-width: 885px;
    margin: 0 auto;
    padding: 23px 20px 22px;
    position: relative;
    display: flex;
    align-items: center;
  }
  & i.fa {
    position: absolute;
    left: 10px;
    top: 28px;
    font-size: 65px;
    line-height: 1;
  }
  & .sol-icon {
    .icon-size(65px);
    margin-right: 30px;
    &.sol-icon-edge {
      margin-right: 95px;
    }
  }
  .sol-info-message {
    .clearfix;
    &__published,
    &__updated {
      color: @info-text;
      font-size: 17px;
      display: inline-block;
      & b {
        font-weight: 600;
      }
    }

    &__updated {
      margin-left: 100px;
    }

    &__title {
      display: block;
      color: @info-text;
      font-size: 27px;
      line-height: 35px;
      font-weight: 600;
    }

    &__desc {
      color: @info-text;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.2;
      margin-right: 10px;
      display: block;
      margin-top: 5px;
    }

    &__more {
      display: block;
      float: right;
      font-size: 17px;
      font-weight: 300;
      line-height: 1;
      color: @info-text;
      white-space: nowrap;
      .link-underline;
      &::after {
        border-bottom-width: 1px;
        border-color: fadeout(@info-text, 40%);
      }
      &:hover::after {
        border-bottom-color: @info-text;
      }
    }
    &.sol-one-line {
      .sol-info-message__title {
        display: inline-block;
      }
      .sol-info-message__more {
        position: relative;
        top: -2px;
        left: 20px;
      }
    }
  }
  @media @md {
    .sol-icon:after {
      .icon-size(55px);
    }
    .sol-info-message {
      &__title {
        font-size: 26px;
      }
      &__desc,
      &__more {
        font-size: 19px;
      }
    }
  }
  @media @sm {
    position: relative;
    &__content {
      padding-left: 50px;
    }
    .sol-icon:after {
      .icon-size(48px);
      position: absolute;
      left: 10px;
      width: 50px;
      top: 65px;
    }
    .sol-info-message {
      &__published {
        position: absolute;
        top: 10px;
        left: 15px;
        font-size: 15px;
      }
      &__updated {
        position: absolute;
        top: 30px;
        left: 15px;
        margin-left: 0;
        font-size: 15px;
      }
      &__title {
        font-size: 20px;
        margin-top: 35px;
      }
      &__desc,
      &__more {
        font-size: 16px;
      }
    }
  }
}

.sol-no-script {
  i.fa-warning {
    font-size: 50px;
    margin-top: -57px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;
  }
}
