.IDRelayContainer {
  form {
    .env-form-element {
      label {
        font-size: 16px;
      }
    }
    .env-checkbox {
      display: flex;
      align-items: flex-start;
      flex-flow: row wrap;
      label {
        .env-checkbox__fake {
          margin-bottom: 4px !important;
        }
        .env-checkbox__label {
          font-size: 16px;
        }
      }
    }
    .env-form-element .env-form-element__control input.env-form-input {
      border: 1px solid #6ecde9;
      border-radius: 2px;
      padding: 1rem 0.7rem;
    }
    .env-form-element input.env-button {
      background-color: #c1e4c7;
      border: 4px solid #c1e4c7;
      color: #000;
      font-size: 16px;
      &:hover {
        background-color: #daefdd;
        border: 4px solid #daefdd;
      }
    }
  }
}