.sol-startsida__puff__wrapper {
  margin-top: 195px;
  @media @md-strict {
    margin-top: 165px;
  }
  @media @sm {
    margin-top: 23px;
  }
}

.tyr-main__portal-page--puff-wrapper.sol-startsida__puff__wrapper {
  @media @md-strict {
    margin-top: 225px;
  }
}

.sol-puff {
  @title__color: @downriver;
  @text__color: @black;
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    @media @md-strict {
      max-width: 94%;
      flex-direction: column;
    }
    @media @sm {
      max-width: 92%;
    }
    > div {
      flex: 1 1 32%;
      max-width: 32%;
      margin-bottom: 25px;
      @media @md {
        min-height: 130px;
      }
      &:nth-child(3n + 2) {
        margin-left: 2%;
        margin-right: 2%;
        @media @md {
          margin-left: 0;
          margin-right: 0;
        }
      }
      @media @md-strict {
        flex: 1 1 100%;
        max-width: 100%;
      }
      &:nth-child(odd) {
        /*@media @md-strict {
          margin-right: 2%;
        }*/
      }
      @media @sm {
        flex: 1 0 100%;
        max-width: 100%;
      }
    }
  }
  &__img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    @media @md-strict {
      flex: 0 0 27.5%;
      max-width: 27.5%;
      height: 100%;
    }
  }
  &__content {
    padding: 15px 29px 20px 25px;
    background-color: @white;
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.08);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    &__title {
      font-size: 21px;
      font-weight: bold;
      color: @title__color;
      @media @sm {
        font-size: 19px;
        font-weight: bold;
      }
    }
    &__text {
      font-size: 21px;
      font-weight: 300;
      text-align: left;
      color: @text__color;
      text-decoration: none;
      transition: all 0.3s ease;
      @media @md-strict {
        font-size: 16px;
        font-weight: 300;
      }
      @media @sm {
        font-size: 16px;
        font-weight: 300;
      }
      &::after {
        display: none !important;
      }
    }
  }
  &__link {
    text-decoration: none;
    @media @md-strict {
      display: flex;
      height: 130px;
    }
    &:hover {
      .sol-puff__content__text {
        text-decoration: none;
      }
      .sol-puff__content__title {
        text-decoration: underline;
      }
    }
  }
}
