.sol-mobile-search {
  @background-color: @color-1;
  @button-color: transparent;
  @text-field: @white;
  @border: @color-2;
  position: relative;
  z-index: 9999;
  @media (max-width: 1024px) and (min-width: 768px) {
    display: block !important;

    & form {
      height: 64px;
    }
  }
  @media @lg {
    form {
      display: none;
    }
  }
  @media @md {
    .sv-searchform-portlet {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: @text-field;
      form {
        margin-top: 5px;
        background-color: @background-color;
        input[type="text"] {
          margin-top: 2px;
          margin-bottom: 15px;
          background-color: @text-field;
          box-shadow: none;
          border: 1px solid @border;
          border-radius: 5px;
          height: 50px;
          padding-left: 18px;
          font-size: 15px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90% !important;
        }
        input[type="submit"] {
          content: "Sök";
          color: #fff;
          width: 50px;
          height: 50px;
          background-color: transparent;
          background-image: url('/webdav/files/System/resources/graphics/icons/search.svg');
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 3px;
          border: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 50px;
          cursor: pointer;
          font-size: 17px;
          &:hover {
            background-color: darken(@button-color, 10%);
          }
        }
        &:after {
          bottom: 100%;
        	right: 77px;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        	border-color: rgba(32, 69, 96, 0);
        	border-bottom-color: #204560;
        	border-width: 10px;
          margin-bottom: -5px;
        	margin-left: -10px;
        }
      }
    }
  }
  @media @sm {
    .sv-searchform-portlet form {
      > div {
        padding: 5px;
        height: 70px;
        position: relative;
      }
    }
    .sv-searchform-portlet form input {
      &[type="text"] {
        margin-left: 10px;
        margin: 0 !important;
      }
      &[type="submit"] {
        right: 20px;
        margin: 0 !important;
        background-image: url('/webdav/files/System/resources/graphics/icons/search.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
