.sol-vacancies-listing {
  @background: fadeout(@color-2, 90%);
  @borderhover: @color-2;
  li {
    padding: 10px 20px;
    margin-bottom: 0;
    &:nth-child(even) {
      background-color: @background;
    }
  }
  .sol-icon__link {
    float: left;
    margin-top: 2px;
    margin-right: 15px;
    .icon-size(0, 0);
  }
  h2 {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 300;
    a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: -1px;
        right: -1px;
        bottom: 3px;
        z-index: -1;
      }
      &:hover:after {
        border-bottom: 2px solid @borderhover;
      }
    }
  }
  span.font-normal {
    float: right;
    font-size: 15px;
    margin-bottom: 0;
  }
}
