.tyr-border-around {
  border-width: 3px;
  border-style: solid;
}

.tyr-hanging-box {
  width: 100%;
  height: auto;
  overflow: auto;
}

.tyr-responsive-image {
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.tyr-active-tab {
  .tyr-active-tab-item {
    background-color: #2a8659 !important;
    color: #fff !important;
    margin-right: 0 !important;
  }
}

.tyr-active--critical-tab {
  .tyr-active--critical-tab-item {
    background-color: #c8496b !important;
    color: #fff !important;
    margin-right: 0 !important;
  }
}

.selected {
  background-color: #c8496b !important;
  color: #fff !important;
  margin-right: 0 !important;
  a {
    color: #fff !important;
  }
}

.tyr-tab {
  &:not(.tyr-active-tab) {
    &:not(.tyr-active--critical-tab) {
      border: 1px solid lightgray !important;
      border-bottom: none !important;
      margin-left: 2px;
    }
  }
}

.tyr-tab-container {
  border-bottom: 2px solid #2a8659 !important;
}

.tyr--critical-tab-container {
  border-bottom: 2px solid #c8496b !important;
}

.tyr-search-hit {
  &:first-child {
    border-top: none !important;
  }
}

.tyr-search-hit {
  &:not(:first-child) {
    border-top: 1px solid #2a8659 !important;
    border-bottom: none;
  }
}

/* Critical messages */

.tyr-tab {
  border-radius: none !important;
}

.tyr-tab {
  margin-left: 0 !important;
}

.tyr-critical-messages--item {
  p {
    margin-bottom: 0;
    margin-top: 0.2em !important;
  }
}

.tyr-tab {
  &:not(.tyr-active-tab) {
    &:not(.tyr-active--critical-tab).selected {
      border-bottom: none !important;
    }
  }
}

.tyr-main__disturbance-archive .tyr-main__tabs .tyr-main__tabs--item {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.8rem 0;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  a {
    position: relative;
    text-decoration: none;
    margin-bottom: 1rem;
    padding: 0 0.4rem 1rem;
  }
}

.tyr-main__disturbance-archive {
  .tyr-main__tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 5px solid #d8f1f8;
    margin-bottom: 1rem;
  }
}

.sv-search-result {
  .sv-search-hit {
    &:first-child {
      border-top: none !important;
    }
  }
}

.sv-search-result {
  max-width: none;
}

/*.sv-search-result {
    li.sv-search-hit {
        padding: 18px 20px 19px 0px!important;
    }
}*/

.tyr-time--container {
  display: flex;
  align-items: flex-start;
  time {
    margin-bottom: 0;
  }
}

/* End of critical messages */

@media only screen and (max-width: 600px) {
  .tyr-hanging-box {
    width: auto;
  }
  .tyr-tab-container {
    display: grid;
  }

  .responsive-list {
    width: 74% !important;
  }

  .tyr-responsive-image {
    display: flow-root;
  }

  .tyr-responsive-icon {
    display: none;
  }

  .tyr-responsive-img-div {
    margin-right: 0.7em;
  }
}
