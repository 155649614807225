/* components/modals.less */
.sol-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  transition: opacity 200ms ease;
  &.sol-overlay-active {
    opacity: 1;
    //z-index: 2000;
  }
  & .sol-overlay-close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #d1d1d1;
    font-size: 33px;
    cursor: pointer;
  }
  & .sol-overlay-content {
    position: absolute;
    top: 33%;
    left: 50%;
    @media @md {
      top: 80px;
      margin-top: 0 !important;
    }
    @media @sm {
      width: auto !important;
      margin: 0 !important;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
    }
  }
}
.sol-modal {
  display: block;
  background-color: @color-1;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.19);
  padding: 33px 43px;
  color: @white;
  position: relative;
  border-radius: 3px;
  @media @lg {
    width: 95vw;
  }
  @media @sm {
    width: 100% !important;
    max-height: 100%;
    overflow: auto;
    padding: 20px;
  }
  & .sv-fluid-grid.sv-grid-main {
    padding: 0;
  }
  &-close {
    .no-appearance;
    .focus-outline;
    display: block;
    width: 44px;
    height: 44px;
    background-color: @color-1;
    position: absolute;
    top: -14px;
    right: -14px;
    border-radius: 50%;
    cursor: pointer;
    @media @sm {
      position: fixed;
      top: 10px;
      right: 10px;
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 9px;
      top: 20px;
      width: 26px;
      height: 4px;
      border-radius: 2px;
      background-color: @white;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  & &-h2 {
    margin-top: 0;
    margin-bottom: 5px;
    color: @white;
    font-family: @main-font;
    font-size: 19px;
  }
  & h3,
  & h4 {
    color: @white;
    font-family: @main-font;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 5px;
  }
  & &-text,
  & &-text p {
    font-family: @main-font;
    font-size: 16px;
    line-height: 1.63;
    color: @white;
    font-weight: 300;
    margin: 0;
    & a {
      color: @white;
    }
  }
  & .sv-portlet-toolbar {
    display: none;
  }
}
.sol-translate-modal {
  max-width: 710px;
  width: 710px;
  .sol-translate-container {
    background-color: @white;
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 5px;
    width: ~'calc(33.333% - 5px)';
    text-align: center;
    @media @sm {
      position: static;
      width: ~'calc(100% + 20px)';
      min-height: 100px;
      display: block;
      padding-top: 23px;
      margin: 20px -10px -10px;
    }
    & > div {
      position: relative;
      top: 50%;
      margin-top: -25px;
      @media @sm {
        position: static;
        margin-top: 0;
      }
    }
  }
  .sol-modal-text {
    a {
      display: inline-block;
      margin-top: 20px;
    }
  }
}
