.sol-error-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sv-font-404-rubrik {
        @media @md {
            font-size: 100px;
        }
    }
    .sv-font-404-ingress {
        @media @md {
            font-size: 25px;
        }
    }
   
}
.sol-404-btn__cnt {
    display: flex;
    width: 100%;
    justify-content: center;
    .sol-decoration-btn {
        margin-right: 15px;
    }
}