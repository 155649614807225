.sol-event-search__tabs {
  display: flex;
  padding-left: 103px;
  border-bottom: 3px solid @downriver;
  margin-top: 25px;
  @media @md-strict {
    padding-left: 0;
    justify-content: center;
  }
  @media @sm {
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 40px;
  }
  > .sol-event-search__tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    height: 45px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border: solid 1px rgba(51, 51, 51, 0.11);
    text-decoration: none;
    font-size: 18px;
    span {
      margin-left: 5px;
    }
    &:first-child {
      margin-right: 9px;
    }
    &--active {
      background-color: #0e3c5d;
      color: @white;
    }
  }
}
.sol-event-listing-page__event-list {
  &__wrapper {
    padding: 35px 103px 66px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    @media @md-strict {
      padding: 3px 26px 17px 0;
    }
    @media @sm {
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
    }
  }
  list-style: none;
  margin-top: 0;
  padding: 0;
}
