.sv-login-portlet.sol-form-decoration {
  box-shadow: none;
  padding-bottom: 0;
  margin-bottom: 0;
  .sv-login-input-group {
    margin-bottom: 0;
  }
  input[type='submit'] {
    margin-top: 0;
  }
  fieldset {
    margin: 0;
    padding: 0;
  }
}
