.icon-size(@height;
@width: @height) {
  max-height: @height;
  &::after {
    height: @height;
    width: @width;
  }
  &.sol-icon-edge {
    height: @height;
    width: @width;
    &::after {
      transform: scale(0.5);
      height: @height * 2;
      width: @width * 2;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }
  }
}
.sol-icon {
  display: inline-block;
  &::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    height: 15px; 
    width: 15px;
  }
}
.sol-required-asterisk {
  &:after {
    display: inline-block;
    content: "\002a";
    color: red;
    font-size: 14px;
  }
}

.sol-icon__arrow {
  &::after {
    background-image: url('@{sol-icons-path}/arrow.svg');
  }
}
.sol-icon__arrow--black {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--black.svg');
  }
}
.sol-icon__arrow--blue {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--blue.svg');
  }
}
.sol-icon__arrow--pink {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--pink.svg');
  }
}
.sol-icon__arrow--2 {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--2.svg');
  }
}
.sol-icon__arrow--white {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--white.svg');
  }
}
.sol-icon__books {
  &::after {
    background-image: url('@{sol-icons-path}/books.svg');
  }
}
.sol-icon__briefcase {
  &::after {
    background-image: url('@{sol-icons-path}/briefcase.svg');
  }
}
.sol-icon__chat {
  &::after {
    background-image: url('@{sol-icons-path}/chat.svg');
  }
}
.sol-icon__clock {
  &::after {
    background-image: url('@{sol-icons-path}/clock.svg');
  }
}
.sol-icon__clock-small {
  &::after {
    background-image: url('@{sol-icons-path}/clock-small.svg');
  }
}
.sol-icon__clock-small--white {
  &::after {
    background-image: url('@{sol-icons-path}/clock-small--white.svg');
  }
}
.sol-icon__commenting {
  &::after {
    background-image: url('@{sol-icons-path}/commenting.svg');
  }
}
.sol-icon__compare {
  &::after {
    background-image: url('@{sol-icons-path}/compare.svg');
  }
}
.sol-icon__computer {
  &::after {
    background-image: url('@{sol-icons-path}/computer.svg');
  }
}
.sol-icon__e-services {
  &::after {
    background-image: url('@{sol-icons-path}/e-services.svg');
  }
}
.sol-icon__english {
  &::after {
    background-image: url('@{sol-icons-path}/english.svg');
  }
}
.sol-icon__eye {
  &::after {
    background-image: url('@{sol-icons-path}/eye.svg');
  }
}
.sol-icon__eye--light {
  &::after {
    background-image: url('@{sol-icons-path}/eye--light.svg');
  }
}
.sol-icon__fax {
  &::after {
    background-image: url('@{sol-icons-path}/fax.svg');
  }
}
.sol-icon__globe {
  &::after {
    background-image: url('@{sol-icons-path}/globe.svg');
  }
}

.sol-icon__globe--black {
  &::after {
    background-image: url('@{sol-icons-path}/globe--black.svg');
  }
}

.sol-icon__globe--white {
  &::after {
    background-image: url('@{sol-icons-path}/globe-white.svg');
  }
}
.sol-icon__info {
  &::after {
    background-image: url('@{sol-icons-path}/info.svg');
  }
}
.sol-icon__info-plain {
  &::after {
    background-image: url('@{sol-icons-path}/info-plain.svg');
  }
}
.sol-icon__legible {
  &::after {
    background-image: url('@{sol-icons-path}/legible.svg');
  }
}

.sol-icon__legible--black {
  &::after {
    background-image: url('@{sol-icons-path}/legible--black.svg');
  }
}

.sol-icon__legible--white {
  &::after {
    background-image: url('@{sol-icons-path}/legible-white.svg');
  }
}
.sol-icon__link {
  &::after {
    background-image: url('@{sol-icons-path}/link.svg');
  }
}
.sol-icon__listen {
  &::after {
    background-image: url('@{sol-icons-path}/listen.svg');
  }
}
.sol-icon__listen--white {
  &::after {
    background-image: url('@{sol-icons-path}/listen-white.svg');
  }
}
.sol-icon__listen--black {
  &::after {
    background-image: url('@{sol-icons-path}/listen--black.svg');
  }
}
.sol-icon__lock {
  &::after {
    background-image: url('@{sol-icons-path}/lock.svg');
  }
}

.sol-icon__lock--black {
  &::after {
    background-image: url('@{sol-icons-path}/lock--black.svg');
  }
}

.sol-icon__lock--white {
  &::after {
    background-image: url('@{sol-icons-path}/lock-white.svg');
  }
}
.sol-icon__mail {
  &::after {
    background-image: url('@{sol-icons-path}/mail-blue.svg');
  }
}
.sol-icon__mail-white {
  &::after {
    background-image: url('@{sol-icons-path}/mail.svg');
  }
}
.sol-icon__minus--blue {
  &::after {
    background-image: url('@{sol-icons-path}/blue-minus-darker.svg');
  }
}
.sol-icon__minus--white {
  &::after {
    background-image: url('@{sol-icons-path}/minus-white.svg');
  }
}
.sol-icon__phone {
  &::after {
    background-image: url('@{sol-icons-path}/phone.svg');
  }
}

.sol-icon__phone--black {
  &::after {
    background-image: url('@{sol-icons-path}/phone--black.svg');
  }
}

.sol-icon__phone--white {
  &::after {
    background-image: url('@{sol-icons-path}/phone-white.svg');
  }
}
.sol-icon__plus--white {
  &::after {
    background-image: url('@{sol-icons-path}/plus-white.svg');
  }
}
.sol-icon__plus--blue {
  &::after {
    background-image: url('@{sol-icons-path}/plus-darker-blue.svg');
  }
}
.sol-icon__print {
  &::after {
    background-image: url('@{sol-icons-path}/print.svg');
  }
}
.sol-icon__search {
  &::after {
    background-image: url('@{sol-icons-path}/search.svg');
  }
}
.sol-icon__search--green {
  &::after {
    background-image: url('@{sol-icons-path}/search-green.svg');
  }
}
.sol-icon__search--pink {
  &::after {
    background-image: url('@{sol-icons-path}/search-pink.svg');
  }
}
.sol-icon__share {
  &::after {
    background-image: url('@{sol-icons-path}/share.svg');
  }
}
.sol-icon__signlanguage {
  &::after {
    background-image: url('@{sol-icons-path}/teckensprak.svg');
  }
}
.sol-icon__tickets {
  &::after {
    background-image: url('@{sol-icons-path}/tickets.svg');
  }
}
.sol-icon__tipsa {
  &::after {
    background-image: url('@{sol-icons-path}/tipsa.svg');
  }
}
.sol-icon__warning {
  &::after {
    background-image: url('@{sol-icons-path}/warning.svg');
  }
}
.sol-icon__work {
  &::after {
    background-image: url('@{sol-icons-path}/work.svg');
  }
}
.sol-icon__x {
  &::after {
    background-image: url('@{sol-icons-path}/x.svg');
  }
}
.sol-icon__gear {
  &::after {
    background-image: url('@{sol-icons-path}/gear.svg');
  }
}


.sol-icon__aktuella-arbeten--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/aktuella-arbeten.svg');
  }
}
.sol-icon__bibliotek--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/bibliotek.svg');
  }
}
.sol-icon__elev--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/elev.svg');
  }
}
.sol-icon__e-tjanster-1--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/e-tjanster-1.svg');
  }
}
.sol-icon__e-tjanster-2--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/e-tjanster-2.svg');
  }
}
.sol-icon__evenemang--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/evenemang.svg');
  }
}
.sol-icon__felanmalan--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/felanmalan.svg');
  }
}
.sol-icon__foreningsregister--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/foreningsregister.svg');
  }
}
.sol-icon__framtidsyrken-1--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/framtidsyrken-1.svg');
  }
}
.sol-icon__framtidsyrken-2--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/framtidsyrken-2.svg');
  }
}
.sol-icon__hitta-hit--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/hitta-hit.svg');
  }
}
.sol-icon__insyn-i-politiken--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/insyn-i-politiken.svg');
  }
}
.sol-icon__jamforelseguiden--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/jamforelseguiden.svg');
  }
}
.sol-icon__lediga-jobb--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/lediga-jobb.svg');
  }
}
.sol-icon__servicecenter--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/servicecenter.svg');
  }
}
.sol-icon__stadsbyggnadsportalen-2--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/stadsbyggnadsportalen-2.svg');
  }
}
.sol-icon__utbildningar--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/utbildningar.svg');
  }
}
.sol-icon__vi-erbjuder--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/vi-erbjuder.svg');
  }
}


.sol-icon__foreningsnytt--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/foreningsnytt.svg');
  }
}

.sol-icon__aktuella-arbeten--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/aktuella-arbeten.svg');
  }
}
.sol-icon__tankar--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/tankar.svg');
  }
}
.sol-icon__bibliotek--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/bibliotek.svg');
  }
}
.sol-icon__elev--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/elev.svg');
  }
}
.sol-icon__e-tjanster-1--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/e-tjanster-1.svg');
  }
}
.sol-icon__e-tjanster-2--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/e-tjanster-2.svg');
  }
}
.sol-icon__evenemang--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/evenemang.svg');
  }
}
.sol-icon__felanmalan--rosa{
  &::after {
    background-image: url('@{sol-icons-path}/rosa/felanmalan.svg');
  }
}
.sol-icon__foreningsregister--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/foreningsregister.svg');
  }
}
.sol-icon__framtidsyrken-1--rosa{
  &::after {
    background-image: url('@{sol-icons-path}/rosa/framtidsyrken-1.svg');
  }
}
.sol-icon__framtidsyrken-2--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/framtidsyrken-2.svg');
  }
}
.sol-icon__hitta-hit--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/hitta-hit.svg');
  }
}
.sol-icon__insyn-i-politiken--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/insyn-i-politiken.svg');
  }
}
.sol-icon__jamforelseguiden--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/jamforelseguiden.svg');
  }
}
.sol-icon__lediga-jobb--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/lediga-jobb.svg');
  }
}
.sol-icon__servicecenter--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/servicecenter.svg');
  }
}
.sol-icon__stadsbyggnadsportalen-2--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/stadsbyggnadsportalen-2.svg');
  }
}
.sol-icon__utbildningar--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/utbildningar.svg');
  }
}
.sol-icon__vi-erbjuder--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/vi-erbjuder.svg');
  }
}
.sol-icon__boka-lokal--rosa {
  &::after {
    background-image: url('@{sol-icons-path}/rosa/boka-lokal.svg');
  }
}



.sol-icon__boka-lokal--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/boka-lokal.svg');
  }
}

.sol-icon__tankar--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/tankar.svg');
  }
}
.sol-icon__aktuella-arbeten--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/aktuella-arbeten.svg');
  }
}
.sol-icon__bibliotek--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/bibliotek.svg');
  }
}
.sol-icon__elev--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/elev.svg');
  }
}
.sol-icon__e-tjanster-1--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/e-tjanster-1.svg');
  }
}
.sol-icon__e-tjanster-2--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/e-tjanster-2.svg');
  }
}
.sol-icon__evenemang--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/evenemang.svg');
  }
}
.sol-icon__felanmalan--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/felanmalan.svg');
  }
}
.sol-icon__foreningsregister--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/foreningsregister.svg');
  }
}
.sol-icon__framtidsyrken-1--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/framtidsyrken-1.svg');
  }
}
.sol-icon__framtidsyrken-2--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/framtidsyrken-2.svg');
  }
}
.sol-icon__hitta-hit--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/hitta-hit.svg');
  }
}
.sol-icon__insyn-i-politiken--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/insyn-i-politiken.svg');
  }
}
.sol-icon__jamforelseguiden--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/jamforelseguiden.svg');
  }
}
.sol-icon__lediga-jobb--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/lediga-jobb.svg');
  }
}
  .sol-icon__servicecenter--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/servicecenter.svg');
  }
}
.sol-icon__stadsbyggnadsportalen-2-grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/stadsbyggnadsportalen-2.svg');
  }
}
.sol-icon__utbildningar--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/utbildningar.svg');
  }
}
.sol-icon__vi-erbjuder--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/vi-erbjuder.svg');
  }
}
.sol-icon__foreningsnytt--grön {
  &::after {
    background-image: url('@{sol-icons-path}/grön/foreningsnytt.svg');
  }
}


.sol-icon__foreningsnytt--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/foreningsnytt.svg');
  }
}
.sol-icon__boka-lokal--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/boka-lokal.svg');
  }
}

.sol-icon__tankar--blå {
  &::after {
    background-image: url('@{sol-icons-path}/blå/tankar.svg');
  }
}

.sol-icon__aktuella-arbeten--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/aktuella-arbeten.svg');
  }
}
.sol-icon__bibliotek--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/bibliotek.svg');
  }
}
.sol-icon__elev--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/elev.svg');
  }
}
.sol-icon__e-tjanster-1--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/e-tjanster-1.svg');
  }
}
.sol-icon__e-tjanster-2--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/e-tjanster-2.svg');
  }
}
.sol-icon__evenemang--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/evenemang.svg');
  }
}
.sol-icon__felanmalan--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/felanmalan.svg');
  }
}
.sol-icon__foreningsregister--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/foreningsregister.svg');
  }
}
.sol-icon__framtidsyrken-1--ljusblå{
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/framtidsyrken-1.svg');
  }
}
.sol-icon__framtidsyrken-2--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/framtidsyrken-2.svg');
  }
}
.sol-icon__hitta-hit--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/hitta-hit.svg');
  }
}
  .sol-icon__insyn-i-politiken--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/insyn-i-politiken.svg');
  }
}
.sol-icon__jamforelseguiden--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/jamforelseguiden.svg');
  }
}
.sol-icon__lediga-jobb--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/lediga-jobb.svg');
  }
}
.sol-icon__servicecenter--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/servicecenter.svg');
  }
}
.sol-icon__stadsbyggnadsportalen-2--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/stadsbyggnadsportalen-2.svg');
  }
}
.sol-icon__utbildningar--ljusblå {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/utbildningar.svg');
  }
}
.sol-icon__vi-erbjuder--ljusblå  {
  &::after {
    background-image: url('@{sol-icons-path}/ljusblå/vi-erbjuder.svg');
  }
}



.sol-icon__aktuella-arbeten--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/aktuella-arbeten.svg');
  }
}
.sol-icon__bibliotek--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/bibliotek.svg');
  }
}
.sol-icon__elev--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/elev.svg');
  }
}
.sol-icon__e-tjanster-1--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/e-tjanster-1.svg');
  }
}
.sol-icon__e-tjanster-2--ljusgrön{
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/e-tjanster-2.svg');
  }
}
.sol-icon__evenemang--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/evenemang.svg');
  }
}
.sol-icon__felanmalan--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/felanmalan.svg');
  }
}
.sol-icon__foreningsregister--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/foreningsregister.svg');
  }
}
.sol-icon__framtidsyrken-1--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/framtidsyrken-1.svg');
  }
}
.sol-icon__framtidsyrken-2--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/framtidsyrken-2.svg');
  }
}
.sol-icon__hitta-hit--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/hitta-hit.svg');
  }
}
.sol-icon__insyn-i-politiken--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/insyn-i-politiken.svg');
  }
}
.sol-icon__jamforelseguiden--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/jamforelseguiden.svg');
  }
}
.sol-icon__lediga-jobb--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/lediga-jobb.svg');
  }
}
.sol-icon__servicecenter--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/servicecenter.svg');
  }
}
.sol-icon__stadsbyggnadsportalen-2--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/stadsbyggnadsportalen-2.svg');
  }
}
.sol-icon__utbildningar--ljusgrön {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/utbildningar.svg');
  }
}
.sol-icon__vi-erbjuder--ljusgrön  {
  &::after {
    background-image: url('@{sol-icons-path}/ljusgrön/vi-erbjuder.svg');
  }
}
.sol-icon__webeditor {
  &::after {
    background-image: url('@{sol-icons-path}/webeditor.svg');
  }
}
