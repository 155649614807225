.sol-startpage-news {
  .sol-news-archive-link {
    font-family: @light-font;
    margin-right: 0;
  }
  &__header {
    margin-top: 0;
    margin-bottom: 60px;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
  }
  &__left {
    @media @xl {
      & > ul {
        padding-right: 35px;
      }
    }
  }
  &__right {
    margin-top: 0px;
    @media @xl {
      & > ul {
        padding-left: 0px;
      }
    }
    @media @sm {
      margin-top: 0;
    }
  }

}
.sol-startpage__content__top {
    margin-top: 0;
  & .sol-news-item { 
    text-decoration: none;
    font-family: PFDINTextStd-Light, sans-serif;
    
    &__header {
      font-family: PFDINTextStd-Regular, sans-serif;
    }
    &__desc {
      // nothing yet
    }
    &__content {
      padding: 8px 10px 18px 18px;
    }
  }
  & .sol-news-archive-link {
    font-family: PFDINTextStd-Light, sans-serif;
    position: relative;
    z-index: 1;
    text-decoration: none;
    font-size: 19px;
    font-weight: 300;
    line-height: 24px;
    color: #204560;
    margin-top: 15px;
    float: right;
    margin-right: 8px;

    &:after {
      content: '';
      border-bottom: 2px solid rgba(110, 205, 233, 0.6);
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: 1px;
      z-index: -1;
    }
    &:hover {
      &:after {
        border-bottom-color: #6ecde9;
      }
    }
  }
  .sol-grid .sol-row *.sv-column-6, .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-6, .sol-grid .sv-row *.sv-column-6, .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    flex: 1 0 48%;
    max-width: 48%;
  }
}
