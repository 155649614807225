.sol-top-nav {
  @top-nav-bg: @color-1;
  @top-nav-text: @white;
  @top-nav-active-text: @color-1;
  @top-nav-active-bg: @white;
  @top-nav-border: rgba(255, 255, 255, 0.2);
  .clearfix;
  display: inline-block;
  vertical-align: top;
  margin-left: -20px;
  margin-right: -20px;
  @media @lg {
    margin-left: -10px;
    margin-right: -10px;
  }
  & &__item {
    .focus-outline;
    position: relative;
    font-weight: 500;
    font-family: @main-font;
    font-size: 17px;
    letter-spacing: 0.6px;
    text-align: center;
    float: left;
    padding: 16px 17px 11px 17px;
    margin-top: 2px;
    margin-bottom: 3px;
    color: @top-nav-text;
    line-height: 21px;
    cursor: pointer;
    .transition(background-color);
    text-decoration: none;
    @media @lg {
      padding: 13px 8px 15px;
      font-size: 15px;
    }
    &::after {
      content: '';
      border-right: 2px solid @top-nav-border;
      width: 2px;
      position: absolute;
      right: -1px;
      top: 10px;
      bottom: 10px;
    }
    &:last-child::after {
      border-right: 0;
    }
    &:hover {
      text-decoration: underline;
    }
    &--active {
      border-radius: 3px;
      background-color: @top-nav-active-bg;
      color: @top-nav-active-text;
      margin-top: 7px;
      position: relative;
      left: -1px;
      padding: 11px 11px 9px 13px ;
      &::after {
        border-color: transparent;
      }
    }
  }
}
.sol-top-nav .sol-top-nav__item:focus, [data-whatinput="keyboard"] .sol-top-nav .sol-top-nav__item:focus {
  outline: 3px solid #FBC900;
}
