// BREAKPOINTS
@xs-min: 0;
@sm-min: 480px;
@md-min: 768px;
@lg-min: 992px;
@xl-min: 1280px;
@xs-max: (@sm-min - 1);
@sm-max: (@md-min - 1);
@md-max: 1024px;
@lg-max: (@xl-min - 1);
@xs: ~"(max-width: @{xs-max})";
@sm: ~"(max-width: @{sm-max})";
@sm-strict: ~"(min-width: @{sm-min}) and (max-width: @{sm-max})";
@md: ~"(max-width: @{md-max})";
@md-strict: ~"(min-width: @{md-min}) and (max-width: @{md-max})";
@lg: ~"(max-width: @{lg-max})";
@lg-strict: ~"(min-width: @{lg-min}) and (max-width: @{lg-max})";
@xl: ~"(min-width: @{xl-min})";
@ipadpro: ~"(max-width: 1024)";
@webkit-only: ~"screen and (-webkit-min-device-pixel-ratio:1.3)";
@grid-width: 1280px;
@grid-min: (@grid-width + 1);
@col-size: 12;
@col-spacing: 20px;
.generate-columns(@n; @selector; @i:1) when (@i =< @n) {
  @col: ~"@{selector}-@{i}";
  @push: ~"@{selector}-push-@{i}";
  @{col} {
    width: auto !important;
    //flex: 1 0 floor(@i * 100% / @n - 1);
    //max-width: floor(@i * 100% / @n - 1);
    flex: 1 0 (@i * 100% / @n); //@Mattias
    max-width: (@i * 100% / @n); //@Mattias
    margin-left: 0; //@Mattias
    &.sol-stacked-margin {
      margin-bottom: 0;
    }
  }
  @{push} {
    position: relative;
    left: (@i * 100% / @n);
  }
  .generate-columns(@n, @selector, (@i + 1));
}

.sol-row(@col-class) {
  .sol-row,
  .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -(@col-spacing / 2);
    margin-right: -(@col-spacing / 2);
    justify-content: flex-start;
    @media @sm {
      margin-left: -(@col-spacing / 4);
      margin-right: -(@col-spacing / 4);
    }
    &.sol-row-reverse {
      flex-direction: row-reverse;
    }
    &.sol-row-center {
      justify-content: center;
    }
    .generate-columns(@col-size, ~  "*.@{col-class}");
  }
}

//Init grid
.sol-grid,
.sv-grid-main.sv-fluid-grid {
  max-width: @grid-width;
  margin-left: auto;
  margin-right: auto;
  /*padding-left: 10px;
  padding-right: 10px;
  @media @xs {
    padding-left: 8px;
    padding-right: 8px;
  }*/
  @media @xl {
    .sol-row(sol-col-xl);
    .sol-row(sv-column);
  }
  @media @lg-strict {
    .sol-row(sol-col-lg);
    .sol-row(sv-column);
  }
  @media @md-strict {
    .sol-row(sol-col-md);
  }
  @media @sm-strict {
    .sol-row(sol-col-sm);
  }
  @media @xs {
    .sol-row(sol-col-xs);
  }

  & *[class*="sol-col-"],
  & *[class*="sv-column-"] {
    flex: 1 0 100%;
    max-width: 100%;
    padding-left: (@col-spacing / 2);
    padding-right: (@col-spacing / 2);
    &.sol-stacked-margin {
      margin-bottom: 10px;
    }
    @media @sm {
      padding-left: (@col-spacing / 4);
      padding-right: (@col-spacing / 4);
    }
  }
}

.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none;
}

.sol-hide-sm {
  display: inherit;
}

@media @xl {
  .sol-hide-xl {
    display: none;
  }

  .sol-show-xl {
    display: inherit;
  }
}

@media @lg {
  .sol-hide-lg {
    display: none;
  }

  .sol-show-lg {
    display: inherit;
  }
}

.sol-show-md--strict {
  display: none !important;
  @media @md-strict {
    display: flex !important;
  }
}

@media @md {
  .sol-hide-md {
    display: none;
  }

  .sol-show-md {
    display: inherit;
  }
}

@media @sm {
  .sol-hide-sm {
    display: none;
  }

  .sol-show-sm {
    display: inherit;
  }
}

@media @xs {
  .sol-hide-xs {
    display: none;
  }

  .sol-show-xs {
    display: inherit;
  }
}
