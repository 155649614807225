
@downriver: #204560;
.sol-element-action {
  text-decoration: none;
  position: relative;
  min-height: 50px;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(193, 228, 199, 0.3);
    .sol-element-action__link {
      text-decoration: underline;
      color: black;
    }
  }
  .sol-element-action__icon--left {
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-50%);

  }
  .sol-icon__cnt {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 6px;
      top: 50%;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .sol-icon {
        .icon-size(15px, 19px);
        &::after {
          background-image: url('/webdav/files/System/resources/graphics/icons/arrow--black.svg') !important;
        }
        &__arrow {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
  }
  display: flex;
  align-items: center;
  width: 570px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #c1e4c7;
  border: solid 2px solid #c1e4c7;
  margin-bottom: 20px;
  border-radius: 100px;
  margin-top: -5px;
  @media @sm {
    height: auto;
    width: auto;
    min-height: 50px;
  }
  &__link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: @black;
    line-height: normal;
    font-size: 20px;
    text-align: left;
    color: #333333;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 62px;
    padding-right: 45px;
    z-index: 2;
    &:hover span {
      text-decoration: underline;
    }
    @media @sm {
      flex-basis: 85%;
    }

    & > i {
      font-size: 0.7em;
      margin-left: 10px;
      margin-top: -4px;
    }
  }
  &__text {
    color: @white;
    font-size: 16px;
    font-weight: bold;
    line-height: 2.0;
    text-decoration: none;
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    margin-left: -12px;
    i.fa {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #333333;
    }
  }
}
.sol-link-tablet {
  width: 543px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
  background-color: @downriver;
  border: solid 2px solid @downriver;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  @media @sm {
    max-width: 100%;
    height: auto;
    padding: 10px;
  }
  .sol-link-tablet__link {
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .sol-link-tablet__text {
    color: @white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    @media @sm {
      line-height: 1.1;
      max-width: 80%;
      font-size: 16px;
    }
    text-decoration: none;
  }
  .sol-link-tablet__icon {
    display: inline-flex;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 12px;
    background-color: @white;
    border-radius: 50%;
    border: solid 2px rgba(255, 255, 255, 0.4);
    i.fa {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: @downriver;
    }
  }
}
