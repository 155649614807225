.sol-cookie-message > div {
  @cookie-bg: rgb(235, 244, 247);
  @cookie-text: @black;
  @cookie-ok-bg: @white;
  @cookie-ok-text: @color-1;
  .sol-clearfix;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 999999999;
  background-color: @cookie-bg;
  color: @cookie-text;
  padding: 8px 190px 8px 14px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.3px;
  & a {
    color: #204560;
    text-decoration: underline;
    text-decoration-color: #6ecde9;
    margin-left: 5px;
    display: inline-block;
    position: relative;

    &:hover {
      &::after {
        border-bottom-color: #6ecde9;
      }
    }
  }
  & .sol-cookie-ok {
    .focus-outline;
    .no-appearance;
    display: inline-block;
    padding: 0 7px;
    border-radius: 2px;
    background-color: @cookie-ok-bg;
    color: @cookie-ok-text;
    font-size: 10px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    float: right;
    margin-top: 3px;
  }
  & .sol-cookie-close-btn {
    .focus-outline;
    .no-appearance;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #204560;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &__cnt {
      position: absolute;
      top: 50%;
      right: 95px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      > span {
        margin-right: 18px;

      }
    }
    & .sol-icon__x {
      .icon-size(15px);
    }
  }
  @media @md {
    padding-right: 140px;
    .sol-cookie-close-btn {
      &__cnt {
        right: 20px;
      }
    }
  }
  @media @sm {
    padding-right: 70px;
    a {
      //display: contents;
    }
    .sol-cookie-close-btn {
      &__cnt {
        right: 15px;
        top: 25px;
        span {
          position: absolute;
          right: -20px;
          top: 30px;
        }
      }
    }
  }
}
.sol-editor-bottom {
  bottom: 60px !important;
}
