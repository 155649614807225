.sol-suggest-political-event-page {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .messageFade-enter-active, .messageFade-leave-active {
    transition: opacity .5s;
    transition-delay: 1s;
  }
  .messageFade-enter, .messageFade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transition-delay: .7s;
  }
  .sol-thank-you-message, .sol-error-message {
    display: none;
  }
}
.sv-edit-mode {
  .sol-thank-you-message, .sol-error-message {
    display: block;
  }
}
.sol-political-event-create-app {

}
.sol-form-group {
  margin-bottom: 20px;
  label, legend {
    .input-label;
    &[required="required"] {
      &::after {
        display: inline-block;
        content: "\002a";
        color: red;
        font-size: 14px;

      }
    }
  }
  textarea {
    .input-textarea;
  }
  .sol-form-control {
    &[type="text"] {
      .input-text;
      padding-top: 10px;
    }
    &[type="radio"] {
      .input-radio;
      & + label {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

.sol-political-event {
  &__ticket {
    &__link {
      margin-top: 20px;
    }
  }
}

.sol-political-event-form {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    color: red;
  }
  & [class$="__error-message"] {
    color: red;
    font-weight: 400;
  }
  > div {
    .sol-form-group;
  }
  .sol-remove-date-time-btn {
    position: relative;
    display: inline-block;
    line-height: 22px;
    min-height: 40px;
    padding: 8px 25px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color,color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular,sans-serif;
    border: 0;
    height: 40px;
    margin-left: 1%;
    .fa {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .sol-add-new-date-time {
    position: relative;
    display: inline-block;
    line-height: 22px;
    padding: 8px 25px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color,color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular,sans-serif;
    border: 0;
    margin-top: 17px;
    min-height: 35px!important;
  }
  button[type="submit"] {
    position: relative;
    display: inline-block;
    line-height: 22px;
    min-height: 40px;
    padding: 8px 25px;
    color: #313131;
    background-color: #c1e4c7;
    border-radius: 3px;
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-family: PFDINTextStd-Regular, sans-serif;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 110px;
  }
  .sol-form-group__error-msg {
    color: red;
  }
  .application--wrap {
    padding: 0;
  }
  .application.theme--light {
    background: none;
  }
  .sol-fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
    .flex-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .sol-form-group {
        flex: 1 0 31%;
        max-width: 31%;
        &.full-width {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }
    legend {
      font-family: PFDINTextStd-Regular,sans-serif;
      color: #204560;
      margin-top: 30px;
      margin-bottom: 5px;
      font-size: 24px;
      line-height: 1.38;
    }

  }
  .sol-form-group {
    margin-bottom: 20px;
    label, legend {
      .input-label;
      &[required="required"] {
        &::after {
          display: inline-block;
          content: "\002a";
          color: red;
          font-size: 14px;

        }
      }
    }
    textarea {
      .input-textarea;
    }
    .sol-form-control {
      &[type="text"] {
        .input-text;
        padding-top: 10px;
      }
      &[type="radio"] {
        .input-radio;
        & + label {
          display: block;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}